.footer {
  background-color: @primary;
  border-top: 8px solid @secondary;
  color: @white;
  .part {
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    display: flex;
    margin-left: 80px;
    margin-right: 80px;
    padding-top: 15px;
    padding-bottom: 15px;
    @media (max-width: 1366px) {
      margin-right: 40px;
      margin-left: 40px;
    }
    &:last-child {
      border-bottom: 0;
    }
    &.with-padding {
      padding-left: 10px;
      padding-right: 10px;
    }
    &.access-rapide {
      display: block;
      padding-bottom: 34px;
      padding-top: 34px;
      &.with-padding {
        padding-right: 0px;
      }
      .list-access-content {
        display: flex;
        flex-wrap: wrap;
        margin-top: -16px;
        .footer-menu {
          width: 100%;
          ul {
            padding: 0;
            margin: 0;
          }
          #menu-main-menu-1,
          #menu-main-menu-anglais-1,
          #bp-menu {
            display: -webkit-box;
            display: -webkit-flex;
            display: -ms-flexbox;
            display: flex;
            -webkit-flex-wrap: wrap;
            -ms-flex-wrap: wrap;
            flex-wrap: wrap;
            li {
              list-style: none;
            }
            > .menu-item:not(.menu-item-has-children) {
              display: none;
            }
            > .menu-item-has-children,
            > .entry-title {
              font-size: 17px;
              font-weight: 300;
              line-height: 22px;
              text-transform: uppercase;
              > a {
                font-size: 17px;
                font-weight: 300;
                line-height: 22px;
                text-transform: uppercase;
                color: white;
                strong {
                  font-weight: 700;
                }
                &:hover {
                  color: @secondary;
                  font-weight: 400;
                }
              }
              .sub-menu {
                list-style: none;
                margin: 0;
                margin-top: 30px;
                padding: 0;
                a {
                  color: #fff;
                  display: block;
                  font-size: 14px;
                  font-weight: 300;
                  line-height: 28px;
                  text-transform: initial;
                  &:hover {
                    color: @secondary;
                    font-weight: 400;
                  }
                }
              }
            }
          }
          #bp-menu {
            padding: 30px 0;
            .entry-title {
              display: inline-block;
              float: none;
              line-height: 20px;
              margin-bottom: 10px;
              height: 45px;
              overflow: hidden;
              vertical-align: top;
              @media screen and (min-width: 992px) {
                width: 33%;
              }

              @media screen and (max-width: 991px) {
                max-width: none;
              }
              @media screen and (max-width: 640px) {
                height: auto;
                padding: 0px;
              }
              a {
                text-transform: initial !important;
                padding: 5px 0 0;
                font-size: 14px;
                line-height: 20px;
                display: inline-block;
                &:first-letter {
                  text-transform: uppercase;
                }
              }
            }
          }
        }
        .list-content,
        .menu-item.menu-item-has-children {
          padding-right: 30px;
          margin-top: 50px;
          min-width: 25%;
          @media (max-width: 1280px) {
            flex: 1 1 270px;
          }
          &:first-child {
            min-width: 250px;
          }
          &:nth-child(4) {
            @media (max-width: 1450px) {
              margin-right: 0;
              min-width: 250px;
            }
          }
          &:nth-child(5) {
            @media (max-width: 1450px) {
              min-width: 247px;
            }
          }
          @media (max-width: 1280px) {
            min-width: unset;
          }
        }
      }
    }
    @media (max-width: 768px) {
      margin: 0;
    }
    &.logo {
      align-items: center;
      padding-bottom: 25px;
      padding-top: 55px;
      @media (max-width: 768px) {
        flex-wrap: wrap;
        padding-top: 25px;
      }
      p {
        margin-bottom: 0px;
        margin-left: 15px;
        @media (max-width: 768px) {
          margin-top: 25px;
        }
        a {
          color: @white;
          text-decoration: underline;
          &:hover {
            color: @secondary;
          }
        }
        strong {
          font-weight: 600;
        }
      }
    }
    &.suivre {
      align-items: flex-end;
      flex-wrap: wrap;
      padding-bottom: 35px;
      .titre {
        strong {
          display: block;
        }
      }
      .sinscrire {
        margin-right: 120px;
        max-width: 270px;
        p {
          margin-bottom: 7px;
        }
      }
      .saisiremail {
        min-width: 394px;
        position: relative;
        margin-right: 96px;
        @media (min-width: 1375px) {
          margin-right: 125px;
        }
        .news-loader {
          position: absolute;
          top: 10px;
          right: 62px;
          display: none;
        }
        @media (max-width: 500px) {
          min-width: 100%;
        }
        input {
          color: @grey-input;
          font-size: 14px;
          font-weight: 300;
          height: 45px;
          padding-left: 15px;
          padding-right: 69px;
          width: 100%;
          outline: 0;
        }
        .btn.btn-ok {
          background-color: @secondary;
          border: 0;
          border-radius: 0;
          color: @white;
          cursor: pointer;
          font-size: 17px;
          font-weight: 700;
          height: 45px;
          min-width: 54px;
          padding-left: 5px;
          padding-right: 5px;
          position: absolute;
          outline: 0;
          right: 0;
          top: 0;
          width: 54px;
          &::after {
            background-color: rgba(0, 0, 0, 0.2);
            content: "";
            height: 45px;
            position: absolute;
            left: 0px;
            top: 0;
            width: 0;
            .transition(all, 0.2s, cubic-bezier(0.4, 0, 1, 1));
          }
          &:hover {
            &::after {
              width: 54px;
              .transition(all, 0.2s, cubic-bezier(0, 0.54, 0.4, 1.04));
              /*.transition(all, 0.4s, cubic-bezier(0.18, 0.89, 0.32, 1.28));*/
            }
          }
        }
      }
      .social {
        min-width: 230px;
        .reseaux {
          list-style: none;
          margin: 0;
          margin-bottom: 3px;
          margin-top: 13px;
          padding: 0;
          li {
            display: inline-block;
            margin: 0;
            padding: 0;
          }
          a {
            background-color: @white;
            border-radius: 50%;
            color: @primary;
            display: inline-block;
            font-size: 22px;
            height: 40px;
            margin-right: 9px;
            padding: 5px 9px 4px 9px;
            position: relative;
            text-decoration: none;
            width: 40px;
            &:hover {
              background-color: @secondary;
              color: @white;
            }
            i,
            span {
              .transition(all, 0.3s, ease);
              .transform(scale(1));
              display: inline-block;
            }
            &:hover {
              i,
              span {
                .transition(all, 0.3s, ease);
                .transform(scale(0.7));
              }
            }
          }
        }
      }
      p {
        margin-top: 15px;
      }
      > div {
        margin-top: 15px;
      }
    }
    &.assistance {
      flex-wrap: wrap;
      padding-bottom: 20px;
      &.with-padding {
        @media (max-width: 1450px) {
          padding-right: 0;
        }
      }
      .contacts {
        margin-top: 20px;
        flex: 1 1 75%;
        .titre {
          p {
            font-size: 14px;
            margin-bottom: 30px !important;
            margin-top: 30px;
            text-transform: none;
          }
        }
        .txt {
          > * {
            margin-bottom: 30px;
            padding-left: 30px;
            flex: 1 1 33.33%;
            padding-right: 15px;
            @media (max-width: 992px) {
              flex: 1 1 50%;
            }
            @media (max-width: 640px) {
              flex: 1 1 100%;
            }
          }
          a {
            color: @white;
            font-weight: 600;
            text-decoration: underline;
            &:hover {
              color: @secondary;
            }
          }
          label {
            font-weight: 600;
            position: relative;
            text-transform: uppercase;
            i {
              left: -28px;
              position: absolute;
              top: 5px;
            }
          }
          p {
            font-size: 14px;
            margin-bottom: 10px;
            &.appel {
              margin-bottom: 5px;
            }
            strong {
              font-weight: 600;
            }
          }
        }
      }
      .txt {
        display: flex;
        flex-wrap: wrap;
      }
      .sites {
        margin-top: 20px;
        // min-width: 260px;
        .liste {
          a {
            &:hover {
            }
          }
        }
      }
    }
    &.cookies {
      padding-bottom: 25px;
      padding-top: 35px;
      p {
        a {
          color: @white;
          text-decoration: underline;
          &:hover {
            color: @secondary;
            font-weight: 400;
          }
        }
      }
    }
    .btn-access {
      background-color: transparent;
      border: 0;
      color: @white;
      cursor: pointer;
      font-size: 17px;
      font-weight: 700;
      outline: 0;
      padding: 0;
      position: relative;
      text-transform: uppercase;
      .transition();
      &::after {
        content: "<";
        font-size: 28px;
        font-weight: 100;
        position: absolute;
        right: -38px;
        top: -8px;
        .transition();
        -webkit-transform: rotate(90deg) scale(0.9, 1.9);
        -ms-transform: rotate(90deg) scale(0.9, 1.9);
        transform: rotate(90deg) scale(0.9, 1.9);
      }
      &.collapsed {
        &::after {
          content: ">";
        }
      }
    }
    .liste {
      list-style: none;
      margin: 0;
      margin-top: 30px;
      padding: 0;
      a {
        color: @white;
        display: block;
        font-size: 14px;
        font-weight: 300;
        line-height: 28px;
        &:hover {
          color: @secondary;
          font-weight: 400;
        }
      }
    }
    .titre {
      font-size: 17px;
      font-weight: 300;
      line-height: 22px;
      text-transform: uppercase;
      strong {
        font-weight: 700;
      }
    }
    p {
      font-size: 14px;
      font-weight: 300;
      line-height: 18px;
      a {
        line-height: 18px;
      }
    }
  }
}
.subfooter {
  color: @black-second;
  font-size: 13px;
  font-weight: 300;
  .part {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-left: 80px;
    margin-right: 80px;
    padding-bottom: 5px;
    padding-top: 5px;
    @media (max-width: 768px) {
      margin-left: 0px;
      margin-right: 0px;
    }
    &.with-padding {
      padding-left: 10px;
      padding-right: 10px;
    }
  }
  a {
    color: @black-second;
  }
}
.to-top {
  position: fixed;
  right: 205px;
  bottom: 59px;
  width: 47px;
  height: 47px;
  background: @grey-opacity;
  text-align: center;
  line-height: 54px;
  border-radius: 4px;
  cursor: pointer;
  opacity: 0.75;
  display: none;
  z-index: 35;
  @media (max-width: 1366px) {
    &.up {
      bottom: 80px;
    }
  }
  @media (max-width: 1450px) {
    right: 205px;
    bottom: 35px;
  }
  @media (min-width: 1451px) {
    right: 290px;
  }
  @media (min-width: 641px) and (max-width: 800px) {
    right: 270px;
    bottom: 50px;
  }
  @media (min-width: 501px) and (max-width: 640px) {
    bottom: 35px;
  }
  @media (max-width: 500px) {
    bottom: 98px;
    right: 0;
    transform: scale(0.8);
  }
  &.visible {
    display: block;
  }
  &:hover {
    opacity: 1;
    a {
      opacity: 1;
    }
  }
  a {
    color: @black;
    position: absolute;
    left: 0;
    top: 0;
    text-decoration: none;
    font-size: 23px;
    text-align: center;
    line-height: 47px;
    opacity: 0.85;
    width: 100%;
    height: 100%;
    .transition(all, 0.3s);
    &:before {
      text-align: center;
      content: "\e113";
      font-family: "Glyphicons Halflings";
    }
    &.scrollToTop.scrollDown {
      -webkit-transform: rotate(180deg);
      -moz-transform: rotate(180deg);
      transform: rotate(180deg);
      -webkit-transform-origin: 50% 50%;
      -moz-transform-origin: 50% 50%;
      transform-origin: 50% 50%;
      .transition(all, 0.3s);
      padding-left: 1px;
    }
    &.scrollToTop.toTop {
      padding-left: 4px;
      .transition(all, 0.3s);
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      transform: rotate(0deg);
      -webkit-transform-origin: 50% 50%;
      -moz-transform-origin: 50% 50%;
      transform-origin: 50% 50%;
    }
  }
}
.btn.btn-first.ayn-newsletter-validate,
.modal.modal-notif
  .modal-dialog
  .modal-content
  .modal-body
  .btn.btn-first.ayn-newsletter-validate,
.btn.btn-first.ayn-callback-validate,
.modal.modal-notif
  .modal-dialog
  .modal-content
  .modal-body
  .btn.btn-first.ayn-callback-validate,
.btn.btn-first.ayn-callback-rdv-validate,
.modal.modal-notif
  .modal-dialog
  .modal-content
  .modal-body
  .btn.btn-first.ayn-callback-rdv-validate {
  position: relative;
  &:focus {
    background: @primary;
    color: @white;
  }
  img.load-callback {
    position: absolute;
    right: -30%;
    top: 50%;
    .translate(0, -50%);
  }
}
#html_captcha_callback_validate > div,
#html_captcha_callback_rdv_validate > div,
#html_captcha_newsletter_validate > div {
  margin: 0 auto;
}
