a {
  color: @primary;
  &:hover {
    color: @secondary-presentation;
  }
}

p {
  a {
    text-decoration: underline;
  }
}

.bloc-presentation {
  color: @black-presentation;
  padding: 35px 0px;
  padding-bottom: 0;
  position: relative;
  overflow-x: hidden;
  .sous-text {
    color: #888;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 1px;
    line-height: 28px;
    margin-bottom: 35px;
  }

  .sous-text-envaleur {
    color: #222222;
    font-size: 20px;
    font-weight: 400;
    letter-spacing: 1px;
    line-height: 28px;
    width: 60%;
    margin-left: 75px;
    @media (max-width: 992px) {
      margin-left: 0px;
      width: 100%;
    }
  }

  &.grey-bg {
    background: #fbfbfb;
  }

  .type-new-bloc {
    padding-bottom: 30px;
    .bloc-texte {
      padding-left: 0;
      color: #888;
      font-size: 14px;
      h3 {
        color: #222222;
        font-size: 18px;
        font-weight: bold;
        font-family: "Open Sans", Arial;
        padding-bottom: 0px;
        margin-bottom: 20px;
        margin-top: 40px;
      }

      h4 {
        color: #888888;
        font-size: 14px;
        font-weight: bold;
        font-family: "Open Sans", Arial;
        margin-bottom: 15px;
        margin-top: 20px;
      }

      p {
        line-height: 28px;
        padding-right: 10%;
        padding-bottom: 15px;
        font-size: 14px;
        font-weight: 400;
        @media (max-width: 640px) {
          line-height: 20px;
        }
        + ul {
          margin-top: -10px !important;
          margin-bottom: 15px !important;
        }
      }

      blockquote {
        text-align: center;
        color: #222222;
        font-size: 20px;
        font-weight: 400;
        font-family: "Open Sans", Arial;
        margin: 0;
        p {
          text-align: center;
          color: #222222;
          font-size: 20px;
          font-weight: 400;
          font-family: "Open Sans", Arial;
          margin: 0;
        }
      }
    }

    &.list-style-check {
      ul {
        padding-right: 10%;
        a {
          color: #888;
          text-decoration: underline;
          :hover {
            color: #f7a800;
          }
        }
      }
    }
  }

  .type-new-bloc-titre-gauche {
    .bloc-title {
      .left-aligned {
        width: 800px;
        max-width: 100%;
      }
    }

    .bloc-texte {
      .desc {
        padding-right: 0;
        h3 {
          color: #222222;
          font-size: 18px;
          font-weight: bold;
          font-family: "Open Sans", Arial;
          padding-bottom: 0px;
          margin-bottom: 20px;
          margin-top: 40px;
        }

        h4 {
          color: #888888;
          font-size: 14px;
          font-weight: bold;
          font-family: "Open Sans", Arial;
          margin-bottom: 15px;
          margin-top: 20px;
        }

        blockquote {
          text-align: center;
          color: #222222;
          font-size: 20px;
          font-weight: 400;
          font-family: "Open Sans", Arial;
          margin: 0;
          padding: 25px 0;
        }
      }

      &.list-style-check {
        .desc {
          ul {
            margin-top: 10px;
            margin-bottom: 15px;
          }
        }
      }

      .bloc-texte {
        padding-left: 0;
      }
    }
  }

  .type-new-bloc-centre {
    .bloc-texte {
      .desc {
        text-align: center;
        padding: 0 10%;
        &.left-content {
          text-align: left;
          padding: 0;
          padding-left: 4%;
        }

        p {
          line-height: 28px;
          padding-right: 10%;
          padding-bottom: 15px;
          font-size: 14px;
          font-weight: 400;
          @media (max-width: 640px) {
            line-height: 20px;
          }
        }

        blockquote {
          text-align: center;
          color: #222222;
          font-size: 20px;
          font-weight: 400;
          font-family: "Open Sans", Arial;
          margin: 0;
          padding: 25px 0;
          p {
            font-size: 20px;
            font-weight: 400;
            font-family: "Open Sans", Arial;
          }
        }
      }
    }
  }

  &.type-new-bloc-tableau {
    > .container {
      padding-left: 65px;
    }

    p + ul {
      margin-bottom: 15px;
      margin-top: 0;
    }

    blockquote {
      p {
        text-align: center;
        color: #222222;
        font-size: 20px;
        font-weight: 400;
        font-family: "Open Sans", Arial;
        margin: 0;
        padding: 5px 0 0 0;
      }
    }

    h3 {
      color: #222222;
      font-size: 18px;
      font-weight: bold;
      font-family: "Open Sans", Arial;
      padding-bottom: 0px;
      margin-bottom: 5px;
      margin-top: 10px;
    }

    h4 {
      color: #888888;
      font-size: 14px;
      font-weight: bold;
      font-family: "Open Sans", Arial;
      margin-bottom: 5px;
      margin-top: 10px;
    }

    > .col-sm-12:first-child {
      padding-left: 0;
    }

    .container {
      .bloc-texte {
        padding-left: 0;
        .desc {
          margin-top: 0;
        }

        h4 {
          font-size: 14px;
          font-weight: 700;
        }
      }

      .bloc-desc {
        width: 100%;
        .desc {
          margin-top: 15px;
        }
      }

      .row-repeater {
        margin-bottom: 30px;
        width: 100%;
        display: flex;
        align-items: flex-start;
        table {
          width: 100%;
          thead {
            border: 1px solid #e0e0e0;
            text-transform: uppercase;
            text-align: center;
            background: #5a5a5a;
            color: white;
            th {
              padding: 10px 15px;
            }
          }

          tbody {
            tr {
              border: 1px solid #e0e0e0;
              td {
                &:not(:last-child) {
                  border-right: 1px solid #e0e0e0;
                }

                font-size: 12px;
                text-align: left;
              }

              &:nth-child(even) td {
                background: rgba(239, 239, 239, 0.5);
              }
            }
          }

          th,
          td {
            padding: 5px 15px;
            width: 50%;
            text-align: center;
            line-height: 17px;
          }
        }
      }
    }
  }

  .container {
    .row {
      margin: 0;
    }
  }

  .row {
    align-items: center;
    &.type-1 {
      margin: 15px 0;
    }

    &.type-2 {
      margin: 15px 0;
    }

    &.type-4 {
      margin: 50px 0 0;
      .bloc-texte.list-style-check .desc p {
        margin-bottom: 15px !important;
      }
    }

    &.type-9 {
      .bloc-texte.list-style-check .desc p {
        margin-bottom: 15px !important;
      }
    }

    &.type-13 {
      .bloc-texte {
        .desc {
          margin-top: 0px;
        }
      }
    }

    .col-sm-12 {
      flex-wrap: wrap;
      align-items: flex-start;
    }

    + .desc {
      margin-top: 0;
      position: relative;
      top: -15px;
      padding-right: 0;
      letter-spacing: 0.3px;
    }
  }

  .title.title-h2 {
    line-height: 46px;
    &.left-aligned {
      &::before {
        content: "";
        background-color: @secondary-presentation;
        position: absolute;
        width: 5px;
        height: 100%;
        top: 0px;
        left: -28px;
        margin: 0;
        transform: none;
      }
    }

    &.right-aligned {
      display: flex;
      justify-content: flex-end;
      &::before {
        content: "";
        background-color: @secondary-presentation;
        position: relative;
        width: 5px;
        height: 92px;
        top: 0px;
        left: -28px;
        margin: 0;
        transform: none;
      }
    }

    &.left-aligned,
    &.right-aligned {
      position: relative;
      text-align: left;
    }
  }

  .bloc-image {
    flex-basis: 55%;
    /* margin-bottom: 70px;
    margin-top: 40px;
    */
    padding-right: 20px;
    margin-bottom: 25px;
    text-align: center;
    &.less-margin {
      margin-bottom: 0px;
      margin-top: 11px;
      .desc {
        &.single {
          margin-top: 23px;
          padding-right: 9%;
          padding-left: 9%;
          strong {
            font-weight: 600;
          }
        }
      }
    }

    @media (max-width: 992px) {
      flex-basis: 100%;
      text-align: center;
      margin-bottom: 60px;
    }

    @media (max-width: 640px) {
      margin-bottom: 20px;
    }

    img {
      max-width: 100%;
      @media (max-width: 992px) {
        width: auto;
        max-width: 100%;
      }
    }
    video {
      max-width: 100%;
    }
  }

  .bloc-texte {
    flex-basis: 45%;
    /* margin-top: 30px;
    */
    text-align: left;
    position: relative;
    padding-left: 50px;
    @media (max-width: 992px) {
      padding-left: 50px;
    }
    @media (max-width: 640px) {
      padding-left: 10px;
    }

    &:last-child {
      margin-bottom: 30px;
    }

    &.less-margin {
      margin-bottom: 8px;
      margin-top: 11px;
      > .desc {
        &:nth-child(1) {
          margin-bottom: 0;
          margin-top: 0px;
          + .lbl-title {
            margin-top: 20px;
          }
        }
      }
    }

    @media (max-width: 992px) {
      flex-basis: 100%;
    }

    &::before {
      content: "";
      background-color: @secondary-presentation;
      position: relative;
      display: block;
      height: 4px;
      width: 30px;
      top: 0px;
      margin: 0 auto;
      margin-bottom: 5px;
    }

    .title {
      font-weight: 800;
      + .lbl-title {
        margin-top: 45px;
      }
    }

    .title-h2 {
      line-height: 46px;
      position: relative;
      &::before {
        content: "";
        background-color: @secondary-presentation;
        position: absolute;
        width: 5px;
        height: 100%;
        top: 0px;
        left: -28px;
      }
    }

    &::before {
      display: none;
    }

    &.one {
      flex-basis: 55%;
      @media (max-width: 992px) {
        flex-basis: 100%;
      }
    }

    &.list-style-check {
      .desc {
        p {
          + ul,
          + ol {
            margin-top: 25px;
          }
        }
      }
    }
  }

  .desc {
    color: @grey-presentation;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 1px;
    line-height: 28px;
    margin-bottom: 25px;
    margin-top: 40px;
    padding-right: 22%;
    @media (max-width: 640px) {
      margin-top: 15px;
    }
    strong {
      font-weight: 700;
    }

    &.marg-left {
      padding-right: 10%;
      padding-left: 53px;
    }

    a {
      color: @primary;
      text-decoration: underline;
      &:hover {
        color: @secondary-presentation;
      }
    }

    &.single {
      color: @black-presentation;
      font-size: 20px;
      margin-top: 15px;
      strong {
        font-weight: 600;
      }
    }

    p {
      + p {
        margin-top: 7px;
      }
    }

    + .lbl-title {
      margin-top: 40px;
    }

    + .bloc-img-txt {
      margin-top: 32px;
    }
  }

  .list-style-square {
    ol {
      color: @grey-presentation;
      counter-reset: item;
      margin: 0;
      padding: 0;
      position: relative;
      li {
        font-size: 14px;
        font-weight: 400;
        letter-spacing: 1px;
        line-height: 28px;
        padding-left: 13px;
        position: relative;
        list-style-type: none;
        counter-increment: item;
        strong {
          font-weight: 700;
        }

        &::before {
          display: inline-block;
          width: 1em;
          padding-right: 18px;
          font-weight: 700;
          text-align: right;
          content: counter(item) ".";
        }

        &::after {
          content: "";
          background-color: @secondary-presentation;
          display: block;
          position: absolute;
          left: 0;
          top: 12px;
          width: 3px;
          height: 3px;
          line-height: 28px;
        }
      }
    }

    ul {
      color: @grey-presentation;
      list-style: none;
      margin: 0;
      padding: 0;
      position: relative;
      padding-right: 22%;
      li {
        font-size: 14px;
        font-weight: 400;
        letter-spacing: 1px;
        line-height: 28px;
        padding-left: 13px;
        display: block;
        position: relative;
        strong {
          font-weight: 700;
        }

        &::before {
          content: "";
          background-color: @secondary-presentation;
          display: block;
          position: absolute;
          left: 0;
          top: 12px;
          width: 3px;
          height: 3px;
          line-height: 28px;
        }
      }
    }

    &.nb-col-list-2,
    &.nb-col-list-3 {
      ul,
      ol {
        li {
          margin-bottom: 20px;
          strong {
            color: @black-presentation;
          }
        }

        + .desc,
        + p {
          margin-top: 25px;
        }
      }

      .desc {
        + ul,
        + ol {
          margin-top: 32px;
        }
      }

      p {
        + ul,
        + ol {
          margin-top: 32px;
        }
      }

      ol {
        li {
          &::before {
            color: @black-presentation;
          }
        }
      }
    }
  }

  .list-style-check {
    ul {
      color: @grey-presentation;
      list-style: none;
      margin: 0;
      padding: 0;
      position: relative;
      padding-right: 22%;
      li {
        font-size: 14px;
        font-weight: 400;
        letter-spacing: 1px;
        line-height: 28px;
        padding-left: 30px;
        display: block;
        position: relative;
        strong {
          font-weight: 700;
        }

        &::before {
          content: "\ea10";
          color: @secondary-presentation;
          display: block;
          position: absolute;
          left: 0;
          top: 0;
          line-height: 28px;
          font-family: "icomoon" !important;
          speak: none;
          font-style: normal;
          font-weight: normal;
          font-variant: normal;
          text-transform: none;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
        }
      }
    }

    ol {
      color: @grey-presentation;
      counter-reset: item;
      margin: 0;
      padding: 0;
      position: relative;
      li {
        font-size: 14px;
        font-weight: 400;
        letter-spacing: 1px;
        line-height: 28px;
        padding-left: 30px;
        position: relative;
        list-style-type: none;
        counter-increment: item;
        strong {
          font-weight: 700;
        }

        &::before {
          display: inline-block;
          width: 1em;
          padding-right: 18px;
          font-weight: 700;
          text-align: right;
          content: counter(item) ".";
        }

        &::after {
          content: "\ea10";
          color: @secondary-presentation;
          display: block;
          position: absolute;
          left: 0;
          top: 0;
          line-height: 28px;
          font-family: "icomoon" !important;
          speak: none;
          font-style: normal;
          font-weight: normal;
          font-variant: normal;
          text-transform: none;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
        }
      }
    }

    &.nb-col-list-2,
    &.nb-col-list-3 {
      ul,
      ol {
        li {
          strong {
            color: @black-presentation;
          }
        }
      }

      ol {
        li {
          &::before {
            color: @black-presentation;
          }
        }
      }
    }
  }

  .nb-col-list-2 {
    ul,
    ol {
      -moz-column-count: 2;
      -webkit-column-count: 2;
      column-count: 2;
      -moz-grid-column-gap: 19%;
      -webkit-grid-column-gap: 19%;
      grid-column-gap: 19%;
      padding: 0 9%;
      @media (max-width: 768px) {
        -moz-column-count: 1;
        -webkit-column-count: 1;
        column-count: 1;
      }
    }
  }

  .nb-col-list-3 {
    ul,
    ol {
      -moz-column-count: 3;
      -webkit-column-count: 3;
      column-count: 3;
      -moz-grid-column-gap: 5%;
      -webkit-grid-column-gap: 5%;
      grid-column-gap: 5%;
      padding: 0 7%;
      @media (max-width: 992px) {
        -moz-column-count: 2;
        -webkit-column-count: 2;
        column-count: 2;
      }

      @media (max-width: 768px) {
        -moz-column-count: 1;
        -webkit-column-count: 1;
        column-count: 1;
      }
    }
  }

  .check-list {
    list-style: none;
    margin: 0;
    padding: 0;
    position: relative;
    color: @grey-presentation;
    padding-right: 22%;
    li {
      align-items: flex-start;
      display: flex;
      position: relative;
      font-size: 14px;
      font-weight: 400;
      letter-spacing: 1px;
      line-height: 28px;
      padding-left: 0px;
      i {
        color: @secondary-presentation;
        line-height: 28px;
        margin-right: 15px;
      }

      strong {
        font-weight: 700;
      }

      &::before {
        display: none;
      }
    }
  }

  .parallax-bg {
    height: 312px;
  }

  .btn.btn-decouvrir {
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 700;
    border-radius: 5px;
    padding: 7px;
    min-width: 189px;
    color: @primary-presentation;
    background-color: @white;
    border: 1px solid @primary-presentation;
    @media (max-width: 640px) {
      margin-bottom: 30px;
    }
    &:hover {
      background-color: @primary-presentation;
      color: @white;
    }

    &.orange {
      background-color: @secondary-presentation;
      border-color: @secondary-presentation;
      color: @white;
      font-size: 14px;
      font-weight: 400;
      margin-left: 64px;
      padding-left: 20px;
      padding-right: 20px;
      text-decoration: underline;
      text-transform: uppercase;
      span {
        display: inline-block;
        font-weight: 700;
        text-decoration: none;
      }

      &:hover {
        background-color: @primary-presentation;
        border-color: @primary-presentation;
        color: @white;
      }
    }
    &.mt {
      margin-top: 40px;
    }
  }

  .btn.btn-ensavoirplus {
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 700;
    border-radius: 5px;
    padding: 7px;
    min-width: 189px;
    color: @primary-presentation;
    background-color: @white;
    border: 1px solid @primary-presentation;
    &:hover {
      background-color: @primary-presentation;
      color: @white;
    }
  }

  .img-link {
    margin-bottom: 15px;
    -webkit-box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.35);
    -moz-box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.35);
    box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.35);
  }

  &.with-lists {
    .bloc-texte {
      div.desc {
        padding-right: 0;
        p {
          padding-right: 22%;
          margin-bottom: 25px !important;
          @media (max-width: 640px) {
            line-height: 20px;
          }
        }
      }
    }
  }

  &.with-bg-img {
    padding-bottom: 0px;
    .container {
      background-repeat: no-repeat;
      background-position: 100% 100%;
      @media (max-width: 1279px) {
        background-position: 150% 100%;
      }

      @media (max-width: 992px) {
        background-image: none !important;
      }
    }

    .bloc-desc {
      width: 100%;
      .desc {
        margin-top: 44px;
      }

      + .bloc-texte {
        margin-top: 0px;
        .desc {
          margin-top: 0px;
        }
      }
    }

    .bloc-texte {
      padding-left: 30px;
      margin-bottom: 110px;
      .desc {
        letter-spacing: 0.24px;
        padding-right: 15px;
      }

      &.list-style-check {
        ul {
          margin-top: 7px;
          li {
            letter-spacing: 0.24px;
          }
        }
      }

      @media (max-width: 992px) {
        margin-bottom: 40px;
      }
    }
  }

  &.with-contact-form{
    .bloc-desc{
      width: 100%;
    }
    .bloc-texte{
      flex-basis: 50%;
      padding-left: 0;
      img{
        margin-top: 70px;
        max-width: 100%;
        padding-right: 20px;
        @media (max-width: 500px) {
          padding-right: 0;
        }
      }
      @media (max-width: 992px) {
        flex-basis: 100%;
      }
    }
    .bloc-image{
      flex-basis: 50%;
      @media (max-width: 992px) {
        flex-basis: 100%;
      }
      &.formulaire{
        padding-left: 20px;
        padding-top: 0;
        @media (max-width: 992px) {
          padding-left: 0;
          padding-right: 0;
        }
        .desc.single{
          text-align: left;
          padding-left: 15px;
          padding-right: 15px;
          font-size: 14px;
        }
        .form-group{
          text-align: left;
          label, span{
            text-align: left;
          }
        }
      }
    }
    .div-star{
      color: #808284;
      font-size: 12px;
      text-align: left;
    }
  }

  &.more-padding {
    .bloc-texte {
      margin-top: 3px;
      padding-left: 0px;
      .desc {
        padding-right: 30%;
      }

      &:last-child {
        .desc {
          padding-left: 15%;
          padding-right: 0;
        }
      }
    }
  }

  .bloc-title {
    text-align: center;
    position: relative;
    z-index: 2;
    width: 100%;
    margin-bottom: 40px;
    + *,
    + * > .desc {
      margin-top: 0;
    }

    .desc,
    .desc p {
      font-size: 20px;
    }

    .title {
      font-weight: 800;
    }

    .title-h2 {
      &::before {
        content: "";
        background-color: @secondary-presentation;
        position: relative;
        display: block;
        height: 4px;
        width: 30px;
        margin: 15px auto;
        transform: translateX(-50%);
      }
    }

    &.in-left {
      text-align: left;
      width: 33%;
      padding-right: 5%;
      padding-left: 34px;
      @media (max-width: 992px) {
        width: 100%;
        text-align: center;
      }

      .title-h2 {
        line-height: 46px;
        position: relative;
        font-weight: 800;
        display: inline-block;
        &::before {
          content: "";
          background-color: @white;
          position: absolute;
          width: 5px;
          height: 100%;
          top: 0px;
          left: -31px;
        }
      }

      .desc,
      .desc p {
        font-size: 14px;
        margin-top: 43px;
        @media (max-width: 640px) {
          line-height: 20px;
        }
      }

      &::before {
        display: none;
      }
    }

    @media (max-width: 1366px) {
      + .bloc-desc {
        padding: 0 15px;
      }
    }

    @media (max-width: 640px) {
      + .bloc-desc {
        padding: 0 0;
      }
    }
  }

  .bloc-desc {
    .desc {
      letter-spacing: 0px;
      padding-right: 0;
      width: 100%;
      &.single {
        letter-spacing: 1px;
        padding-left: 0px;
        padding-right: 15%;
        strong {
          font-weight: 600;
        }
      }

      p + ul {
        margin-top: 25px;
      }
    }

    &.list-style-square,
    &.list-style-check {
      .desc {
        ul,
        ol {
          &:nth-child(1) {
            margin-top: 48px;
          }

          &:nth-last-child(1) {
            margin-bottom: 55px;
          }
        }
      }
    }

    &.img-only {
      margin-top: 30px;
      padding-bottom: 50px;
      text-align: center;
      width: 100%;
      .lbl-title {
        display: block;
        margin-top: 15px;
        + img {
          margin-top: 10px;
        }
      }

      img {
        margin: 0 auto;
      }
    }

    &.with-right-img {
      margin-bottom: 30px;
      position: relative;
      .f-start {
        align-items: flex-start;
        margin-bottom: 25px;
      }

      > .desc {
        font-size: 20px;
        margin-top: 0px;
        text-align: center;
        &.single {
          padding-left: 8.5%;
          padding-right: 7%;
          text-align: left;
          strong {
            font-weight: 600;
          }
        }
      }

      > img {
        display: block;
        position: absolute;
        top: 0;
        right: -9%;
        left: initial;
        bottom: initial;
        max-width: 38%;
        /* top: 40%;
        transform: translate(0%,-50%);
        */
        + .desc {
          margin-top: 0;
        }

        @media (max-width: 1200px) {
          top: 50px;
        }

        @media (max-width: 992px) {
          display: none;
        }
      }

      .right-img {
        width: 100%;
      }

      .bloc-steps {
        margin-top: 25px;
        @media (max-width: 768px) {
          display: block !important;
        }

        .title {
          padding-left: 0;
        }

        .step {
          &:not(:last-child) {
            .title {
              &::after {
                right: 0;
                left: 0;
              }
            }
          }
        }
      }
    }

    + .bloc-img-txt {
      margin-top: 32px;
    }

    + .bloc-texte {
      margin-top: 0px;
      .desc {
        margin-top: 0px;
      }

      + .bloc-texte {
        margin-top: 0px;
        .desc {
          &.single {
            margin-top: 32px;
            strong {
              font-weight: 600;
            }
          }
        }
      }
    }
  }

  .bloc-steps {
    margin-top: 38px;
    padding-left: 0;
    margin-bottom: 30px;
    justify-content: center;
    &.col-sm-12 {
      padding-left: 6%;
      padding-bottom: 0;
    }

    .step {
      padding: 0;
      &:not(:last-child) {
        .title {
          &::after {
            border-top: 2px dotted @blue-presentation;
            content: "";
            display: block;
            position: absolute;
            bottom: initial;
            right: -19%;
            left: 19%;
            top: 50%;
            z-index: 0;
            @media (max-width: 768px) {
              display: none;
            }
          }
        }
      }

      .desc {
        margin-bottom: 42px;
      }
    }

    .title {
      margin-bottom: 39px;
      padding-left: 19%;
      position: relative;
      @media (max-width: 640px) {
        padding-left: 0;
        margin-bottom: 15px;
      }
      .icon {
        background: @blue-presentation;
        border-radius: 50%;
        color: @white;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 29px;
        font-weight: 100;
        height: 66px;
        position: relative;
        width: 66px;
        z-index: 1;
        img {
          height: 30px;
          width: 30px;
          position: relative;
          top: initial;
          right: initial;
          left: initial;
          bottom: initial;
          max-width: 100%;
        }
      }

      .number {
        background: @blue-presentation;
        border-radius: 50%;
        color: @white;
        display: block;
        font-size: 36px;
        font-weight: 700;
        height: 66px;
        line-height: 53px;
        padding: 6px 0px;
        position: relative;
        text-align: center;
        width: 66px;
        z-index: 1;
      }
    }

    .lbl-title {
      letter-spacing: 0px;
      margin-bottom: 24px;
    }

    ul {
      padding-left: 20px;
    }

    .desc {
      letter-spacing: 0px;
      padding-right: 18%;
      @media (max-width: 640px) {
        padding-right: 0;
      }
    }
  }

  .bloc-img-txt {
    display: flex;
    flex-wrap: wrap;
    flex-basis: 100%;
    position: relative;
    .img-content {
      flex-basis: 14%;
      position: relative;
      img {
        position: absolute;
        left: 0;
        max-width: 80%;
        top: 7px;
      }
    }

    .txt-content {
      flex-basis: 86%;
      position: relative;
      .desc {
        letter-spacing: 0.2px;
        padding-right: 9%;
        width: 100%;
        a {
          color: @primary;
          text-decoration: underline;
          &:hover {
            color: @secondary-presentation;
          }
        }

        &.single {
          letter-spacing: 1px;
          padding-left: 50px;
          padding-right: 15%;
          strong {
            font-weight: 600;
          }
        }
      }
    }

    + .desc.single {
      margin-top: 10px;
      padding-right: 0;
      strong {
        font-weight: 600;
      }
    }
  }

  .list-style-square,
  .list-style-check {
    .desc {
      ul,
      ol {
        padding-right: 0;
      }
    }
  }

  .lbl-title {
    color: @black-presentation;
    font-size: 18px;
    font-weight: 700;
    letter-spacing: 1px;
    line-height: 28px;
    text-transform: uppercase;
    padding-right: 20px;
    + .desc {
      margin-top: 5px;
    }
  }

  .lbl-subtitle {
    color: @black-presentation;
    font-size: 18px;
    font-weight: 700;
    letter-spacing: 1px;
    line-height: 28px;
    margin-bottom: 0px;
    text-transform: none;
    + .desc {
      margin-top: 0px;
    }
  }

  .title {
    &.title-h2 {
      + .bloc-img-txt {
        margin-top: 32px;
      }

      + .bloc-desc {
        .desc {
          padding-right: 5%;
        }
      }
    }
  }

  .type-7 {
    .bloc-desc {
      width: 100%;
    }
  }

  .type-8 {
    .bloc-desc.img-only {
      margin-top: 0;
    }
  }

  .type-14 {
    .bloc-desc {
      .desc {
        letter-spacing: 0.18px;
      }
    }

    .bloc-texte {
      padding-left: 28px;
      .desc {
        padding-right: 24%;
      }

      &.one {
        padding-left: 88px;
        .desc {
          padding-right: 22%;
        }
      }
    }

    .bloc-texte.list-style-check,
    .bloc-texte.list-style-square {
      .desc {
        p + ul {
          margin-top: 7px;
        }
      }
    }
  }

  .type-15,
  .type-16 {
    .bloc-desc {
      .desc {
        margin-bottom: 36px;
      }
    }

    .bloc-texte {
      &.less-margin {
        > .desc:nth-child(1) {
          + .lbl-title {
            margin-top: 0px;
          }
        }
      }
    }
  }

  .type-17 {
    margin-left: -30px;
    margin-right: -30px;
    .bloc-desc {
      .desc {
        letter-spacing: 0.22px;
      }
    }

    .bloc-steps {
      margin-top: 50px;
      margin-bottom: 50px;
      .title {
        padding-left: 0;
      }

      &.col-sm-12 {
        padding-left: 19.2%;
        padding-right: 14%;
      }

      .step {
        .desc {
          margin-bottom: 15px;
          padding-right: 24%;
        }
      }
    }
  }

  img {
    &.more-padding {
      padding-top: 20px;
    }
  }

  @media (max-width: 992px) {
    .right-align,
    .left-align {
      text-align: center;
    }
  }
}

.bloc-entete {
  .container {
    padding: 0 15px;
    .col-sm-12 {
      @media (max-width: 992px) {
        padding-left: 15px;
        padding-right: 15px;
      }
    }
  }

  .title.title-h2 {
    &.left-aligned {
      position: relative;
      text-align: left;
      /* display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: end;
      -webkit-justify-content: flex-end;
      -ms-flex-pack: end;
      */
      justify-content: flex-end;
      width: 590px;
      max-width: 100%;
      padding-left: 30px;
      &::before {
        content: "";
        background-color: #f7a800;
        position: absolute;
        width: 5px;
        height: 100%;
        top: 0;
        left: 0px;
        margin: 0;
        -webkit-transform: none;
        -ms-transform: none;
        transform: none;
      }
    }

    &.right-aligned {
      position: relative;
      text-align: left;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: end;
      -webkit-justify-content: flex-end;
      -ms-flex-pack: end;
      justify-content: flex-end;
      float: right;
      &::before {
        content: "";
        background-color: #f7a800;
        position: absolute;
        width: 5px;
        height: 100%;
        top: 0;
        left: -28px;
        margin: 0;
        -webkit-transform: none;
        -ms-transform: none;
        transform: none;
      }
    }
  }
}
