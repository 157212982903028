
/* 404*/

#particles-js {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    + div {
      pointer-events: none;
      .bloc-btn{
        pointer-events: auto;
      }
    }
}
.back-body{
  background-image:url(../images/404/back.jpg);
  background-repeat:no-repeat;
  background-size: cover;
  height: 100vh;
}
div#primary .container {
    text-align: center;
}
.container-relatif{
    height: 100vh;
    position: relative;
}
.bloc-logo {
    margin-top: 5%;
    margin-bottom: 5%;
}
.text-intro{
  font-size: 16px;
  color: #ffffff87;
  font-weight: 100;
  text-transform: uppercase; 
    p{
      margin: 10px 0 !important;
    }
}
.text404{
  font-size: 180px;
  color: #545e7f;
  font-weight: bold;
  -webkit-text-stroke-width: 3px;
  -webkit-text-stroke-color: #727d96;
  line-height: 1;
}
.page-not{
  font-size: 24px;
  font-weight: 500;
  color: #ffffff;
}
.text-page{
  font-size: 16px; 
  color:#ffffff; 
  font-weight: 500;
}
.bloc-iman img {
    width: 21%;
    transform-origin: 100% 100%;
    transform: rotate(-12deg);
    
    -webkit-animation: floating 40s infinite; /* Safari 4+ */
    -moz-animation:    floating 40s infinite; /* Fx 5+ */
    -o-animation:      floating 40s infinite; /* Opera 12+ */
    animation:         floating 40s infinite; /* IE 10+, Fx 29+ */
}
.bloc-404{
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    bottom: 10%;
}
.bloc-btn{
      position: relative;
      .bloc-iman {
        position: absolute;
        bottom: 0;
        width: 100%;
        z-index: -1;
      }
   input {
      color: #53607f;
      font-size: 14px;
      font-weight: bold;
      text-transform: uppercase;
      background: #fff;
      border: 2px solid #53607f;
      padding: 14px 65px;
      border-radius: 10px;
      margin-top: 25px;
      text-decoration: none !important;
      cursor: pointer;
      &:hover{
          background-color: #53607f;
          color: #fff;

      }
    }
}
@media (max-width: 1400px) {
  .bloc-iman img {
        width: 16%;
    }
  .text404 {
    font-size: 100px;
    -webkit-text-stroke-width: 2px;
  }
  .text-intro p {
    margin: 5px 0!important;
  }
  .bloc-logo {
    margin-top: 3%;
    margin-bottom: 2%;
  }

}
@media (max-width: 764px) {
  .bloc-iman img {
        width: 50%;
    }
    .bloc-404 {
      bottom: 12%;
  }
}
@media (max-width: 400px) {
  .bloc-iman img {
        width: 50%;
    }
    .bloc-404 {
      bottom: 12%;
  }
  .text404 {
    font-size: 70px;
  }
  .page-not {
    font-size: 17px;
  }
  .text-page {
    font-size: 13px;
    padding: 0 15px;
  }
  .text-intro p {
    margin: 12px 0!important;
    font-size: 12px;
  }
}
@media (max-width: 350px) {
  .bloc-iman img {
        width: 50%;
    }
  .bloc-404 {
    bottom: 5%;
  }
  .text404 {
    font-size: 60px;
  }
 
}
/*fin 404*/


@-webkit-keyframes floating {
  0%, 100% {-webkit-transform: rotate(-12deg);}
  50% {-webkit-transform: translateY(0deg);}
  
}
@-moz-keyframes floating {
  0%, 100% {-moz-transform: rotate(-12deg);}
  50% {-moz-transform: rotate(0deg);}
  
}
@keyframes floating {
  0%, 100% {transform: rotate(-12deg);}
  50% {transform: rotate(0deg);}
  
}