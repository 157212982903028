.bloc-a-propos-actus {
  color: @black-a-propos-actus;
  padding: 35px 0px;
  padding-bottom: 0;
  position: relative;
  overflow-x: hidden;
  .container {
    @media (min-width: 1374px) {
      padding: 0 25px;
    }
  }
  .row {
    align-items: center;
    .col-sm-12 {
      flex-wrap: wrap;
      padding: 0;
      &.d-flex {
        padding-left: 74px;
        padding-right: 64px;
        @media (max-width: 1200px) {
          padding-left: 15px;
          padding-right: 15px;
        }
      }
    }
    + .desc {
      margin-top: 0;
      position: relative;
      top: -15px;
      padding-right: 0;
      letter-spacing: 0.3px;
    }
  }
  .title.title-h2 {
    line-height: 46px;
    &.left-aligned {
      &::before {
        content: "";
        background-color: @secondary-a-propos-actus;
        position: absolute;
        width: 2px;
        height: 75px;
        top: 0px;
        left: -28px;
        margin: 0;
        transform: none;
      }
    }
    &.right-aligned {
      display: flex;
      justify-content: flex-end;
      &::before {
        content: "";
        background-color: @secondary-a-propos-actus;
        position: relative;
        width: 5px;
        height: 92px;
        top: 0px;
        left: -28px;
        margin: 0;
        transform: none;
      }
    }
    &.left-aligned,
    &.right-aligned {
      position: relative;
      text-align: left;
    }
  }
  .title.title-h4 {
    line-height: 18px;
    font-weight: 700;
    letter-spacing: 1px;
  }
  .date-txt {
    font-size: 18px;
    font-weight: 800;
    line-height: 18px;
    padding-left: 12px;
    padding-right: 11px;
    position: relative;
    text-align: right;
    text-transform: uppercase;
    .annee {
      display: block;
      font-weight: 600;
      margin-top: 4px;
    }
    .mois {
      display: block;
    }
    &::after {
      content: "";
      display: block;
      background-color: @secondary-a-propos-actus;
      position: absolute;
      width: 2px;
      height: 75px;
      top: -2px;
      right: 0px;
    }
  }
  .txt-desc {
    .desc {
      margin-top: 5px;
    }
  }
  .liste-articles {
    > .d-flex {
      @media (max-width: 768px) {
        flex-direction: column;
      }
    }
    .bloc-image {
      flex-basis: 40%;
      margin-bottom: 60px;
      margin-top: 20px;
      padding-left: 10px;
      /*     display: flex;
      justify-content: flex-start; */
      &.less-margin {
        margin-bottom: 0px;
        margin-top: 11px;
        .desc {
          &.single {
            margin-top: 23px;
            padding-right: 9%;
            padding-left: 9%;
          }
        }
      }
      @media (max-width: 992px) {
        flex-basis: 40%;
      }
      @media (max-width: 768px) {
        margin-bottom: 15px;
      }
      img {
        width: 100%;
        box-shadow: 0px 0px 7px 1px rgba(0, 0, 0, 0.35);
        -webkit-box-shadow: 0px 0px 7px 1px rgba(0, 0, 0, 0.35);
        -moz-box-shadow: 0px 0px 7px 1px rgba(0, 0, 0, 0.35);
        height: auto;
      }
      video {
        max-width: 100%;
      }
    }
    .bloc-texte {
      flex-basis: 60%;
      display: flex;
      margin-bottom: 20px;
      margin-top: 20px;
      text-align: left;
      position: relative;
      @media (max-width: 768px) {
        order: 2;
      }
      @media (min-width: 769px) {
        &:last-child {
          padding-left: 30px;
        }
      }
      &.less-margin {
        margin-bottom: 8px;
        margin-top: 0px;
        > .desc {
          &:nth-child(1) {
            margin-bottom: 0;
            margin-top: 0px;
            + .lbl-title {
              margin-top: 20px;
            }
          }
        }
      }
      @media (max-width: 992px) {
        flex-basis: 100%;
      }
      &::before {
        content: "";
        background-color: @secondary-a-propos-actus;
        position: relative;
        display: block;
        height: 4px;
        width: 30px;
        top: 0px;
        margin: 0 auto;
        margin-bottom: 5px;
      }
      .title {
        font-weight: 800;
        &.title-h4 {
          font-weight: 700;
          margin-left: 28px;
          min-height: 67px;
          padding-right: 32px;
        }
        + .lbl-title {
          margin-top: 45px;
        }
      }
      .title-h2 {
        line-height: 46px;
        position: relative;
        &::before {
          content: "";
          background-color: @secondary-a-propos-actus;
          position: absolute;
          width: 2px;
          height: 75px;
          top: 0px;
          left: -28px;
        }
      }
      &::before {
        display: none;
      }
      &.one {
        flex-basis: 55%;
        @media (max-width: 992px) {
          flex-basis: 100%;
        }
      }
      &.list-style-check {
        .desc {
          p {
            + ul,
            + ol {
              margin-top: 25px;
            }
          }
        }
      }
      .desc {
        margin-left: 28px;
        padding-right: 15px;
      }
      .btn {
        margin-left: 28px;
      }
    }
  }

  .desc {
    color: @grey-a-propos-actus;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 1.15px;
    line-height: 28px;
    margin-bottom: 25px;
    margin-top: 40px;
    padding-right: 22%;
    strong {
      font-weight: 600;
    }
    a {
      color: @grey-a-propos-actus;
      text-decoration: underline;
      &:hover {
        color: @secondary-a-propos-actus;
      }
    }
    &.single {
      color: @black-a-propos-actus;
      font-size: 20px;
    }
    p {
      + p {
        margin-top: 7px;
      }
    }
    + .lbl-title {
      margin-top: 40px;
    }
    + .bloc-img-txt {
      margin-top: 32px;
    }
  }
  .list-style-square {
    ol {
      color: @grey-a-propos-actus;
      counter-reset: item;
      margin: 0;
      padding: 0;
      position: relative;
      li {
        font-size: 14px;
        font-weight: 400;
        letter-spacing: 1px;
        line-height: 28px;
        padding-left: 13px;
        position: relative;
        list-style-type: none;
        counter-increment: item;
        strong {
          font-weight: 600;
        }
        &::before {
          display: inline-block;
          width: 1em;
          padding-right: 18px;
          font-weight: 600;
          text-align: right;
          content: counter(item) ".";
        }
        &::after {
          content: "";
          background-color: @secondary-a-propos-actus;
          display: block;
          position: absolute;
          left: 0;
          top: 12px;
          width: 3px;
          height: 3px;
          line-height: 28px;
        }
      }
    }
    ul {
      color: @grey-a-propos-actus;
      list-style: none;
      margin: 0;
      padding: 0;
      position: relative;
      padding-right: 22%;
      li {
        font-size: 14px;
        font-weight: 400;
        letter-spacing: 1px;
        line-height: 28px;
        padding-left: 13px;
        display: block;
        position: relative;
        strong {
          font-weight: 600;
        }
        &::before {
          content: "";
          background-color: @secondary-a-propos-actus;
          display: block;
          position: absolute;
          left: 0;
          top: 12px;
          width: 3px;
          height: 3px;
          line-height: 28px;
        }
      }
    }
    &.nb-col-list-2,
    &.nb-col-list-3 {
      ul,
      ol {
        li {
          margin-bottom: 20px;
          strong {
            color: @black-a-propos-actus;
          }
        }
        + .desc,
        + p {
          margin-top: 25px;
        }
      }
      .desc {
        + ul,
        + ol {
          margin-top: 32px;
        }
      }
      p {
        + ul,
        + ol {
          margin-top: 32px;
        }
      }
      ol {
        li {
          &::before {
            color: @black-a-propos-actus;
          }
        }
      }
    }
  }
  .list-style-check {
    ul {
      color: @grey-a-propos-actus;
      list-style: none;
      margin: 0;
      padding: 0;
      position: relative;
      padding-right: 22%;
      li {
        font-size: 14px;
        font-weight: 400;
        letter-spacing: 1px;
        line-height: 28px;
        padding-left: 30px;
        display: block;
        position: relative;
        strong {
          font-weight: 600;
        }
        &::before {
          content: "\ea10";
          color: @secondary-a-propos-actus;
          display: block;
          position: absolute;
          left: 0;
          top: 0;
          line-height: 28px;
          font-family: "icomoon" !important;
          speak: none;
          font-style: normal;
          font-weight: normal;
          font-variant: normal;
          text-transform: none;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
        }
      }
    }
    ol {
      color: @grey-a-propos-actus;
      counter-reset: item;
      margin: 0;
      padding: 0;
      position: relative;
      li {
        font-size: 14px;
        font-weight: 400;
        letter-spacing: 1px;
        line-height: 28px;
        padding-left: 30px;
        position: relative;
        list-style-type: none;
        counter-increment: item;
        strong {
          font-weight: 600;
        }
        &::before {
          display: inline-block;
          width: 1em;
          padding-right: 18px;
          font-weight: 600;
          text-align: right;
          content: counter(item) ".";
        }
        &::after {
          content: "\ea10";
          color: @secondary-a-propos-actus;
          display: block;
          position: absolute;
          left: 0;
          top: 0;
          line-height: 28px;
          font-family: "icomoon" !important;
          speak: none;
          font-style: normal;
          font-weight: normal;
          font-variant: normal;
          text-transform: none;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
        }
      }
    }
    &.nb-col-list-2,
    &.nb-col-list-3 {
      ul,
      ol {
        li {
          strong {
            color: @black-a-propos-actus;
          }
        }
      }
      ol {
        li {
          &::before {
            color: @black-a-propos-actus;
          }
        }
      }
    }
  }
  .nb-col-list-2 {
    ul,
    ol {
      -moz-column-count: 2;
      -webkit-column-count: 2;
      column-count: 2;
      -moz-grid-column-gap: 19%;
      -webkit-grid-column-gap: 19%;
      grid-column-gap: 19%;
      padding: 0 9%;
      @media (max-width: 768px) {
        -moz-column-count: 1;
        -webkit-column-count: 1;
        column-count: 1;
      }
    }
  }
  .nb-col-list-3 {
    ul,
    ol {
      -moz-column-count: 3;
      -webkit-column-count: 3;
      column-count: 3;
      -moz-grid-column-gap: 5%;
      -webkit-grid-column-gap: 5%;
      grid-column-gap: 5%;
      padding: 0 7%;
      @media (max-width: 992px) {
        -moz-column-count: 2;
        -webkit-column-count: 2;
        column-count: 2;
      }
      @media (max-width: 768px) {
        -moz-column-count: 1;
        -webkit-column-count: 1;
        column-count: 1;
      }
    }
  }
  .check-list {
    list-style: none;
    margin: 0;
    padding: 0;
    position: relative;
    color: @grey-a-propos-actus;
    padding-right: 22%;
    li {
      align-items: flex-start;
      display: flex;
      position: relative;
      font-size: 14px;
      font-weight: 400;
      letter-spacing: 1px;
      line-height: 28px;
      padding-left: 0px;
      i {
        color: @secondary-a-propos-actus;
        margin-right: 15px;
        line-height: 28px;
      }
      strong {
        font-weight: 600;
      }
      &::before {
        display: none;
      }
    }
  }
  .parallax-bg {
    height: 312px;
  }
  .btn.btn-decouvrir {
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 600;
    border-radius: 5px;
    padding: 7px;
    min-width: 189px;
    color: @primary-a-propos-actus;
    background-color: @white;
    border: 1px solid @primary-a-propos-actus;
    @media (max-width: 640px) {
      margin-bottom: 30px;
    }
    &:hover {
      background-color: @primary-a-propos-actus;
      color: @white;
    }
    &.orange {
      background-color: @secondary-a-propos-actus;
      border-color: @secondary-a-propos-actus;
      color: @white;
      font-size: 14px;
      font-weight: 400;
      margin-left: 64px;
      padding-left: 20px;
      padding-right: 20px;
      text-decoration: underline;
      text-transform: uppercase;
      span {
        display: inline-block;
        font-weight: 700;
        text-decoration: none;
      }
      &:hover {
        background-color: @primary-a-propos-actus;
        border-color: @primary-a-propos-actus;
        color: @white;
      }
    }
  }
  .btn.btn-ensavoirplus {
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 600;
    border-radius: 5px;
    padding: 7px;
    min-width: 189px;
    color: @primary-a-propos-actus;
    background-color: @white;
    border: 1px solid @primary-a-propos-actus;
    &:hover {
      background-color: @primary-a-propos-actus;
      color: @white;
    }
  }
  .img-link {
    margin-bottom: 15px;
    -webkit-box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.35);
    -moz-box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.35);
    box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.35);
  }

  &.with-lists {
    .bloc-texte {
      div.desc {
        padding-right: 0;
        p {
          padding-right: 22%;
          margin-bottom: 25px !important;
        }
      }
    }
  }
  &.with-bg-img {
    padding-bottom: 0px;
    .container {
      background-repeat: no-repeat;
      background-position: 100% 100%;
      @media (max-width: 1279px) {
        background-position: 150% 100%;
      }
      @media (max-width: 992px) {
        background-image: none !important;
      }
    }
    .bloc-desc {
      width: 100%;
      .desc {
        margin-top: 44px;
      }
      + .bloc-texte {
        margin-top: 0px;
        .desc {
          margin-top: 0px;
        }
      }
    }
    .bloc-texte {
      padding-left: 30px;
      margin-bottom: 110px;
      .desc {
        letter-spacing: 0.24px;
        padding-right: 15px;
      }
      &.list-style-check {
        ul {
          margin-top: 7px;
          li {
            letter-spacing: 0.24px;
          }
        }
      }
      @media (max-width: 992px) {
        margin-bottom: 40px;
      }
    }
  }
  &.more-padding {
    padding-left: 105px;
    padding-right: 105px;
    .bloc-texte {
      margin-top: 3px;
      padding-left: 0px;
      .desc {
        padding-right: 30%;
      }
      &:last-child {
        .desc {
          padding-left: 15%;
          padding-right: 0;
        }
      }
    }
  }
  .bloc-title {
    text-align: center;
    position: relative;
    z-index: 2;
    width: 100%;
    .desc,
    .desc p {
      font-size: 20px;
    }
    .title {
      font-weight: 800;
      letter-spacing: -0.25px;
    }
    .title-h2 {
      &::before {
        content: "";
        background-color: @secondary-a-propos-actus;
        position: relative;
        display: block;
        height: 4px;
        width: 30px;
        margin: 15px auto;
        transform: translateX(-50%);
      }
    }
    &.in-left {
      text-align: left;
      width: 33%;
      padding-right: 5%;
      padding-left: 34px;
      @media (max-width: 992px) {
        width: 100%;
        text-align: center;
      }
      .title-h2 {
        line-height: 46px;
        position: relative;
        font-weight: 800;
        display: inline-block;
        &::before {
          content: "";
          background-color: @white;
          position: absolute;
          width: 2px;
          height: 75px;
          top: 0px;
          left: -31px;
        }
      }
      .desc,
      .desc p {
        font-size: 14px;
        margin-top: 43px;
      }
      &::before {
        display: none;
      }
    }
  }
  .bloc-desc {
    .desc {
      letter-spacing: 0px;
      padding-right: 0;
      width: 100%;
      @media (max-width: 992px) {
        padding-left: 20px;
        padding-right: 20px;
      }
      &.single {
        letter-spacing: 1px;
        padding-left: 50px;
        padding-right: 15%;
      }
    }
    &.list-style-square,
    &.list-style-check {
      .desc {
        ul,
        ol {
          &:nth-child(1) {
            margin-top: 48px;
          }
          &:nth-last-child(1) {
            margin-bottom: 55px;
          }
        }
      }
    }
    &.img-only {
      margin-top: 30px;
      padding-bottom: 50px;
      text-align: center;
      width: 100%;
      .lbl-title {
        display: block;
        + img {
          margin-top: 10px;
        }
      }
      img {
        margin: 0 auto;
      }
    }
    &.with-right-img {
      margin-bottom: 80px;
      position: relative;
      > .desc {
        font-size: 20px;
        margin-top: 45px;
        text-align: center;
        &.single {
          padding-left: 11.5%;
          padding-right: 7%;
          text-align: left;
        }
      }
      img {
        display: block;
        position: absolute;
        top: 0;
        right: -9%;
        left: initial;
        bottom: initial;
        max-width: 38%;
        @media (max-width: 1450px) {
          max-width: 28%;
          right: 0px;
          top: 10px;
        }
        @media (max-width: 1200px) {
          top: 50px;
        }
        @media (max-width: 992px) {
          display: none;
        }
      }
      .bloc-steps {
        margin-top: 72px;
        .title {
          padding-left: 0;
        }
        .step {
          &:not(:last-child) {
            .title {
              &::after {
                right: 0;
                left: 0;
              }
            }
          }
        }
      }
    }
    + .bloc-img-txt {
      margin-top: 32px;
    }
    + .bloc-texte {
      margin-top: 0px;
      .desc {
        margin-top: 0px;
      }
      + .bloc-texte {
        margin-top: 0px;
        .desc {
          &.single {
            margin-top: 32px;
          }
        }
      }
    }
  }
  .bloc-steps {
    margin-top: 38px;
    padding-left: 6%;
    &.col-sm-12 {
      padding-left: 6%;
    }
    @media screen and (max-width: 640px) {
      padding: 0;
    }
    .step {
      padding: 0;
      &:not(:last-child) {
        .title {
          &::after {
            border-top: 2px dotted @blue-a-propos-actus;
            content: "";
            display: block;
            position: absolute;
            bottom: initial;
            right: -19%;
            left: 19%;
            top: 50%;
            z-index: 0;
          }
        }
      }
      .desc {
        margin-bottom: 42px;
      }
    }
    .title {
      margin-bottom: 39px;
      padding-left: 19%;
      position: relative;
      @media screen and (max-width: 640px) {
        text-align: center;
        padding-left: 0;
      }
      .icon {
        background: @blue-a-propos-actus;
        border-radius: 50%;
        color: @white;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 29px;
        font-weight: 100;
        height: 66px;
        position: relative;
        width: 66px;
        z-index: 1;
        @media screen and (max-width: 640px) {
          display: inline-flex;
        }
        img {
          height: 30px;
          width: 30px;
          position: relative;
          top: initial;
          right: initial;
          left: initial;
          bottom: initial;
          max-width: 100%;
        }
      }
      .number {
        background: @blue-a-propos-actus;
        border-radius: 50%;
        color: @white;
        display: block;
        font-size: 36px;
        font-weight: 600;
        height: 66px;
        line-height: 53px;
        padding: 6px 0px;
        position: relative;
        text-align: center;
        width: 66px;
        z-index: 1;
      }
    }
    .lbl-title {
      letter-spacing: 0px;
      margin-bottom: 24px;
    }
    .desc {
      letter-spacing: 0px;
      padding-right: 18%;
      @media screen and (max-width: 640px) {
        padding-right: 0;
      }
    }
  }
  .bloc-img-txt {
    display: flex;
    flex-wrap: wrap;
    flex-basis: 100%;
    position: relative;
    .img-content {
      flex-basis: 14%;
      position: relative;
      img {
        position: absolute;
        left: 0;
        max-width: 100%;
        top: 7px;
      }
    }
    .txt-content {
      flex-basis: 86%;
      position: relative;
      .desc {
        letter-spacing: 0.2px;
        padding-right: 9%;
        width: 100%;
        a {
          color: @grey-a-propos-actus;
          text-decoration: underline;
          &:hover {
            color: @secondary-a-propos-actus;
          }
        }
        &.single {
          letter-spacing: 1px;
          padding-left: 50px;
          padding-right: 15%;
        }
      }
    }
    + .desc.single {
      margin-top: 10px;
      padding-right: 0;
    }
  }
  .list-style-square,
  .list-style-check {
    .desc {
      ul,
      ol {
        padding-right: 0;
      }
    }
  }
  .lbl-title {
    color: @black-a-propos-actus;
    font-size: 18px;
    font-weight: 700;
    letter-spacing: 1px;
    line-height: 28px;
    text-transform: uppercase;
    + .desc {
      margin-top: 5px;
    }
  }
  .lbl-subtitle {
    color: @black-a-propos-actus;
    font-size: 18px;
    font-weight: 700;
    letter-spacing: 1px;
    line-height: 28px;
    margin-bottom: 0px;
    text-transform: none;
    + .desc {
      margin-top: 0px;
    }
  }
  .title {
    &.title-h2 {
      + .bloc-img-txt {
        margin-top: 32px;
      }
      + .bloc-desc {
        .desc {
          padding-right: 5%;
        }
      }
    }
  }
  img {
    &.more-padding {
      padding-top: 20px;
    }
  }
  @media (max-width: 992px) {
    .right-align,
    .left-align {
      text-align: center;
    }
  }
}
