.selecter {
  display: inline-block;
  margin: 0;
  position: relative;
  text-align: left;
  vertical-align: middle;
  width: 100%;
  outline: none;
  z-index: 1;
}
	.selecter .selecter-element { *left: -999999px; height: 100%; left: 0; position: absolute; opacity: 0; width: 100%; z-index: -1; }
	.selecter .selecter-element,
	.selecter .selecter-element:focus { outline: none;
		-webkit-tap-highlight-color: rgba(255, 255, 255, 0);
		-webkit-tap-highlight-color: transparent;
	}

.selecter .selecter-selected {
  color: #595959;
  cursor: pointer;
  display: block;
  margin: 0;
  overflow: hidden;
  padding: 14px 10px;
  position: relative;
  text-overflow: clip;
  text-transform: uppercase;
  vertical-align: top;
  z-index: 2;
  border: 1px solid #eaeaea;
  &::after{
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    width: 48px;
    height: 100%;
    background: #53607f;
  }
  &::before{
    content: '';
    position: absolute;
    right: 17px;
    top: 18px;
    .transform(rotate(0deg));
    z-index: 2;
    color: white;
    font-size: 20px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 10px 7px 0 7px;
    border-color: #ffffff transparent transparent transparent;
  }
}

.selecter.open .selecter-selected {
  // border-left: 3px solid #900620;
  &::before{
    .transform(rotate(-180deg));
  }
}

.selecter.intitule .selecter-group{
    background: none !important; 
    border-bottom: none  !important;
    color: #000 !important;
    display: block !important;
    font-size: 17px !important;
    padding: 5px 10px 4px !important;
    text-transform: uppercase !important;
    font-weight: 500 !important;
}
.selecter.intitule .selecter-item{
  padding-left: 25px !important;
}
.selecter .intitule .selecter-group{
    background: none !important; 
    border-bottom: none  !important;
    color: #000 !important;
    display: block !important;
    font-size: 17px !important;
    padding: 5px 10px 4px !important;
    text-transform: uppercase !important;
    font-weight: 500 !important;
}
.selecter .intitule .selecter-item{
  padding-left: 25px !important;
}
.intitule.selecter .selecter-group{
    background: none !important; 
    border-bottom: none  !important;
    color: #000 !important;
    display: block !important;
    font-size: 17px !important;
    padding: 5px 10px 4px !important;
    text-transform: uppercase !important;
    font-weight: 500 !important;
}
 .intitule.selecter .selecter-item{
  padding-left: 25px !important;
}
.intitule .selecter .selecter-group{
    background: none !important; 
    border-bottom: none  !important;
    color: #000 !important;
    display: block !important;
    font-size: 17px !important;
    padding: 5px 10px 4px !important;
    text-transform: uppercase !important;
    font-weight: 500 !important;
}
 .intitule .selecter .selecter-item{
  padding-left: 25px !important;
}
	.selecter .selecter-options { border: 1px solid #ccc; border-width: 0 1px 1px; background-color: #fefefe; border-radius: 0 0 3px 3px; box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15); display: none; left: 0; margin: 0; max-height: 260px; overflow: auto; overflow-x: hidden; padding: 0; position: absolute; top: 94%; width: 100%; *width: auto; z-index: 50; }
	.selecter .selecter-group { background: #F9F9F9; border-bottom: 1px solid #e3e3e3; color: #999; display: block; font-size: 11px; padding: 5px 10px 4px; text-transform: uppercase; }
	.selecter .selecter-item { text-transform:uppercase;background: #fff; color: #595959; cursor: pointer; display: block; margin: 0; overflow: hidden; padding: 5px 10px; text-overflow: ellipsis; width: 100%; }
	.selecter .selecter-item.placeholder { display: none; }
.selecter .selecter-item.selected {
  background: none repeat scroll 0 0 #f7f7f7;
  font-weight: 400;
}
	.selecter .selecter-item.disabled { color: #999; cursor: default; }
	.selecter .selecter-item:first-child { border-radius: 0; }
	.selecter .selecter-item:last-child { border-radius: 0 0 2px 2px; border-bottom: 0; }

	@media screen and (min-width: 980px) {
		.selecter .selecter-item:hover,
		.selecter .selecter-item.selected:hover { background-color: #f7f7f7;color:#303030; }
		.selecter .selecter-item.disabled:hover { background-color: #fff; }

		.selecter:hover .selecter-selected { background-color: #fff; }

		.selecter.disabled .selecter-item:hover { background: #fff; }
	}

	/* Open */
	.selecter.open { z-index: 3; }
	.selecter.open .selecter-selected { z-index: 51; }

	.selecter.open .selecter-selected,
	.selecter.focus .selecter-selected { color: #595959; background-color: #fff; box-shadow: 0 0 5px rgba(0, 0, 0, 0.1); }

	/* 'Cover' Positioning */
	.selecter.cover .selecter-options { border-radius: 3px; border-width: 1px; top: 0; }
	.selecter.cover .selecter-options .selecter-item.first { border-radius: 3px 3px 0 0; }
	.selecter.cover.open .selecter-selected { border-radius: 3px 3px 0 0; z-index: 49; }

	/* 'Bottom' Positioning */
	.selecter.bottom .selecter-options { border-width: 1px 1px 0; bottom: 100%; top: auto; }
	.selecter.bottom .selecter-item:last-child { border: none; }
	.selecter.bottom.open .selecter-selected { border-radius: 0 0 3px 3px; }
	.selecter.bottom.open .selecter-options { border-radius: 3px 3px 0 0; }

	/* 'Bottom' + 'Cover' Positioning */
	.selecter.bottom.cover .selecter-options { bottom: 0; top: auto; }
	.selecter.bottom.cover.open .selecter-selected { border-radius: 3px; }
	.selecter.bottom.cover.open .selecter-options { border-radius: 3px; }

	/* Multiple Select */
	.selecter.multiple .selecter-options { border-radius: 3px; border-width: 1px; box-shadow: none; display: block; position: static; width: 100%; }

	/* 'Disabled' State */
	.selecter.disabled .selecter-selected { background: #fff; border-color: #eee; color: #ccc; cursor: default; }
	.selecter.disabled .selecter-options { background: #fff; border-color: #eee; }
	.selecter.disabled .selecter-group,
	.selecter.disabled .selecter-item { border-color: #eee; color: #ccc; cursor: default; }
	.selecter.disabled .selecter-item.selected { background: #fafafa; }

	/* Scroller Support */
	.selecter .selecter-options.scroller { overflow: hidden; }
	.selecter .selecter-options.scroller .scroller-content { max-height: 260px; padding: 0; }

	/* Media Queries */
	@media screen and (max-width: 768px) {
		.selecter { max-width: 100%; }
	}