.bloc-ccmarche{
  color: @black-ccmarche;
  padding: 35px 0px;
  padding-bottom: 0;
  position: relative;
  margin-top: 30px;
  > .container {
    padding-left: 95px;
    padding-right: 95px;
    @media (max-width : 992px) {
      padding-left: 0;
      padding-right: 0;
    }
  }
  .row {
    margin: 0;
  }
  @media (max-width: 1280px) {
    > .container > .row {
        margin: 0 0;
    }
  }
  .col-sm-12 {
    padding-left: 0;
    padding-right: 0;
  }
  &.less-padding{
    padding: 40px 0px;
    padding-bottom: 0;
  }
  .bloc-title{
    text-align: center;
    position: relative;
    float: left;
    z-index: 2;
    width: 100%;
    .desc, .desc p{
      font-size: 20px;
    }
    .title{
      font-weight: 800;
    }
    .title-h2{
      &::before{
        content: '';
        background-color: @secondary-ccmarche;
        position: absolute;
        display: block;
        height: 4px;
        width: 30px;
        top: 0px;
        margin: 0 auto;
        margin-bottom: 5px;
        left: 50%;
        top: -20px;
        transform: translateX(-50%);
      }
    }
    &.in-left{
      text-align: left;
      width: 33%;
      padding-right: 5%;
      padding-left: 30px;
      @media (max-width: 992px){
        width: 100%;
        text-align: center;
      }
      .title-h2{
        line-height: 46px;
        position: relative;
        font-weight: 800;
        display: inline-block;
        &::before{
          content: "";
          background-color: @white;
          position: absolute;
          width: 5px;
          height: 100%;
          top:0px;
          left: -28px;
        }
      }
      .desc, .desc p{
        font-size: 14px;
        margin-top: 43px;
      }
      &::before{
        display: none;
      }
    }
  }
  .bloc-desc{
    float: left;
    width: 100%;
    @media (min-width: 769px){
      padding-top: 95px;
    }
    @media (min-width: 993px){
      padding-top: 160px;
    }
    .title{
      letter-spacing: 1px;
      font-weight: 700;
      display: inline-block;
      position: relative;
      .play-icon{
        position: absolute;
        margin: 0 auto;
        font-size: 48px;
        font-weight: 100;
        display: block;
        left: 37%;
        top: -60px;
        @media (max-width: 992px){
          position: static;
          margin-bottom: 15px;
        }
      }
      span img {
        max-width: 50px;
        @media (max-width: 992px){
          display: none;
        }
      }
    }
    &.in-right{
      padding-top: 0px;
      padding-left: 15px;
      width: 67%;
      .desc{
        padding-right: 5%;
      }
      @media (max-width: 992px){
        width: 100%;
        text-align: center;
      }
    }
    >div{
      &.row > div {
        margin-bottom: 35px;
        @media (max-width: 768px){
          margin-bottom: 60px;
        }
        &.center-align {
          padding: 0 6%;
          @media (max-width: 992px){
            padding: 0 15px;
          }
          > div {
            max-width: 200px;
            margin-right: auto; 
            margin-left: auto; 
            color: @white;
          }
          .btn-decouvrir:not(:hover){
            border-color: @white;
            color: @white;
          }
          @media (max-width: 767px){
            padding-top: 90px;
            padding-bottom: 30px;
          }
        }
        &.left-align{
          @media (max-width: 767px){
            padding-top: 65px;
          }
        }
      } 
      &.owl-carousel{
        margin-bottom: 12px;    
        padding-bottom: 25px;
      }
      @media (max-width: 992px){
        .right-align{
          padding-right: 15px;
        }
        .left-align{
          padding-left: 15px;
        }
      }
      @media (min-width: 993px){
        .right-align{
          padding-right: 75px;
          .desc, .desc p{
            padding-left: 15px;
          }
        }
        .left-align{
          padding-left: 75px;
          .desc, .desc p{
            padding-right:15px;
          }
        }
      }
    }
  }
  .desc, .desc p{
    font-weight: 400;
    margin-top: 15px;
    margin-bottom: 30px;
    line-height: 28px;
    letter-spacing: 1px;
    position: relative;
    z-index: 2;
    @media (max-width: 768px) {
      height: auto !important;
      margin-bottom: 15px;
    }
  }
  .title{
    .star-icon{
      position: absolute;
      top: 0px;
      right: -70px;
      font-size: 45px;
      @media (max-width: 992px){
        right: 0px;
        text-align: left;
        position: relative;
        margin-bottom: 15px;
        display: block;
        text-align: center;
      }
    }
    .click-icon{
      position: absolute;
      top: 0px;
      left: -60px;
      font-size: 45px;
      @media (max-width: 992px){
        position: static;
        margin-bottom: 15px;
        display: inline-table;
      }
    }
  }
  .btn.btn-decouvrir{
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 600;
    border-radius: 5px;
    padding: 7px;
    min-width:189px;
    color: @white;
    background-color: transparent;
    border: 1px solid @white;
    position: relative;
    z-index: 2;
    &:hover{
       color: @primary-ccmarche;
       background-color: @white;
    }
  }
  .btn.btn-ensavoirplus{
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 600;
    border-radius: 5px;
    padding: 7px;
    min-width: 189px;
    color: @white;
    background-color: transparent;
    border: 1px solid @white;
    position: relative;
    z-index: 2;
    &:hover{
      color: @primary-ccmarche;
      background-color: @white;
      border-color: @white;
    }
  }
  &.primary{
    background-color: @primary-ccmarche;
    color: @white;
  }
  &.secondary{
    background-color: @secondary-ccmarche;
    color: @white;
    .bloc-title, .title-h2{
      &::before{
        background-color: @white;
      }
    }
    .btn-decouvrir, .btn-ensavoirplus{
      background-color: transparent;
      &:hover{
        color: @secondary-ccmarche;
        background-color: @white;
        border-color: @white;
      }
    }
  }
  &.with-bg-img{
    position: relative;
    overflow: hidden;
    margin-top: 0;
    .bg-img{
      position: absolute;
      bottom: 0;
      right: 0;
      min-height: 100%;
      width: auto;
      display: block;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      @media (max-width: 992px){
        width: auto;
        min-height: 100%;
        max-width: inherit;
      }
    }
    .btn-decouvrir, .btn-ensavoirplus{
      background-color: transparent;
      border-color: @primary-ccmarche;
      color: @primary-ccmarche;
      &:hover{
        color: @white;
        background-color: @primary-ccmarche;
        border-color: @primary-ccmarche;
      }
    }
    .bloc-title{
      &.in-left{
        .title-h2{
          &::before{
            background-color: @secondary-ccmarche;
          }
        }
      }
    }
    .owl-theme{
      .owl-dots{
        .owl-dot{
          span{
            background: @white;
            opacity: 1;
          }
          &.active, &:hover{
            span{
              background-color: @primary-ccmarche;
            }
          }
        }
      }
    }
  }
  &.left-titled{
    .bloc-title{
      position: absolute;
      text-align: left;
      max-width: 330px;
      padding-left: 34px;
      @media (max-width: 992px){
        position: relative;
        text-align: center;
      }
      .title-h2{
        line-height: 46px;
        position: relative;
        &::before{
          content: "";
          background-color: @white;
          position: absolute;
          width: 5px;
          height: 100%;
          top:0px;
          left: -28px;
        }
      }
      .desc, .desc p{
        font-size: 14px;
        margin-top: 43px;
      }
      &::before{
        display: none;
      }
    }
    .bloc-desc{
      padding-top: 248px;
      @media (max-width: 992px){
        padding-top: 0px;
      }
      .title{
        .play-icon{
          top: -100px;
        }
      }
      .desc{
        margin-bottom: 30px;
        margin-top: 30px;
      }
    }
  }
  &.center-titled{
    padding-top: 70px;
    margin: 0;
    .bloc-title{
      .desc{
        margin-top: 32px;
      }
    }
    .bloc-desc{
      >div{
        margin-bottom: 35px;
        &.row {
            margin-right: 0;
            margin-left: 0;
            > div {
              margin-bottom: 0px;
              &.center-align {
                &:nth-child(1){
                  text-align: right;
                  @media (max-width: 768px){
                    text-align: center;
                  }
                  .desc{
                    padding-right: 0;
                    // padding-left: 18%;
                    @media (max-width: 768px){
                      padding: 0;
                    }
                  }
                }
                &:nth-child(3){
                  text-align: left;
                  @media (max-width: 768px){
                    text-align: center;
                  }
                  .des{
                    // padding-right: 18%;
                    padding-left: 0;
                    @media (max-width: 768px){
                      padding: 0;
                    }
                  }
                }
              }
              &.left-align{
              }
            }
        }
      }
      .desc{
        margin-bottom: 30px;
      }
    }
  }
  &.inverted{
    padding: 40px 0px;
    padding-bottom: 0;
    .container{
      .row{
        .col-sm-12{
          padding: 0;
        }
      }
    }
    .bloc-desc{
      &.in-right{
        padding-left: 0px;
        padding-right: 6%;
      }
    }
  }
  &.no-video-img{
    .bloc-desc{
      padding-top: 35px;
      padding-right: 35px;
      .title{
        span, .click-icon, .star-icon, .play-icon{
          position: absolute;
          top: 0px;
          left: -67px;
          font-size: 50px;
        }
      }
    }
  }
  .img-link{
    margin-bottom: 15px;
  }
  .item{
    padding-left: 20px;
    padding-right: 20px;
  }
  .video-img{
    position: absolute;
    bottom: -35px;
    left: 0;
    right: 0;
    max-width: 100%;
    z-index: 0;
    margin: 0 auto;
    @media (max-width: 878px){
      display: none;
    }
  }
  .icon-block{
    margin-bottom: 20px;
    min-height: 50px;
    span{
      font-size: 50px;
      max-height: 50px;
    }
  }
  @media (max-width: 768px){
    .right-align, .left-align{
      text-align: center;
    }
  }
}