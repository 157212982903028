.bloc-filter {
    display: flex;
    justify-content: center;    
    padding: 30px 0 50px;
    .filter-title  {
    	text-decoration: none;
    	text-transform: uppercase;
	    align-items: baseline;
	    padding: 5px 10px;
	    color: white;
	    font-weight: 700;
	    font-size: 16px;
	    display: flex;
	    align-items: center;
	    text-align: center;	
		a {
			text-decoration: none;
			&:hover {color: @primary-filter;}
		}
	    &.current-select {
	    	background: @primary-filter;
	    	a {
	    		color: white !important;
	    	}
		}
	}
	.filter-year  {
		margin: 0;
		padding: 0;
		padding-top: 5px;
		li {
		    &.current-select a{
		    	text-decoration: none;
		    	text-transform: uppercase;
			    align-items: baseline;
			    padding: 5px 10px;
			    color: white !important;
			    background: @primary-filter;
			    font-weight: 700;
			    font-size: 16px;
			    text-align: center;
			}
		    float: left;
		    list-style: none;
		    a {
				color: @grey-a-propos-actus;
				font-size: 16px;
				font-weight: 700;
				padding: 0px 15px;
				text-decoration: none;
				&:hover{
					color: @primary-filter;
				}
		    }
		    &.active{
		    	a{
		    		color: @primary-filter;
		    	}
		    }
		}
	}
}