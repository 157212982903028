.bloc-chiffres-cles {
  background: @white;
  padding: 30px 0;
  float: left;
  width: 100%;
  .d-flex {
    @media (max-width: 768px) {
      display: block !important;
    }
  }
  .chiffre-item {
    text-transform: uppercase;
    @media (max-width: 768px) {
      width: 50%;
      float: left;
    }
    @media (max-width: 480px) {
      width: 100%;
      margin-bottom: 30px;
    }
    .chiffre{
      font-size: 48px;
      color: @primary-chiffre;
      font-weight: 600;
      white-space: nowrap;
      line-height: 38px;
      display: inline-block;
      @media (max-width: 1200px) {
        font-size: 25px;
      }
      @media (max-width: 480px) {
        font-size: 48px;
      }
    }
    .titre-chiffre{
      font-size: 18px;
      font-weight: 100;
      color: @primary-chiffre;
      @media (max-width: 1200px) {
        font-size: 13px;
      }
      @media (max-width: 480px) {
        font-size: 18px;
      }
    }
  }
  &.classic-a{
    background: @classic-bg;
    &.white{
       background-color: @white;
    }
    .chiffre{
      color: @primary-chiffre;
    }
    .titre-chiffre{color: @primary-chiffre}
  }
  &.classic-b{
    background: @classic-bg;
    &.white{
      background-color: @white;
    }
    .chiffre{
      color: @secondary-chiffre;
    }
    .titre-chiffre{color: @primary-chiffre}
  }
  &.primary-a{
    background: @primary-chiffre;
    .chiffre{
      color: @white;
    }
    .titre-chiffre{color: @white;}
  }
  &.primary-b{
    background: @primary-chiffre;
    .chiffre{
      color: @secondary-chiffre;
    }
    .titre-chiffre{color: @white;}
  }
  &.primary-a{
    background: @primary-chiffre;
    .chiffre{
      color: @white;
    }
    .titre-chiffre{color: @white;}
  }
  &.primary-b{
    background: @primary-chiffre;
    .chiffre{
      color: @secondary-chiffre;
    }
    .titre-chiffre{color: @white;}
  }
  &.secondary-a{
    background: @secondary-chiffre;
    .chiffre{
      color: @white;
    }
    .titre-chiffre{color: @white;}
  }
  &.secondary-b{
    background: @secondary-chiffre;
    .chiffre{
      color: @primary-chiffre;
    }
    .titre-chiffre{color: @white;}
  }
  &.tertiary-a, &.tertiary-b, &.tertiary-c{
    padding: 39px 0;
    .container{
      @media (min-width: 1374px){
        padding: 0 5px;
      }
    }
    .bloc-title{
      width: 100%;
      .title-h2{
        font-size: 40px;
        font-weight: 800;
        line-height: 46px;
        margin-bottom: 40px;
        position: relative;
        &::before{
          content: '';
          position: relative;
          display: block;
          height: 4px;
          width: 30px;
          margin: 15px auto;
          /*transform: translateX(-50%);*/
        }
      }
      .desc{
        font-size: 18px;
        font-weight: 400;
        letter-spacing: 0.27px;
        line-height: 28px;
        margin-bottom: 40px;
      }
    }
    .bloc-desc{
      width: 100%;
      .title{
        font-size: 40px;
        font-weight: 600;
        line-height: 40px;
        margin-bottom: 17px;
        text-transform: uppercase;
      }
      .desc{
        font-size: 18px;
        font-weight: 400;
        line-height: 21px;
        margin-bottom: 46px;
        text-transform: uppercase;
        strong{
          font-weight: 600;
        }
      }
      >.desc{
        font-weight: 400;
        letter-spacing: 0.25px;
        line-height: 28px;
        margin-bottom: 7px;
        text-transform: none;
        width: 100%;
        strong{
          font-weight: 700;
        }
      }
    }
    .chiffre{
      color: @secondary-chiffre;
    }
    .titre-chiffre{color: @white;}
    .d-flex.text-center{
      flex-wrap: wrap;
    }
  }
  &.tertiary-a{
    background: @primary-chiffre;
    color: @white;
    .bloc-title{
      .title-h2{
        &::before{
          background-color: @secondary-chiffre;
        }
      }
    }
    &.white{
      background: @white;
      color: @black;
      .bloc-title{
        .title-h2{
          &::before{
            background-color: @secondary-chiffre;
          }
        }
      }
      .bloc-desc{
        .title{
          color: @secondary-chiffre;
        }
        .desc{
          color: @primary-chiffre;
        }
        >.desc{
          color: @black;
        }
      }
    }
  }
  &.tertiary-b{
    background: @secondary-chiffre;
    color: @white;
    .bloc-title{
      .title-h2{
        &::before{
          background-color: @white;
        }
      }
    }
  }
  &.tertiary-c{
    background: @classic-bg;
    color: @black;
    .bloc-title{
      .title-h2{
        &::before{
          background-color: @secondary-chiffre;
        }
      }
    }
    .bloc-desc{
      .title{
        color: @secondary-chiffre;
      }
      .desc{
        color: @primary-chiffre;
      }
      >.desc{
        color: @black;
      }
    }
  }
}