/*login wp-admin*/
.login header {
    background: none repeat scroll 0 0 #fff;
}
.login .container {
    margin: 0 auto;
    padding: 10px 0;
    display: flex;
}
.login .align_center {
    text-align: center !important;
}
.login .sub-header {
  background: none repeat scroll 0 0 #535e7d;
  border: medium none;
  padding: 5px;
}
.div-shadow p {
    font-size: 2.1em;
}
.sub-header.align_center img {
    padding-top: 5px;
}
#login {
    padding: 3% 0 0 !important;
}

@media (max-width: 1050px) {
  .login .container {
    display: block;
    text-align: -webkit-center;
  }
  .login .container img {
      margin: 15px 0;
  }
}
/* finlogin wp-admin*/