.coockies_wrapper {
      position: fixed;
      width: 100%;
      z-index: 9999999;
      bottom: 0;
      color: #fff;
      padding: 0;
      padding-right: 0!important;
      display: block;
      min-height: 50px;
      .container {
        position: relative;
        padding: 0 30px 0 15px;
        background: #f7a800;
        min-height: 50px;
      }
      p {
        font-size: 14px;
        line-height: 17px;
        margin-bottom: 15px;
        margin-top: 15px;
        color: #ffffff;
        font-weight: 100;
        padding-right: 45px;
        padding-left: 25px;
      }
      a{
        font-size: 12px;
      }
      .coockies_btn.cookiebar-close {
          display: inline-block;
          float: right;
          position: absolute;
          right: 15px;
          top: 5px;
          img {
              width: 80%;
          }
      }
      .cookiebar-close{
        display: none;
      }

  }
  @media print {
    .coockies_wrapper {
      display: none;
    }
  }