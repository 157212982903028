.bloc-domaine-application {
  color: @black-dmappli;
  padding: 60px 0px;
  padding-bottom: 0;
  position: relative;
  > .container {
    padding-left: 95px;
    padding-right: 95px;
    @media (max-width: 992px) {
      padding-left: 0;
      padding-right: 0;
    }
  }
  &.less-padding {
    padding: 40px 0px;
    padding-bottom: 0;
  }
  &.no-background {
    // margin-bottom: 60px; => crée un espace sur https://odity.website/refonte-site-ayn/online-proprietary-panel-software.html?lang=en sur application area
  }
  .bloc-title {
    text-align: center;
    position: relative;
    float: left;
    z-index: 2;
    width: 100%;
    .desc,
    .desc p {
      font-size: 20px;
    }
    .title {
      font-weight: 700;
    }
    .title-h2 {
      &::before {
        content: "";
        background-color: @secondary-dmappli;
        position: absolute;
        display: block;
        height: 4px;
        width: 30px;
        top: 0px;
        margin: 0 auto;
        margin-bottom: 5px;
        left: 50%;
        top: -20px;
        transform: translateX(-50%);
      }
    }
    &.in-left {
      text-align: left;
      width: 33%;
      padding-right: 5%;
      padding-left: 34px;
      @media (max-width: 992px) {
        width: 100%;
        text-align: center;
      }
      .title-h2 {
        line-height: 46px;
        position: relative;
        font-weight: 800;
        display: inline-block;
        &::before {
          content: "";
          background-color: @white;
          position: absolute;
          width: 5px;
          height: 100%;
          top: 0px;
          left: -31px;
        }
      }
      .desc,
      .desc p {
        font-size: 14px;
        margin-top: 43px;
      }
      &::before {
        display: none;
      }
    }
  }
  .bloc-desc {
    float: left;
    width: 100%;
    @media (min-width: 768px) {
      padding-top: 195px;
    }
    .title {
      letter-spacing: 1px;
      font-weight: 700;
      display: inline-block;
      position: relative;
      .play-icon {
        position: absolute;
        margin: 0 auto;
        font-size: 48px;
        font-weight: 100;
        display: block;
        left: 37%;
        top: -65px;
      }
    }
    &.in-right {
      padding-top: 0px;
      padding-left: 15px;
      width: 67%;
      .desc {
        padding-right: 5%;
      }
      @media (max-width: 768px) {
        width: 100%;
        text-align: center;
      }
    }
    > div {
      &.row > div {
        margin-bottom: 35px;
        &.center-align {
          padding: 0 10%;
          @media (max-width: 767px) {
            padding-top: 195px;
          }
        }
        &.left-align {
          @media (max-width: 767px) {
            padding-top: 65px;
          }
        }
      }
      &.owl-carousel {
        margin-bottom: 12px;
      }
    }
    &.with-carousel {
      > div {
        &.row > div {
          &.center-align {
            padding: 0;
            > .desc {
              padding: 0 10%;
              &:last-child {
                margin-bottom: 16px;
              }
            }
          }
        }
      }
    }
  }
  .desc,
  .desc p {
    font-weight: 400;
    margin-top: 15px;
    margin-bottom: 30px;
    line-height: 28px;
    letter-spacing: 1px;
    position: relative;
    z-index: 2;
  }
  .title {
    .star-icon {
      position: absolute;
      top: 0px;
      right: -60px;
      font-size: 45px;
      @media (max-width: 992px) {
        /*right: 0px;*/
      }
    }
    .click-icon {
      position: absolute;
      top: 0px;
      left: -60px;
      font-size: 45px;
      @media (max-width: 992px) {
        /*left: 0px;*/
      }
    }
  }
  .btn.btn-decouvrir {
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 600;
    border-radius: 5px;
    padding: 7px;
    min-width: 189px;
    color: @white;
    background-color: transparent;
    border: 1px solid @white;
    position: relative;
    z-index: 2;
    &:hover {
      color: @primary-dmappli;
      background-color: @white;
    }
  }
  .btn.btn-ensavoirplus {
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 600;
    border-radius: 5px;
    padding: 7px;
    min-width: 189px;
    color: @white;
    background-color: transparent;
    border: 1px solid @white;
    position: relative;
    z-index: 2;
    &:hover {
      color: @primary-dmappli;
      background-color: @white;
    }
  }
  &.primary {
    background-color: @primary-dmappli;
    color: @white;
  }
  &.secondary {
    background-color: @secondary-dmappli;
    color: @white;
    .bloc-title,
    .title-h2 {
      &::before {
        background-color: @white;
      }
    }
    .btn-decouvrir,
    .btn-ensavoirplus {
      background-color: transparent;
      &:hover {
        color: @secondary-dmappli;
        background-color: @white;
        border: 1px solid @white;
      }
    }
  }
  &.tertiary {
    background-color: @grey-dmappli;
    color: @black-dmappli;
    padding-bottom: 30px;
    ul {
      li {
        &::before {
          color: @secondary-dmappli;
        }
      }
    }
    .bloc-title {
      &.in-left {
        .title-h2 {
          &::before {
            background-color: @secondary-dmappli;
          }
        }
      }
    }
    .btn-decouvrir,
    .btn-ensavoirplus {
      background-color: transparent;
      border-color: @primary-dmappli;
      color: @primary-dmappli;
      &:hover {
        color: @white;
        background-color: @primary-dmappli;
      }
    }
    .owl-theme {
      .owl-dots {
        .owl-dot {
          span {
            background: @primary-dmappli;
            opacity: 0.4;
          }
          &.active,
          &:hover {
            span {
              background-color: @primary-dmappli;
              opacity: 1;
            }
          }
        }
      }
    }
  }
  &.no-background {
    ul {
      li {
        &::before {
          color: @secondary-dmappli;
        }
      }
    }
    .bloc-title {
      &.in-left {
        .title-h2 {
          &::before {
            background-color: @secondary-dmappli;
          }
        }
      }
    }
    .btn-decouvrir,
    .btn-ensavoirplus {
      background-color: transparent;
      border-color: @primary-dmappli;
      color: @primary-dmappli;
      &:hover {
        color: @white;
        background-color: @primary-dmappli;
      }
    }
    .owl-theme {
      .owl-dots {
        .owl-dot {
          span {
            background: @primary-dmappli;
            opacity: 0.4;
          }
          &.active,
          &:hover {
            span {
              background-color: @primary-dmappli;
              opacity: 1;
            }
          }
        }
      }
    }
  }
  &.with-bg-img {
    position: relative;
    overflow: hidden;
    ul {
      li {
        &::before {
          color: @secondary-dmappli;
        }
      }
    }
    .bg-img {
      position: absolute;
      bottom: 0;
      right: 0;
      min-height: 100%;
      width: auto;
      display: block;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      @media (max-width: 992px) {
        width: auto;
        min-height: 100%;
        max-width: inherit;
      }
    }
    .btn-decouvrir,
    .btn-ensavoirplus {
      background-color: transparent;
      border-color: @primary-dmappli;
      color: @primary-dmappli;
      &:hover {
        color: @white;
        background-color: @primary-dmappli;
      }
    }
    .bloc-title {
      &.in-left {
        .title-h2 {
          &::before {
            background-color: @secondary-dmappli;
          }
        }
      }
    }
    .owl-theme {
      .owl-dots {
        .owl-dot {
          span {
            background: @primary-dmappli;
            opacity: 0.4;
          }
          &.active,
          &:hover {
            span {
              background-color: @primary-dmappli;
              opacity: 1;
            }
          }
        }
      }
    }
  }
  &.left-titled {
    .bloc-title {
      position: absolute;
      text-align: left;
      max-width: 330px;
      padding-left: 34px;
      @media (max-width: 992px) {
        position: relative;
        text-align: center;
      }
      .title-h2 {
        line-height: 46px;
        position: relative;
        &::before {
          content: "";
          background-color: @white;
          position: absolute;
          width: 5px;
          height: 100%;
          top: 0px;
          left: -31px;
        }
      }
      .desc,
      .desc p {
        font-size: 14px;
        margin-top: 43px;
      }
      &::before {
        display: none;
      }
    }
    .bloc-desc {
      padding-top: 248px;
      @media (max-width: 992px) {
        padding-top: 0px;
      }
      .title {
        .play-icon {
          top: -100px;
        }
      }
      .desc {
        margin-bottom: 30px;
        margin-top: 30px;
      }
    }
  }
  &.center-titled {
    .bloc-title {
      .desc {
        font-weight: 400;
        margin-top: 32px;
      }
    }
    .bloc-desc {
      .desc {
        margin-bottom: 7px;
        margin-top: 0px;
        a {
          color: @white;
          text-decoration: underline;
          &:hover {
            color: @secondary-dmappli;
          }
        }
      }
      .row {
        > div {
          margin-bottom: 37px;
        }
        .center-align {
          .btn {
            margin-bottom: 10px;
            margin-top: 28px;
          }
        }
        .owl-carousel {
          margin-bottom: 37px;
          margin-top: 34px;
          padding-left: 35px;
          padding-right: 35px;
          .item {
            text-align: left;
            .title {
              text-align: left;
            }
            .desc {
              margin-top: 12px;
              margin-bottom: 33px;
              text-align: left;
            }
            .btn {
              margin-top: 0px;
              margin-bottom: 0px;
            }
          }
        }
      }
      &.with-list {
        margin-top: 25px;
        .left-align {
          &:first-child {
            padding-right: 9%;
          }
        }
      }
    }
    &.secondary {
      .bloc-desc {
        .desc {
          a {
            color: @white;
            text-decoration: underline;
            &:hover {
              color: @primary-dmappli;
            }
          }
        }
      }
    }
    &.no-background,
    &.tertiary,
    &.with-bg-img {
      .bloc-desc {
        .desc {
          a {
            color: @black-dmappli;
            text-decoration: underline;
            &:hover {
              color: @secondary-dmappli;
            }
          }
        }
      }
    }
  }
  &.inverted {
    padding: 40px 0px;
    padding-bottom: 0;
    .container {
      .row {
        .col-sm-12 {
          padding: 0;
        }
      }
    }
    .bloc-desc {
      &.in-right {
        padding-left: 0px;
        padding-right: 6%;
      }
    }
  }
  &.no-video-img {
    .bloc-desc {
      padding-top: 0px;
      padding-left: 73px;
      padding-right: 73px;
    }
  }
  .img-link {
    margin-bottom: 15px;
  }
  .item {
    padding-left: 20px;
    padding-right: 20px;
  }
  .video-img {
    position: absolute;
    bottom: -35px;
    left: 0;
    right: 0;
    max-width: 100%;
    z-index: 0;
    margin: 0 auto;
  }
  .icon-block {
    margin-bottom: 20px;
    min-height: 50px;
    span {
      font-size: 50px;
      max-height: 50px;
    }
  }
  ul {
    list-style: none;
    margin: 0;
    margin-top: 12px;
    margin-bottom: 37px;
    padding: 0;
    position: relative;
    li {
      font-size: 14px;
      font-weight: 400;
      letter-spacing: 1px;
      line-height: 28px;
      padding-left: 30px;
      display: block;
      position: relative;
      strong {
        font-weight: 600;
      }
      &::before {
        content: "\ea10";
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        line-height: 28px;
        font-family: "icomoon" !important;
        speak: none;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
      }
    }
  }
  @media (max-width: 768px) {
    .right-align,
    .left-align {
      text-align: center;
    }
  }
}
