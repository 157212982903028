/* Open Sans @font-face kit */

@OpenSansPath: "../fonts/open_sans/";

/* BEGIN Light */
@font-face {
  font-family: 'Open Sans';
  font-display: swap;
  src: url('@{OpenSansPath}/Light/OpenSans-Light.eot');
  src: url('@{OpenSansPath}/Light/OpenSans-Light.eot?#iefix') format('embedded-opentype'),
       url('@{OpenSansPath}/Light/OpenSans-Light.woff') format('woff'),
       url('@{OpenSansPath}/Light/OpenSans-Light.ttf') format('truetype'),
       url('@{OpenSansPath}/Light/OpenSans-Light.svg#OpenSansLight') format('svg');
  font-weight: 300;
  font-style: normal;
}
/* END Light */

/* BEGIN Light Italic */
@font-face {
  font-family: 'Open Sans';
  font-display: swap;
  src: url('@{OpenSansPath}/LightItalic/OpenSans-LightItalic.eot');
  src: url('@{OpenSansPath}/LightItalic/OpenSans-LightItalic.eot?#iefix') format('embedded-opentype'),
       url('@{OpenSansPath}/LightItalic/OpenSans-LightItalic.woff') format('woff'),
       url('@{OpenSansPath}/LightItalic/OpenSans-LightItalic.ttf') format('truetype'),
       url('@{OpenSansPath}/LightItalic/OpenSans-LightItalic.svg#OpenSansLightItalic') format('svg');
  font-weight: 300;
  font-style: italic;
}
/* END Light Italic */

/* BEGIN Regular */
@font-face {
  font-family: 'Open Sans';
  font-display: swap;
  src: url('@{OpenSansPath}/Regular/OpenSans-Regular.eot');
  src: url('@{OpenSansPath}/Regular/OpenSans-Regular.eot?#iefix') format('embedded-opentype'),
       url('@{OpenSansPath}/Regular/OpenSans-Regular.woff') format('woff'),
       url('@{OpenSansPath}/Regular/OpenSans-Regular.ttf') format('truetype'),
       url('@{OpenSansPath}/Regular/OpenSans-Regular.svg#OpenSansRegular') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* END Regular */

/* BEGIN Italic */
@font-face {
  font-family: 'Open Sans';
  font-display: swap;
  src: url('@{OpenSansPath}/Italic/OpenSans-Italic.eot');
  src: url('@{OpenSansPath}/Italic/OpenSans-Italic.eot?#iefix') format('embedded-opentype'),
       url('@{OpenSansPath}/Italic/OpenSans-Italic.woff') format('woff'),
       url('@{OpenSansPath}/Italic/OpenSans-Italic.ttf') format('truetype'),
       url('@{OpenSansPath}/Italic/OpenSans-Italic.svg#OpenSansItalic') format('svg');
  font-weight: normal;
  font-style: italic;
}
/* END Italic */

/* BEGIN Semibold */
@font-face {
  font-family: 'Open Sans';
  font-display: swap;
  src: url('@{OpenSansPath}/Semibold/OpenSans-Semibold.eot');
  src: url('@{OpenSansPath}/Semibold/OpenSans-Semibold.eot?#iefix') format('embedded-opentype'),
       url('@{OpenSansPath}/Semibold/OpenSans-Semibold.woff') format('woff'),
       url('@{OpenSansPath}/Semibold/OpenSans-Semibold.ttf') format('truetype'),
       url('@{OpenSansPath}/Semibold/OpenSans-Semibold.svg#OpenSansSemibold') format('svg');
  font-weight: 600;
  font-style: normal;
}
/* END Semibold */

/* BEGIN Semibold Italic */
@font-face {
  font-family: 'Open Sans';
  font-display: swap;
  src: url('@{OpenSansPath}/SemiboldItalic/OpenSans-SemiboldItalic.eot');
  src: url('@{OpenSansPath}/SemiboldItalic/OpenSans-SemiboldItalic.eot?#iefix') format('embedded-opentype'),
       url('@{OpenSansPath}/SemiboldItalic/OpenSans-SemiboldItalic.woff') format('woff'),
       url('@{OpenSansPath}/SemiboldItalic/OpenSans-SemiboldItalic.ttf') format('truetype'),
       url('@{OpenSansPath}/SemiboldItalic/OpenSans-SemiboldItalic.svg#OpenSansSemiboldItalic') format('svg');
  font-weight: 600;
  font-style: italic;
}
/* END Semibold Italic */

/* BEGIN Bold */
@font-face {
  font-family: 'Open Sans';
  font-display: swap;
  src: url('@{OpenSansPath}/Bold/OpenSans-Bold.eot');
  src: url('@{OpenSansPath}/Bold/OpenSans-Bold.eot?#iefix') format('embedded-opentype'),
       url('@{OpenSansPath}/Bold/OpenSans-Bold.woff') format('woff'),
       url('@{OpenSansPath}/Bold/OpenSans-Bold.ttf') format('truetype'),
       url('@{OpenSansPath}/Bold/OpenSans-Bold.svg#OpenSansBold') format('svg');
  font-weight: bold;
  font-style: normal;
}
/* END Bold */

/* BEGIN Bold Italic */
@font-face {
  font-family: 'Open Sans';
  font-display: swap;
  src: url('@{OpenSansPath}/BoldItalic/OpenSans-BoldItalic.eot');
  src: url('@{OpenSansPath}/BoldItalic/OpenSans-BoldItalic.eot?#iefix') format('embedded-opentype'),
       url('@{OpenSansPath}/BoldItalic/OpenSans-BoldItalic.woff') format('woff'),
       url('@{OpenSansPath}/BoldItalic/OpenSans-BoldItalic.ttf') format('truetype'),
       url('@{OpenSansPath}/BoldItalic/OpenSans-BoldItalic.svg#OpenSansBoldItalic') format('svg');
  font-weight: bold;
  font-style: italic;
}
/* END Bold Italic */

/* BEGIN Extrabold */
@font-face {
  font-family: 'Open Sans';
  font-display: swap;
  src: url('@{OpenSansPath}/ExtraBold/OpenSans-ExtraBold.eot');
  src: url('@{OpenSansPath}/ExtraBold/OpenSans-ExtraBold.eot?#iefix') format('embedded-opentype'),
       url('@{OpenSansPath}/ExtraBold/OpenSans-ExtraBold.woff') format('woff'),
       url('@{OpenSansPath}/ExtraBold/OpenSans-ExtraBold.ttf') format('truetype'),
       url('@{OpenSansPath}/ExtraBold/OpenSans-ExtraBold.svg#OpenSansExtrabold') format('svg');
  font-weight: 800;
  font-style: normal;
}
/* END Extrabold */

/* BEGIN Extrabold Italic */
@font-face {
  font-family: 'Open Sans';
  font-display: swap;
  src: url('@{OpenSansPath}/ExtraBoldItalic/OpenSans-ExtraBoldItalic.eot');
  src: url('@{OpenSansPath}/ExtraBoldItalic/OpenSans-ExtraBoldItalic.eot?#iefix') format('embedded-opentype'),
       url('@{OpenSansPath}/ExtraBoldItalic/OpenSans-ExtraBoldItalic.woff') format('woff'),
       url('@{OpenSansPath}/ExtraBoldItalic/OpenSans-ExtraBoldItalic.ttf') format('truetype'),
       url('@{OpenSansPath}/ExtraBoldItalic/OpenSans-ExtraBoldItalic.svg#OpenSansExtraboldItalic') format('svg');
  font-weight: 800;
  font-style: italic;
}
/* END Extrabold Italic */
