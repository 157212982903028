@font-face {
  font-family: "icomoon";
  font-display: swap;
  src: url("https://www.areyounet.com/wp-content/themes/ayn/assets/fonts/icons/icomoon/icomoon.ttf?ri1j2f");
  src: url("https://www.areyounet.com/wp-content/themes/ayn/assets/fonts/icons/icomoon/icomoon.eot?ri1j2f#iefix")
      format("embedded-opentype"),
    url("https://www.areyounet.com/wp-content/themes/ayn/assets/fonts/icons/icomoon/icomoon.ttf?ri1j2f")
      format("truetype"),
    url("https://www.areyounet.com/wp-content/themes/ayn/assets/fonts/icons/icomoon/icomoon.woff?ri1j2f")
      format("woff"),
    url("https://www.areyounet.com/wp-content/themes/ayn/assets/fonts/icons/icomoon/icomoon.svg?ri1j2f#icomoon")
      format("svg");
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-viadeo:before {
  content: "\eaeb";
}

.icon-phone:before {
  content: "\e942";
}
.icon-envelop:before {
  content: "\e945";
}

.icon-location:before {
  content: "\e947";
}
.icon-home:before {
  content: "\e900";
}
.icon-printer:before {
  content: "\e954";
}
.icon-user:before {
  content: "\e971";
}
.icon-cross:before {
  content: "\ea0f";
}
.icon-search:before {
  content: "\e986";
}
.icon-road:before {
  content: "\e9b1";
}
.icon-facebook:before {
  content: "\ea90";
}
.icon-twitter:before {
  content: "\ea96";
}
.icon-linkedin2:before {
  content: "\eaca";
}
