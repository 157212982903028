.espace-recherche {
    background: #eeeeee;
    display: flex;
    width: 100%;
    height: 67px;
    padding-right: 15px;
    margin: 0 15px;
}
button.search-submit {
    background: #53607f;
    border: none;
    padding: 12px 49px;
    margin-left: -3px;
    margin-top: 11px;
    margin-bottom: 10px;
}
span.screen-reader-text {
    color: #ffffff;
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 2px;
}
input.search-field {
    margin-top: 11px;
    margin-left: 10px;
    width: 84%;
    height: 46px;
    border: none;
    padding-left: 54px;
    border-left: 5px solid #53607f;
    position: relative;
}
img.img-recherche {
    position: absolute;
    margin-top: 14px;
    margin-left: 25px;
}
.nombre-resultat {
    float: left;
    display: block;
    width: 100%;
    border-bottom: 1px solid #eeeeee;
    color: #222222;
    font-size: 20px;
    font-weight: 300;
    padding-bottom: 10px;
    margin-top: 40px;
    margin-bottom: 30px;
}
.liste-resultat {
    display: block;
    float: left;
    width: 100%;
    padding-left: 40px;
    padding-top: 10px;
    a{
      text-decoration: none !important;  
    }
    span.title-recherche {
        color: #f7a800;
        font-size: 14px;
        font-weight: 600;
        text-decoration: underline;
    }
    span{
        color: #727272;
        font-size: 14px;
        font-weight: 600;
    }
    &:hover {
        background: #fbfbfb;
        cursor: pointer;
        span{
            color:#f7a800; 
        }
        p.parag-recherche {
            text-decoration: none !important;
        }
    }
}

p.parag-recherche {
    color: #727272;
    font-size: 14px;
    font-weight: 300;
    margin-bottom: 15px !important;
    text-decoration: none !important;
}
.bloc {
    width: 100%;
}
.pagination-recherche {
    width: 100%;
    text-align: center;
    border-top: 1px solid #eeeeee;
    display: inline-block;
        padding-top: 30px;
    padding-bottom: 30px;
    a {
        color: #888888;
        font-size: 14px;
        font-weight: bold;
        padding: 0 20px;
        text-transform: uppercase;
        text-decoration: none !important;
        &:hover{
            color:#f7a800;
        }
        &.prev.page-numbers {
            float: left;
        }
        &.next.page-numbers {
            float: right;
        }
    }
    span {
        color: #ffffff;
        font-size: 14px;
        font-weight: bold;
        padding: 10px 17px;
        background: #f7a800;
    }
}

