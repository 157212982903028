.btn{
	background: transparent;
	font-size: 14px;
	font-family: 'Open Sans', Arial;
	font-weight: 600;
	border: 1px solid @white;
	color: @white;
    cursor: pointer;
    padding-left: 20px;
    padding-right: 20px;
    min-width: 189px;
    text-align: center;
    &:focus, &.focus{
	  outline: 0;
	  box-shadow: none;
	}
	&.call{
		font-size: 18px;
		position: relative;
		padding-left: 45px;
		text-align: right;
		&:after{
			content: "\e182";
			font-size: 18px;
			position: absolute;
		    left: 10px;
		    top: 8px;
			font-family: 'Glyphicons Halflings';
		}
	}
	&.btn-first{
		border: 1px solid @white;
		color: @white;
		&:hover{
			background: @primary;
			border: 1px solid @primary;
		}
	}
	&.btn-second{
		border: 1px solid @white;
		color: @white;
		&:hover{
			background: @secondary;
			border: 1px solid @secondary;
		}
	}
    &.btn-decouvrir{
		text-transform: uppercase;
		font-size: 14px;
		font-weight: 600;
		border-radius: 5px;
		padding: 7px;
	    min-width: 189px;
	    color: @white;
		background-color: @primary;
		border: 1px solid @white;
	    letter-spacing: 1px;
	    outline: 0;
		&:hover{
	  		color: @primary;
	  		background-color: @white;
	  		border-color: @primary;
		}
    }
	&.white{
		background-color: white;
		color: @primary;
		border-color: @primary;
		&:hover{
			background: @primary;
			color: white;
		}
	}
    &.btn-ensavoirplus{
	text-transform: uppercase;
	font-size: 14px;
	font-weight: 600;
	border-radius: 5px;
	padding: 7px;
	min-width: 189px;
	color: @white;
	background-color: @primary;
	letter-spacing: 1px;
	border: 1px solid @white;
	outline: 0;
	&:hover{
	  color: @primary;
	  background-color: @white;
	}
  }
}