.bloc-message-notif{
  position: fixed;
  right: 0;
  bottom: 155px;
  z-index: 9999;
  padding: 0;
  margin: 0;
  pointer-events: none;
  @media (max-width: 1450px){
    -webkit-transform: scale(0.8);
    -moz-transform: scale(0.8);
    -ms-transform: scale(0.8);
    -o-transform: scale(0.8);
    transform: scale(0.8);
    -webkit-transform-origin: 100% 100%;
    -ms-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
  }
  &.show-notif-box{
    pointer-events: initial;
    .content{
      -webkit-transition: 1s all 1s cubic-bezier(0.61, 0.02, 0, 1.07);
      -moz-transition: 1s all 1s cubic-bezier(0.61, 0.02, 0, 1.07);
      -ms-transition: 1s all 1s cubic-bezier(0.61, 0.02, 0, 1.07);
      -o-transition: 1s all 1s cubic-bezier(0.61, 0.02, 0, 1.07);
      left: 0;
      .cd-img{
        left: 0px;
        -webkit-transition: 0.6s all 1.3s cubic-bezier(0.61, 0.02, 0, 1.07);
        -moz-transition: 0.6s all 1.3s cubic-bezier(0.61, 0.02, 0, 1.07);
        -ms-transition: 0.6s all 1.3s cubic-bezier(0.61, 0.02, 0, 1.07);
        -o-transition: 0.6s all 1.3s cubic-bezier(0.61, 0.02, 0, 1.07);
      }
      .cd-words{
        left: 0px;
        -webkit-transition: all 1s cubic-bezier(0.61, 0.02, 0, 1.07);
        -moz-transition: all 1s cubic-bezier(0.61, 0.02, 0, 1.07);
        -ms-transition: all 1s cubic-bezier(0.61, 0.02, 0, 1.07);
        -o-transition: all 1s cubic-bezier(0.61, 0.02, 0, 1.07);
      }
    }
  }
  @media (max-width: 600px){
    bottom: 125px;
  }
  .content{
    align-items: center;
    background: transparent;
    color: white;
    /*max-width: 420px;*/
    left: 110%;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    padding: 5px 15px 5px 5px;
    -webkit-transition: all 1s cubic-bezier(0.61, 0.02, 0, 1.07);
    -moz-transition: all 1s cubic-bezier(0.61, 0.02, 0, 1.07);
    -ms-transition: all 1s cubic-bezier(0.61, 0.02, 0, 1.07);
    -o-transition: all 1s cubic-bezier(0.61, 0.02, 0, 1.07);
    @media (max-width: 1450px){
      padding-right: 10px;
    }
    @media (max-width: 430px){
      max-width: 100%;
      padding-right: 25px;
    }
    @media (max-width: 400px){
      padding-right: 15px;
    }
    @media (max-width: 357px){
      padding-right: 10px;
    }
    .cd-close{
      position: absolute;
      top: 11px;
      right: 9px;
      font-size: 13px;
      color: #80d7f8;
      cursor: pointer;
      display: none;
    }
    .cd-img{
      background-color: @red-alert;
      cursor: pointer;
      left: 200px;
      -webkit-transition: 1s all 1.5s cubic-bezier(0.61, 0.02, 0, 1.07);
      -moz-transition: 1s all 1.5s cubic-bezier(0.61, 0.02, 0, 1.07);
      -ms-transition: 1s all 1.5s cubic-bezier(0.61, 0.02, 0, 1.07);
      -o-transition: 1s all 1.5s cubic-bezier(0.61, 0.02, 0, 1.07);
      .drop-shadow(0px,0px,9px,0.5);
      border-radius: 50%;
      color: @white;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 16px;
      height: 66px;
      width: 66px;
      position: relative;
      @media (max-width: 400px){
        padding: 10px 14px;
      }
      @media (max-width: 367px){
        padding: 10px 9px;
      }
      @media (max-width: 327px){
        padding: 10px 3px;
      }
      img{
        @media (max-width: 327px){
          width: 5px;
        }
      }
      .icon{
        font-size: 30px;
        display: block;
        top: 2px;
        position: relative;
      }
      &::before{
        content: '';
        border: 1px solid @white;
        border-radius: 50%;
        display: block;
        height: 50px;
        width: 50px;
        position: absolute;
      }
    }
    .cd-words{
      background-color: @dark-black;
      border-radius: 8px;
      cursor: pointer;
      display: flex;
      padding: 18px 8px;
      vertical-align: middle;
      position: relative;
      left: 500px;
      -webkit-transition: all 1s cubic-bezier(0.61, 0.02, 0, 1.07);
      -moz-transition: all 1s cubic-bezier(0.61, 0.02, 0, 1.07);
      -ms-transition: all 1s cubic-bezier(0.61, 0.02, 0, 1.07);
      -o-transition: all 1s cubic-bezier(0.61, 0.02, 0, 1.07);
      .drop-shadow(0px,0px,9px,0.5);
      &::after{
        content: "";
        display: block;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 13px 0 13px 12px;
        border-color: transparent transparent transparent #000000;
        position: absolute;
        left: initial;
        right: -12px;
        top: 12px;
        bottom: initial;
      }
      @media (max-width: 400px){
        padding-left: 8px;
      }
      @media (max-width: 357px){
        padding-left: 3px;
      }
      .cd-alert{
        color: @white;
        text-transform: uppercase;
        font-size: 13px;
        font-weight: 600;
        @media (max-width: 357px){
          font-size: 12px;
        }
      }
      .cd-txt{
        color: @white;
        font-size: 13px;
        font-weight: 400;
        line-height: 17px;
        @media (max-width: 357px){
          font-size: 12px;
        }
      }
    }
  }
}
@media print {
    .bloc-message-notif {
      display: none;
    }
  }