.bloc-inscription {
  padding: 36px 0 30px 0px;
  float: left;
  width: 100%;
  margin-top: 0px;
  margin-bottom: 0px;
  .container {
    @media (min-width: 1374px) {
      padding: 0 12px;
    }
  }
  .paragraphe-container {
    flex-basis: 79%;
    @media (max-width: 1366px) {
      flex-basis: 60%;
    }
    @media (max-width: 570px) {
      flex-basis: 100%;
    }
  }
  .btn-container {
    flex-basis: 20%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    a.btn {
      margin: 8px 0;
    }
    @media (max-width: 1366px) {
      flex-basis: 40%;
    }
    @media (max-width: 570px) {
      flex-basis: 100%;
      text-align: center !important;
    }
  }
  .paragraphe {
    color: @white;
    text-transform: uppercase;
    font-size: 27px;
    font-weight: 600;
    line-height: 30px;
    @media (max-width: 992px) {
      font-size: 17px;
      line-height: 24px;
      margin-bottom: 30px;
    }
  }
  .btn {
    font-weight: 700;
    padding: 6.5px 19.5px;
    text-transform: uppercase;
    min-width: 210px;
    &:not(:last-child) {
      margin-bottom: 14px;
    }
    &.btn-decouvrir {
      text-transform: uppercase;
      font-size: 14px;
      font-weight: 600;
      border-radius: 5px;
      padding: 7px;
      min-width: 189px;
      border-color: @white;
      background-color: transparent;
      padding-bottom: 10px;
      @media (max-width: 600px) {
        margin-top: 30px;
      }
      &.second-color {
        color: @white;
        font-size: 14px;
        font-weight: 400;
        padding-left: 20px;
        padding-right: 20px;
        text-decoration: underline;
        text-transform: uppercase;
        span {
          display: inline-block;
          font-weight: 700;
          text-decoration: none;
        }
        &:hover {
          background-color: @secondary-inscription;
          border-color: @secondary-inscription;
          color: @white;
        }
      }
      &.first-color {
        color: @white;
        font-size: 14px;
        font-weight: 400;
        padding-left: 20px;
        padding-right: 20px;
        text-decoration: underline;
        text-transform: uppercase;
        span {
          display: inline-block;
          font-weight: 700;
          text-decoration: none;
        }
        &:hover {
          background-color: @primary-inscription;
          border-color: @primary-inscription;
          color: @white;
        }
      }
    }
    &.call {
      line-height: 21px;
      padding-left: 44px;
      @media (max-width: 600px) {
        margin-bottom: 14px;
      }
    }
  }
  &.centered {
    text-align: center;
  }
  &.primary {
    background: @primary-inscription;
  }
  &.secondary {
    background: @secondary-inscription;
  }
  .type-1,
  .type-2 {
    align-items: center;
    padding-left: 10.9%;
    padding-right: 10.9%;
    @media (max-width: 1400px) {
      padding-left: 7.5%;
      padding-right: 7.5%;
    }
    @media (max-width: 768px) {
      padding-left: 10px;
      padding-right: 10px;
    }
    .d-flex {
      flex-wrap: wrap;
      justify-content: flex-end;
      padding-right: 9px;
      @media (max-width: 600px) {
        justify-content: space-evenly;
        margin-top: 30px;
      }
    }
  }
  .type-2 {
    .paragraphe {
      margin-bottom: 40px;
      margin-top: 15px;
      padding-left: 11%;
      padding-right: 11%;
    }
    .btn {
      &:not(:last-child) {
        margin-bottom: 0;
      }
      &.btn-first,
      &.btn-first.call,
      &.btn-second,
      &.btn-second.call {
        margin-bottom: 17px;
        &:not(:last-child) {
          margin-right: 18px;
        }
      }
    }
  }
  .type-3 {
    .paragraphe {
      margin-bottom: 31px;
      margin-top: 15px;
    }
    .btn {
      margin-bottom: 12px;
    }
  }
  .type-4 {
    align-items: center;
    padding-left: 11.5%;
    padding-right: 10.5%;
    .paragraphe {
      margin-bottom: 3px;
    }
    @media (max-width: 1400px) {
      padding-left: 7.5%;
      padding-right: 7.5%;
    }
    @media (max-width: 768px) {
      padding-left: 10px;
      padding-right: 10px;
    }
  }
}
