.bloc-entete {
  color: @black-entete;
  padding: 30px 0px;
  padding-bottom: 0;
  position: relative;
  margin-bottom: 30px;
  .bloc-title {
    text-align: center;
    position: relative;
    float: left;
    width: 100%;
    margin-bottom: 40px;
    .title {
      font-weight: 800;
    }
    .title-h2 {
      line-height: 46px;
      position: relative;
      &::before {
        content: "";
        background-color: @secondary-entete;
        position: absolute;
        display: block;
        height: 4px;
        width: 30px;
        top: 0px;
        margin: 0 auto;
        margin-bottom: 5px;
        left: 50%;
        top: -20px;
        transform: translateX(-50%);
      }
    }
    + *,
    + * > .desc,
    + * > .desc p {
      margin-top: 0;
    }
  }
  .bloc-texte {
    text-align: left;
    position: relative;
    float: left;
  }
  .desc,
  .desc p {
    color: @grey-entete;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 1px;
    line-height: 28px;
    margin-bottom: 12px;
    /* margin-top: 22px; */
    strong {
      font-weight: 600;
    }
    &.single {
      color: @black-entete;
      font-size: 20px;
    }
  }

  .desc {
    &.single {
      float: left;
      margin-top: 0;
      padding-left: 5.5%;
      padding-right: 5.5%;
      position: relative;
      margin-top: 20px;
    }
    + .desc {
      margin-top: 0;
    }
  }
  @media (max-width: 992px) {
    .right-align,
    .left-align {
      text-align: center;
    }
  }
  .image-center {
    text-align: center;
    img {
      margin-top: 30px;
      margin-bottom: 30px;
    }
  }
}
