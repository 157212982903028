.bloc-header-landing{
        .groupe-header{
            width: 100%;
            margin-bottom: 50px;
            padding: 15px;
            .title-header{
                @media screen and (max-width:650px){
                    width: 100%;
                    display: block;
                    text-align:center;
                }
                p{
                    font-size: 30px; 
                    font-weight: 800;
                    color:#545e7f;
                    .text-orange{
                        color:#f7a800;
                    }
                    @media screen and (max-width: 1280px){
                        font-size: 25px;
                    }
                    @media screen and (max-width: 992px){
                        font-size: 16px;
                    }
                }
               
            }
            .logo-header{
                @media screen and (max-width:1280px){
                    width: 15%;
                }
                @media screen and (max-width:650px){
                    width: 15%;
                    margin: 0 auto;
                    float: none !important;
                    display: block !important;
                    margin-bottom: 15px;
                }
                img{
                    max-width: 100%;
                }
            }
        }
        
            .title-label{
                color: #222;
                font-size: 18px;
                font-weight: 700;
                letter-spacing: 1px;
                line-height: 28px;
                text-transform: uppercase;
                padding-right: 20px;
            }
            .group-li{
                ul{
                    color: #484848;
                    list-style: none;
                    margin: 0;
                    padding: 0;
                    position: relative;
                    padding-right: 22%; 

                    li{
                        font-size: 14px;
                        font-weight: 400;
                        letter-spacing: 1px;
                        line-height: 28px;
                        padding-left: 30px;
                        display: block;
                        position: relative;
                        &::before{
                            content: "\ea10";
                            color: #f7a800;
                            display: block;
                            position: absolute;
                            left: 0;
                            top: 0;
                            line-height: 28px;
                            font-family: icomoon!important;
                            speak: none;
                            font-style: normal;
                            font-weight: 400;
                            font-variant: normal;
                            text-transform: none;
                            -webkit-font-smoothing: antialiased;
                            -moz-osx-font-smoothing: grayscale;
                        }
                    }
            }
        }

        .group-li+.title-label {
            margin-top: 40px;
        }
    
 
    .single{
        text-align: justify;
        padding-left: 15px;
        padding-right: 15px;
        font-size: 14px;
        margin: 23px 0;
    }

    .group-left-lp{
        height: 100%;
        position: relative;

        @media screen and (max-width:992px){
            height: auto;
        }
    }

    .img-leading{
         position:absolute;
         bottom:87px;
     
        @media screen and(max-width:1373px){
           bottom:85px
        }

        @media screen and (max-width:992px){
            position:initial;
        }

        img{
            max-width: 100%;
            padding-right: 20px;

            @media screen and (max-width:1200px){
                margin-top: 20px;
            }
            @media screen and (max-width:992px){
                margin-bottom: 20px;

            }
        }
    }
    .zone-texte{
        width: 100%;
        margin-bottom: 30px;
    }

}

.landing-questionnaire{
    .bloc-title .title-h2 {
    text-align:center;
        &::before{
            content: '';
            background-color: #f7a800;
            position: absolute;
            display: block;
            height: 4px;
            width: 30px;
            top: 0;
            margin: 0 auto;
            margin-bottom: 5px;
            left: 50% !important;
            top: -20px;
            -webkit-transform: translateX(-50%);
            -ms-transform: translateX(-50%);
            transform: translateX(-50%);
        }
    }
   .link-description{
       display: none !important;
   }
   .title{
       font-weight: 800 !important;
   }
   .img-link{
       img{
           max-height:65px !important;
       }
   }
}