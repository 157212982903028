.ui-helper-clearfix:before,
.ui-helper-clearfix:after {
    content: "";
    display: table;
    border-collapse: collapse
}

.ui-helper-clearfix:after {
    clear: both
}

.ui-helper-clearfix {
    min-height: 0
}

.ui-helper-zfix {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    opacity: 0;
    filter: Alpha(Opacity=0)
}

.ui-front {
    z-index: 100
}

.ui-state-disabled {
    cursor: default!important
}

.ui-icon {
    display: block;
    text-indent: -99999px;
    overflow: hidden;
    background-repeat: no-repeat
}

.ui-widget-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%
}

.ui-datepicker {
    width: 17em;
    padding: .2em .2em 0;
    display: none
}

.ui-datepicker .ui-datepicker-header {
    position: relative;
    padding: .2em 0
}

.ui-datepicker .ui-datepicker-prev,
.ui-datepicker .ui-datepicker-next {
    position: absolute;
    top: 2px;
    width: 1.8em;
    height: 1.8em
}

.ui-datepicker .ui-datepicker-prev-hover,
.ui-datepicker .ui-datepicker-next-hover {
    top: 1px
}

.ui-datepicker .ui-datepicker-prev {
    left: 2px
}

.ui-datepicker .ui-datepicker-next {
    right: 2px
}

.ui-datepicker .ui-datepicker-prev-hover {
    left: 1px
}

.ui-datepicker .ui-datepicker-next-hover {
    right: 1px
}

.ui-datepicker .ui-datepicker-prev span,
.ui-datepicker .ui-datepicker-next span {
    display: block;
    position: absolute;
    left: 50%;
    margin-left: -8px;
    top: 50%;
    margin-top: -8px
}

.ui-datepicker .ui-datepicker-title {
    margin: 0 2.3em;
    line-height: 1.8em;
    text-align: center
}

.ui-datepicker .ui-datepicker-title select {
    font-size: 1em;
    margin: 1px 0
}

.ui-datepicker select.ui-datepicker-month,
.ui-datepicker select.ui-datepicker-year {
    width: 45%
}

.ui-datepicker table {
    width: 100%;
    font-size: .9em;
    border-collapse: collapse;
    margin: 0 0 .4em
}

.ui-datepicker th {
    padding: .7em .3em;
    text-align: center;
    font-weight: bold;
    border: 0
}

.ui-datepicker td {
    border: 0;
    padding: 1px
}

.ui-datepicker td span,
.ui-datepicker td a {
    display: block;
    padding: .2em;
    text-align: right;
    text-decoration: none
}

.ui-datepicker .ui-datepicker-buttonpane {
    background-image: none;
    margin: .7em 0 0 0;
    padding: 0 .2em;
    border-left: 0;
    border-right: 0;
    border-bottom: 0
}

.ui-datepicker .ui-datepicker-buttonpane button {
    float: right;
    margin: .5em .2em .4em;
    cursor: pointer;
    padding: .2em .6em .3em .6em;
    width: auto;
    overflow: visible
}

.ui-datepicker .ui-datepicker-buttonpane button.ui-datepicker-current {
    float: left
}

.ui-datepicker.ui-datepicker-multi {
    width: auto
}

.ui-datepicker-multi .ui-datepicker-group {
    float: left
}

.ui-datepicker-multi .ui-datepicker-group table {
    width: 95%;
    margin: 0 auto .4em
}

.ui-datepicker-multi-2 .ui-datepicker-group {
    width: 50%
}

.ui-datepicker-multi-3 .ui-datepicker-group {
    width: 33.3%
}

.ui-datepicker-multi-4 .ui-datepicker-group {
    width: 25%
}

.ui-datepicker-multi .ui-datepicker-group-last .ui-datepicker-header,
.ui-datepicker-multi .ui-datepicker-group-middle .ui-datepicker-header {
    border-left-width: 0
}

.ui-datepicker-multi .ui-datepicker-buttonpane {
    clear: left
}

.ui-datepicker-row-break {
    clear: both;
    width: 100%;
    font-size: 0
}

.ui-datepicker-rtl {
    direction: rtl
}

.ui-datepicker-rtl .ui-datepicker-prev {
    right: 2px;
    left: auto
}

.ui-datepicker-rtl .ui-datepicker-next {
    left: 2px;
    right: auto
}

.ui-datepicker-rtl .ui-datepicker-prev:hover {
    right: 1px;
    left: auto
}

.ui-datepicker-rtl .ui-datepicker-next:hover {
    left: 1px;
    right: auto
}

.ui-datepicker-rtl .ui-datepicker-buttonpane {
    clear: right
}

.ui-datepicker-rtl .ui-datepicker-buttonpane button {
    float: left
}

.ui-datepicker-rtl .ui-datepicker-buttonpane button.ui-datepicker-current,
.ui-datepicker-rtl .ui-datepicker-group {
    float: right
}

.ui-datepicker-rtl .ui-datepicker-group-last .ui-datepicker-header,
.ui-datepicker-rtl .ui-datepicker-group-middle .ui-datepicker-header {
    border-right-width: 0;
    border-left-width: 1px
}

.ui-slider {
    position: relative;
    text-align: left
}

.ui-slider .ui-slider-handle {
    position: absolute;
    z-index: 2;
    width: 1.2em;
    height: 1.2em;
    cursor: default;
    -ms-touch-action: none;
    touch-action: none
}

.ui-slider .ui-slider-range {
    position: absolute;
    z-index: 1;
    font-size: .7em;
    display: block;
    border: 0;
    background-position: 0 0
}

.ui-slider.ui-state-disabled .ui-slider-handle,
.ui-slider.ui-state-disabled .ui-slider-range {
    filter: inherit
}

.ui-slider-horizontal {
    height: .8em
}

.ui-slider-horizontal .ui-slider-handle {
    top: -.3em;
    margin-left: -.6em
}

.ui-slider-horizontal .ui-slider-range {
    top: 0;
    height: 100%
}

.ui-slider-horizontal .ui-slider-range-min {
    left: 0
}

.ui-slider-horizontal .ui-slider-range-max {
    right: 0
}

.ui-slider-vertical {
    width: .8em;
    height: 100px
}

.ui-slider-vertical .ui-slider-handle {
    left: -.3em;
    margin-left: 0;
    margin-bottom: -.6em
}

.ui-slider-vertical .ui-slider-range {
    left: 0;
    width: 100%
}

.ui-slider-vertical .ui-slider-range-min {
    bottom: 0
}

.ui-slider-vertical .ui-slider-range-max {
    top: 0
}

.ui-sortable-handle {
    -ms-touch-action: none;
    touch-action: none
}

.ui-spinner {
    position: relative;
    display: inline-block;
    overflow: hidden;
    padding: 0;
    vertical-align: middle
}

.ui-spinner-input {
    border: none;
    background: none;
    color: inherit;
    padding: 0;
    margin: .2em 0;
    vertical-align: middle;
    margin-left: .4em;
    margin-right: 22px
}

.ui-spinner-button {
    width: 16px;
    height: 50%;
    font-size: .5em;
    padding: 0;
    margin: 0;
    text-align: center;
    position: absolute;
    cursor: default;
    display: block;
    overflow: hidden;
    right: 0
}

.ui-spinner a.ui-spinner-button {
    border-top: none;
    border-bottom: none;
    border-right: none
}

.ui-spinner .ui-icon {
    position: absolute;
    margin-top: -8px;
    top: 50%;
    left: 0
}

.ui-spinner-up {
    top: 0
}

.ui-spinner-down {
    bottom: 0
}

.ui-spinner .ui-icon-triangle-1-s {
    background-position: -65px -16px
}

.ui-widget {
    font-family: Trebuchet MS, Tahoma, Verdana, Arial, sans-serif;
    font-size: 1.1em
}

.ui-widget .ui-widget {
    font-size: 1em
}

.ui-widget input,
.ui-widget select,
.ui-widget textarea,
.ui-widget button {
    font-family: Trebuchet MS, Tahoma, Verdana, Arial, sans-serif;
    font-size: 1em
}

.ui-widget-content {
    border: 1px solid #ddd;
    background: #eee url("images/ui-bg_highlight-soft_100_eeeeee_1x100.png") 50% top repeat-x;
    color: #333
}

.ui-widget-content a {
    color: #333
}

.ui-widget-header {
    border: 1px solid #e78f08;
    background: #f6a828 url("images/ui-bg_gloss-wave_35_f6a828_500x100.png") 50% 50% repeat-x;
    color: #fff;
    font-weight: bold
}

.ui-widget-header a {
    color: #fff
}

.ui-state-default,
.ui-widget-content .ui-state-default,
.ui-widget-header .ui-state-default {
    border: 1px solid #ccc;
    background: #f6f6f6 url("images/ui-bg_glass_100_f6f6f6_1x400.png") 50% 50% repeat-x;
    font-weight: bold;
    color: #1c94c4
}

.ui-state-default a,
.ui-state-default a:link,
.ui-state-default a:visited {
    color: #1c94c4;
    text-decoration: none
}

.ui-state-hover,
.ui-widget-content .ui-state-hover,
.ui-widget-header .ui-state-hover,
.ui-state-focus,
.ui-widget-content .ui-state-focus,
.ui-widget-header .ui-state-focus {
    border: 1px solid #fbcb09;
    background: #fdf5ce url("images/ui-bg_glass_100_fdf5ce_1x400.png") 50% 50% repeat-x;
    font-weight: bold;
    color: #c77405
}

.ui-state-hover a,
.ui-state-hover a:hover,
.ui-state-hover a:link,
.ui-state-hover a:visited,
.ui-state-focus a,
.ui-state-focus a:hover,
.ui-state-focus a:link,
.ui-state-focus a:visited {
    color: #c77405;
    text-decoration: none
}

.ui-state-active,
.ui-widget-content .ui-state-active,
.ui-widget-header .ui-state-active {
    border: 1px solid #fbd850;
    background: #fff url("images/ui-bg_glass_65_ffffff_1x400.png") 50% 50% repeat-x;
    font-weight: bold;
    color: #eb8f00
}

.ui-state-active a,
.ui-state-active a:link,
.ui-state-active a:visited {
    color: #eb8f00;
    text-decoration: none
}

.ui-state-highlight,
.ui-widget-content .ui-state-highlight,
.ui-widget-header .ui-state-highlight {
    border: 1px solid #fed22f;
    background: #ffe45c url("images/ui-bg_highlight-soft_75_ffe45c_1x100.png") 50% top repeat-x;
    color: #363636
}

.ui-state-highlight a,
.ui-widget-content .ui-state-highlight a,
.ui-widget-header .ui-state-highlight a {
    color: #363636
}

.ui-state-error,
.ui-widget-content .ui-state-error,
.ui-widget-header .ui-state-error {
    border: 1px solid #cd0a0a;
    background: #b81900 url("images/ui-bg_diagonals-thick_18_b81900_40x40.png") 50% 50% repeat;
    color: #fff
}

.ui-state-error a,
.ui-widget-content .ui-state-error a,
.ui-widget-header .ui-state-error a {
    color: #fff
}

.ui-state-error-text,
.ui-widget-content .ui-state-error-text,
.ui-widget-header .ui-state-error-text {
    color: #fff
}

.ui-priority-primary,
.ui-widget-content .ui-priority-primary,
.ui-widget-header .ui-priority-primary {
    font-weight: bold
}

.ui-priority-secondary,
.ui-widget-content .ui-priority-secondary,
.ui-widget-header .ui-priority-secondary {
    opacity: .7;
    filter: Alpha(Opacity=70);
    font-weight: normal
}

.ui-state-disabled,
.ui-widget-content .ui-state-disabled,
.ui-widget-header .ui-state-disabled {
    opacity: .35;
    filter: Alpha(Opacity=35);
    background-image: none
}

.ui-state-disabled .ui-icon {
    filter: Alpha(Opacity=35)
}

.ui-icon {
    width: 16px;
    height: 16px
}

.ui-icon,
.ui-widget-content .ui-icon {
    background-image: url("images/ui-icons_222222_256x240.png")
}

.ui-widget-header .ui-icon {
    background-image: url("images/ui-icons_ffffff_256x240.png")
}

.ui-icon-circle-triangle-e {
    background-position: -48px -192px
}

.ui-icon-circle-triangle-s {
    background-position: -64px -192px
}

.ui-icon-circle-triangle-w {
    background-position: -80px -192px
}


.ui-corner-all,
.ui-corner-top,
.ui-corner-left,
.ui-corner-tl {
    border-top-left-radius: 4px
}

.ui-corner-all,
.ui-corner-top,
.ui-corner-right,
.ui-corner-tr {
    border-top-right-radius: 4px
}

.ui-corner-all,
.ui-corner-bottom,
.ui-corner-left,
.ui-corner-bl {
    border-bottom-left-radius: 4px
}

.ui-corner-all,
.ui-corner-bottom,
.ui-corner-right,
.ui-corner-br {
    border-bottom-right-radius: 4px
}
