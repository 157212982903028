.video-js{
  background-color: transparent;
  @media (max-width: 600px) {
    width: 100%;
  }
  &::before{
    content: "";
    background-color: rgba(84,95,127,0.38);
    display: block;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    height: 100%;
    outline: 0;
    width: 100%;
    position: absolute;
    z-index: 3;
  }
  .vjs-big-play-button{
    background-color: transparent;
    border: 0;
    border-radius: 0;
    cursor: pointer;
    right: initial;
    bottom: initial;
    left: 50%;
    top: 50%;
    height: 95px;
    outline: 0;
    width: 85px;
    transform: translate(-50%,-50%);
    z-index: 5;
    .vjs-icon-placeholder , .vjs-icon-play{
      &::before{
        font-size: 160px;
        left: -44px;
        top: 24px;
      }
    }
  }
  .vjs-tech{
    pointer-events: none;
  }
  &.vjs-has-started{
    .vjs-tech{
      pointer-events: auto;
    }
    &::before{
      display: none;
    }
  }
  &:hover, &:focus{
    .vjs-big-play-button{
      border: 0;
      background-color: transparent;
    }
  }
}
.vjs_video_3-dimensions{
  /* max-width: 100%;
  width: 638px;
  height: 296px; */
  width: 100%;
}