.bloc-pagination{
	float: left;
	width: 100%;
	.actu-pagination{
	    padding: 47px 0;
	    padding-bottom: 26px;
		display: flex; 
		justify-content: space-between;
		border-top: 1px solid @grey-a-propos-actus;
		&.dot-pagination {
			span.page-numbers.dots {
			    position: relative;
			    top: 6px;
			}
		}
		.page-item.prev, .page-item.next {
			padding-top: 7px;
		}
		.prev, .next{
			a{
				text-transform: uppercase;
				color: @grey-a-propos-actus;
				text-decoration: none;
				position: relative;
				&:hover{color: @secondary-pagination}
			}
		}
		.prev a{
			padding-left: 15px;
			&:before{
				position: absolute;
				content: '<';
				font-size: 20px;
				left: 0;
				top: 50%;
				transform: translateY(-50%) scale(.9,1.9);
			}
		}
		.next a{
			padding-right: 15px;
			&:before{
				position: absolute;
				content: '>';
				font-size: 20px;
				top: 50%;
				right: 0;
				transform: translateY(-50%) scale(.9,1.9);
			}
		}
		ul {
			padding: 0;
			border-radius: 0; 
			li {
		    	float: left;
			    list-style: none;
			    margin: 0 5px;
			    a, span{
			    	border-radius: 0;
			    	font-size: 14px;
			    	color: @grey-a-propos-actus;
			    	border-color: transparent;
					text-decoration: none;
					padding: 8px 14px;
					font-weight: bold;
					border-radius: 0; 
					&:hover{
						color: @secondary-pagination;
					}
			    }
			    &.active{
					span, a{
						background-color: @secondary-pagination;
						border-color: @secondary-pagination;
						color: white;
						border-radius: 0; 
					}
			    }
			}
			@media (max-width: 640px) {
			    position: absolute;
			    width: 100%;
			    top: 15px;
			    text-align: center;
			    li {
				    float: none;
				    display: inline-block;
				}
			}
		}
		
	}	

}

