//
// Glyphicons for Bootstrap
//
// Since icons are fonts, they can be placed anywhere text is placed and are
// thus automatically sized to match the surrounding child. To use, create an
// inline element with the appropriate classes, like so:
//
// <a href="#"><span class="glyphicon glyphicon-star"></span> Star</a>

@icon-font-path: "../fonts/icons/";
@icon-font-name: "glyphicons-halflings-regular";
@icon-font-svg-id: "glyphicons_halflingsregular";

// Import the fonts
@font-face {
  font-family: "Glyphicons Halflings";
  font-display: swap;
  src: url("@{icon-font-path}@{icon-font-name}.eot");
  src: url("@{icon-font-path}@{icon-font-name}.eot?#iefix")
      format("embedded-opentype"),
    url("@{icon-font-path}@{icon-font-name}.woff2") format("woff2"),
    url("@{icon-font-path}@{icon-font-name}.woff") format("woff"),
    url("@{icon-font-path}@{icon-font-name}.ttf") format("truetype"),
    url("@{icon-font-path}@{icon-font-name}.svg#@{icon-font-svg-id}")
      format("svg");
}

// Catchall baseclass
.glyphicon {
  position: relative;
  top: 1px;
  display: inline-block;
  font-family: "Glyphicons Halflings";
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// Individual icons
.glyphicon-envelope {
  &:before {
    content: "\2709";
  }
}

.glyphicon-check {
  &:before {
    content: "\e067";
  }
}
