header {
  float: left;
  width: 100%;
  &#masthead {
    position: absolute;
    z-index: 20;
    .top-bar {
      float: left;
      width: 100%;
      background: @white;
      .left-part {
        ul {
          margin: 0;
          float: left;
          padding: 0;
          li {
            list-style: none;
            float: left;
            position: relative;
            padding: 0;
            &:not(:last-child):after {
              content: "";
              position: absolute;
              height: 15px;
              background: #dddfe5;
              right: 0;
              top: 3px;
              width: 1px;
              @media (max-width: 640px) {
                display: none;
              }
            }
            a {
              color: @primary;
              padding: 0 11px;
              font-size: 13px;
              font-size: 13px;
              font-weight: 400;
              text-decoration: none !important;
              span {
                display: inline-block;
                position: relative;
                top: -1px;
              }
              &:hover {
                text-decoration: none;
                color: @secondary;
              }
            }
          }
        }
      }
      .right-part {
        ul {
          margin: 0;
          padding: 0;
          align-items: center;
          li {
            list-style: none;
            float: left;
            position: relative;
            padding: 0;
            &.lang {
              opacity: 0.5;
              &.active {
                opacity: 1;
              }
            }
            &:not(:last-child):after {
              content: "";
              position: absolute;
              height: 15px;
              background: #dddfe5;
              right: 0;
              top: 3px;
              width: 1px;
            }
            a {
              color: @primary;
              padding: 0 11px;
              font-size: 13px;
              font-size: 13px;
              font-weight: 600;
              &:hover {
                text-decoration: none;
                color: @secondary;
              }
            }
          }
        }
        .acces-client {
          a {
            padding: 4px 13px;
            display: block;
            color: @white;
            font-weight: 600;
            background: @primary;
            text-transform: uppercase;
            text-decoration: none;
            padding-left: 28px;
            font-size: 12px;
            position: relative;
            overflow: hidden;
            span {
              position: relative;
              z-index: 2;
            }
            &:after {
              content: "";
              width: 200%;
              height: 0;
              background-color: @secondary;
              position: absolute;
              left: 0;
              bottom: -23%;
              .transition(all, 0.5s, cubic-bezier(0.51, 0.1, 0.98, 0.7));
              z-index: 1;
              border-radius: 230% 0 0 0;
              left: 50%;
              transform: translateX(-105%) rotate(32deg);
            }
            &:hover {
              &:after {
                height: 135%;
                border-radius: 0 0 0 0;
                transform: translateX(-50%) rotate(0);
                .transition(all, 0.4s, cubic-bezier(0.18, 0.89, 0.32, 1.28));
              }
            }
            i {
              position: absolute;
              left: 8px;
              top: 7px;
              z-index: 2;
            }
          }
        }
        @media (max-width: 640px) {
          display: none !important;
        }
      }
      > .container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-left: 0;
        @media (max-width: 640px) {
          padding: 0;
          .left-part {
            width: 100%;
            margin-bottom: 3px;
            ul {
              display: flex;
              justify-content: space-between;
              width: 100%;
              li a {
                padding: 0 8px;
                font-size: 11px;
              }
            }
          }
        }
      }
    }
    &:not(.sticky)
      .menu-bar:not(.menu-light)
      #main-menu
      ul.menu
      > li.menu-item
      > a {
      color: #fff;
    }
    @media (max-width: 992px) {
      // même comportemen que sticky
      .top-bar {
        background: @primary;
        z-index: 100;
        .left-part {
          ul {
            li {
              &:not(:last-child):after {
                background: rgba(255, 255, 255, 0.5);
              }
              a {
                color: @white;
                &:hover {
                  text-decoration: none;
                  color: @secondary;
                }
              }
            }
          }
        }
        .right-part {
          ul {
            li {
              &:not(:last-child):after {
                background: rgba(255, 255, 255, 0.5);
              }
              a {
                color: @white;
                &:hover {
                  color: @secondary;
                }
              }
            }
          }
        }
      }
    }
    .menu-bar {
      float: left;
      width: 100%;
      top: -45px;
      @media (max-width: 992px) {
        background: white !important;
      }
      > .container {
        padding-left: 0;
        @media (max-width: 992px) {
          padding: 0;
        }
      }
      .m-wrap {
        justify-content: space-between;
      }
      .logo-type-2 {
        display: none;
      }
      .m-wrap {
        justify-content: space-between;
        @media (max-width: 992px) {
          display: block !important;
          padding: 0;
          background: white;
        }
      }
      .logo-top {
        a {
          display: block;
          padding: 20px 10px;
          @media (max-width: 992px) {
            padding: 20px 25px 10px 25px;
            float: left;
          }
          @media (max-width: 640px) {
            padding: 10px;
            float: left;
          }
          .logo-txt {
            color: #fff;
            font-size: 30px;
            font-weight: 900;
            text-align: center;
            border-top: 1px solid rgba(255, 255, 255, 0.5);
            margin-top: 6px;
            line-height: 20px;
            text-decoration: none !important;
            display: inline-block;
            padding: 7px 3px 0;
            @media (max-width: 992px) {
              color: @primary;
              font-size: 21px;
              margin-top: 2px;
              line-height: 25px;
              display: inline-block;
              padding: 2px 3px 0 10px;
              margin-left: 10px;
              border-left: 1px solid rgba(83, 96, 127, 0.2);
              border-top: transparent !important;
              vertical-align: bottom;
              strong {
                left: 0 !important;
                opacity: 1 !important;
              }
            }
            span {
              color: @secondary;
            }
          }
        }
        .initial {
          @media (max-width: 992px) {
            display: none !important;
          }
        }
        .minimize {
          display: none;
          .logo-txt {
            overflow: hidden;
            font-size: 21px;
            strong {
              top: 3px;
              opacity: 0.5;
              left: -110%;
              position: relative;
            }

            @media (max-width: 640px) {
              font-size: 19px;
            }
          }
          @media (max-width: 992px) {
            display: block !important;
            .odity-logo {
              float: left;
              padding-bottom: 10px;
            }
          }
          .odity-logo {
            @media (max-width: 640px) {
              margin-bottom: 0;
              img {
                max-width: 73px;
                margin-top: 9px;
              }
            }
          }
        }
      }
      .hamburger {
        display: none;
        position: absolute;
        right: 30px;
        top: 13px;
        @media (max-width: 992px) {
          display: block;
        }
        @media (max-width: 640px) {
          right: 15px;
          top: 5px;
        }
        .line {
          width: 28px;
          height: 2px;
          background-color: @blue-menu;
          display: block;
          margin: 9px auto;
          border-radius: 25px;
          transition: all 0.2s ease-in-out;
        }
        &.active {
          .line:nth-child(1) {
            transform: translateY(8px) rotate(45deg);
          }
          .line:nth-child(2) {
            transform: rotate(45deg);
            transform: translateY(-3px) rotate(-45deg);
          }
          .line:nth-child(3) {
            transform: translateY(-14px) rotate(-45deg);
          }
        }
      }
      .mobile-sub-bar {
        display: none;
        background: @darker-blue;
        ul {
          margin: 0;
          padding: 0;
          align-items: center;
          li {
            list-style: none;
            float: left;
            position: relative;
            padding: 0;
            &.lang {
              opacity: 0.5;
              &.active {
                opacity: 1;
              }
            }
            &:not(:last-child):after {
              content: "";
              position: absolute;
              height: 15px;
              background: #dddfe5;
              right: 0;
              top: 3px;
              width: 1px;
            }
            a {
              color: @white;
              padding: 0 11px;
              font-size: 13px;
              font-size: 13px;
              font-weight: 600;
              &:hover {
                text-decoration: none;
                color: @secondary;
              }
            }
          }
        }
        .acces-client {
          a {
            padding: 4px 13px;
            display: block;
            color: @white;
            font-weight: 600;
            background: transparent;
            text-transform: uppercase;
            text-decoration: none;
            padding-left: 28px;
            font-size: 12px;
            position: relative;
            overflow: hidden;
            span {
              position: relative;
              z-index: 2;
            }
            &:after {
              content: "";
              width: 200%;
              height: 0;
              background-color: @secondary;
              position: absolute;
              left: 0;
              bottom: -23%;
              .transition(all, 0.5s, cubic-bezier(0.51, 0.1, 0.98, 0.7));
              z-index: 1;
              border-radius: 230% 0 0 0;
              left: 50%;
              transform: translateX(-105%) rotate(32deg);
            }
            &:hover {
              &:after {
                height: 135%;
                border-radius: 0 0 0 0;
                transform: translateX(-50%) rotate(0);
                .transition(all, 0.4s, cubic-bezier(0.18, 0.89, 0.32, 1.28));
              }
            }
            i {
              position: absolute;
              left: 8px;
              top: 7px;
              z-index: 2;
            }
          }
        }
        @media (max-width: 640px) {
          display: flex;
          width: 100%;
          float: left;
          justify-content: space-between;
        }
      }
      #main-menu {
        align-items: center;
        @media (max-width: 992px) {
          clear: both;
          display: none !important;
          > div {
            background: @blue-menu;
            width: 100%;
            padding-bottom: 20px;
            float: left;
          }
        }
        &.visible {
          display: block !important;
        }
        ul.menu {
          padding: 0;
          margin: 0;
          > li.menu-item {
            float: left;
            list-style: none;
            position: relative;
            padding-bottom: 8px;
            &:last-child {
              padding-bottom: 0;
            }
            &.demo {
              padding-bottom: 0;
              padding-top: 8px;
              @media (min-width: 993px) {
                margin-left: 15px;
              }
              a {
                border: 1px solid @secondary;
                border-radius: 5px;
                padding: 4px 10px;
                &:after {
                  display: none;
                }
                &:hover {
                  background: @secondary;
                  color: #fff;
                }
              }
              @media (max-width: 992px) {
                padding-left: 20px;
                a {
                  display: inline-block;
                }
              }
            }
            &.menu-item-has-children {
              @media (max-width: 992px) {
                &::before {
                  content: "<";
                  font-size: 15px;
                  font-weight: 100;
                  z-index: 10;
                  position: absolute;
                  right: 10px;
                  top: 9px;
                  color: white;
                  .transition();
                  transform-origin: 50% 50%;
                  -webkit-transform: rotate(-90deg) scale(0.9, 1.9);
                  -ms-transform: rotate(-90deg) scale(0.9, 1.9);
                  transform: rotate(-90deg) scale(0.9, 1.9);
                  height: 20px;
                  display: block;
                  width: 40px;
                  line-height: 20px;
                  text-align: center;
                }
              }
            }
            &:hover {
              @media (max-width: 992px) {
                &::before {
                  .transition();
                  transform-origin: 50% 50%;
                  -webkit-transform: rotate(90deg) scale(0.9, 1.9);
                  -ms-transform: rotate(90deg) scale(0.9, 1.9);
                  transform: rotate(90deg) scale(0.9, 1.9);
                }
              }
              ul.children,
              ul.sub-menu {
                opacity: 1;
                pointer-events: auto;
                top: calc(~"100% + 0px");
                top: -ms-calc(~"100% + 0px");
                top: -o-calc(~"100% + 0px");
                top: -webkit-calc(~"100% + 0px");
                top: calc(~"100% + 0px");
                .transition(all, 0.2s);
                li {
                  width: 100%;
                  a {
                    opacity: 1;
                    top: 0px;
                    webkit-transition: all 0.4s ease-out 0.2s;
                    -o-transition: all 0.4s ease-out 0.2s;
                    transition: all 0.4s ease-out 0.2s;
                  }
                  .sub-menu {
                    display: none;
                    position: absolute;
                    left: 100%;
                    top: 0;
                  }
                  &:hover {
                    .sub-menu {
                      display: block;
                    }
                  }
                }
              }
            }
            &.current-menu-item,
            &.current-menu-parent {
              > a {
                background: rgba(255, 255, 255, 0.15);
                font-weight: 400;
                &:after {
                  width: 50px;
                  .transition(all, 0.3s, cubic-bezier(0, 0, 0.64, 1.18));
                }
              }
            }
            a {
              font-size: 14px;
              text-transform: uppercase;
              font-weight: 200;
              padding: 12px 10px 18px;
              white-space: nowrap;
              position: relative;
              text-decoration: none !important;
              display: block;
              @media (max-width: 1080px) {
                font-size: 12px;
              }
              @media (max-width: 992px) {
                padding: 10px 10px 10px 20px;
                color: @white !important;
              }
              &.active {
                background: rgba(255, 255, 255, 0.15);
                font-weight: 500;
                &:after {
                  content: "";
                  position: absolute;
                  height: 2px;
                  width: 50px;
                  background: @secondary;
                  left: 10px;
                  bottom: 10px;
                  .transition(all, 0.3s, cubic-bezier(0, 0, 0.42, 1.71));
                  z-index: 2;
                }
              }
              &:after {
                content: "";
                position: absolute;
                height: 2px;
                width: 0;
                background: @secondary;
                left: 10px;
                bottom: 10px;
                .transition(all, 0.3s, cubic-bezier(0, 0, 0.42, 1.71));
                @media (max-width: 992px) {
                  display: none;
                }
              }
              &:hover {
                &:after {
                  content: "";
                  width: 50px;
                  .transition(all, 0.3s, cubic-bezier(0, 0, 0.42, 1.71));
                }
              }
            }
            ul.children,
            ul.sub-menu {
              position: absolute;
              left: 10px;
              background: @white;
              padding: 0;
              top: calc(~"100% - 20px");
              top: -ms-calc(~"100% - 20px");
              top: -o-calc(~"100% - 20px");
              top: -webkit-calc(~"100% - 20px");
              top: calc(~"100% - 20px");
              z-index: 20;
              padding: 10px;
              box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.2);
              min-width: 160px;
              opacity: 0;
              pointer-events: none;
              .transition(all, 0.2s);
              @media (max-width: 992px) {
                position: relative;
                top: initial;
                opacity: 1;
                pointer-events: auto;
                float: left;
                width: 100%;
                left: initial;
                box-shadow: none;
                padding: 0px 15px;
                .transition(all, 0.3s, cubic-bezier(0, 0, 0.42, 1.71));
                max-height: 0;
                overflow: hidden;
              }
              &:before {
                content: "";
                position: absolute;
                top: -10px;
                left: 30px;
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 0 10px 10px 10px;
                border-color: transparent transparent #ffffff transparent;
                z-index: 2;
                @media (max-width: 992px) {
                  display: none;
                }
              }
              &:after {
                content: "";
                z-index: 1;
                position: absolute;
                top: -12px;
                left: 30px;
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 0px 11px 10px 10px;
                border-color: transparent transparent rgba(0, 0, 0, 0.12)
                  transparent;
                z-index: 1;
                filter: blur(1px);
                @media (max-width: 992px) {
                  display: none;
                }
              }
              li {
                list-style: none;
                a {
                  color: @black;
                  padding: 5px;
                  display: block;
                  text-transform: initial;
                  font-size: 13px;
                  opacity: 0;
                  top: -10px;
                  -webkit-transition: all 0.4s ease-out 0.2s;
                  -o-transition: all 0.4s ease-out 0.2s;
                  transition: all 0.4s ease-out 0.2s;
                  &:after {
                    bottom: 0;
                    left: 6px;
                  }
                  &.active {
                    font-weight: 400;
                    &:after {
                      width: 26px;
                      .transition(all, 0.3s, cubic-bezier(0, 0, 0.64, 1.18));
                    }
                  }
                  &:hover:after {
                    left: 6px;
                    bottom: 0;
                    width: 26px;
                    .transition(all, 0.3s, cubic-bezier(0, 0, 0.64, 1.18));
                  }
                  @media (max-width: 992px) {
                    opacity: 1;
                    top: initial;
                    color: @black !important;
                  }
                }
                &.current-menu-item {
                  a {
                    font-weight: 400;
                    &:after {
                      width: 26px;
                      .transition(all, 0.3s, cubic-bezier(0, 0, 0.64, 1.18));
                    }
                  }
                }
              }
            }
            @media (max-width: 992px) {
              width: 100%;
              padding-bottom: 0px;
              &:hover {
                ul.children,
                ul.sub-menu {
                  max-height: none;
                  .transition(all, 0.3s, cubic-bezier(0, 0, 0.42, 1.71));
                }
              }
            }
          }
        }
      }
      &.menu-light {
        background: rgba(255, 255, 255, 0.8);
        .logo-type-1 {
          display: none;
        }
        .logo-type-2 {
          display: block;
        }
        .menu-item {
          &.demo > a {
            border: 1px solid @primary;
            background: transparent;
            &:hover {
              background: @primary;
              color: white;
            }
          }
          > a {
            @media (min-width: 922px) {
              color: @black;
            }
            font-weight: 400;
          }
        }
        .logo-top {
          a {
            color: @black;
            .logo-txt {
              color: @primary;
              border-top: 1px solid rgba(83, 96, 127, 0.5);
              span {
                color: @secondary;
              }
            }
          }
        }
      }
    }

    &.sticky {
      .top-bar {
        position: fixed;
        background: @primary;
        z-index: 100;
        .left-part {
          ul {
            li {
              &:not(:last-child):after {
                background: rgba(255, 255, 255, 0.5);
              }
              a {
                color: @white;
                &:hover {
                  text-decoration: none;
                  color: @secondary;
                }
              }
            }
          }
        }
        .right-part {
          ul {
            li {
              &:not(:last-child):after {
                background: rgba(255, 255, 255, 0.5);
              }
              a {
                color: @white;
                &:hover {
                  color: @secondary;
                }
              }
            }
          }
        }
      }
      .menu-bar {
        background: @white;
        position: fixed;
        top: -107px;
        z-index: 20;
        box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.2);
        .transition(top, 0.4s, 0.4s);
        &.reveal {
          top: 25px;
          @media (max-width: 992px) {
            top: 24px;
          }
          .transition(top, 0.4s, 0.4s);
          .minimize {
            .logo-txt {
              strong {
                left: 0;
                opacity: 1;
                .transition(all, 1s, 0.8s);
              }
            }
          }
        }
        .logo-top {
          a {
            padding: 10px;
          }
          .initial {
            display: none;
          }
          .minimize {
            display: block;
            .odity-logo {
              float: left;
              margin-bottom: 7px;
              img {
                max-width: 173px;
              }
              @media (min-width: 993px) {
                padding-bottom: 0;
              }
              @media (max-width: 640px) {
                margin-bottom: 0;
                img {
                  max-width: 73px;
                  margin-top: 9px;
                }
              }
            }
            .logo-txt {
              color: @primary;
              font-size: 21px;
              margin-top: 2px;
              line-height: 25px;
              display: inline-block;
              padding: 2px 3px 0 10px;
              margin-left: 10px;
              border-left: 1px solid rgba(83, 96, 127, 0.2);
              border-top: transparent;
              vertical-align: bottom;
              @media (max-width: 992px) {
                border-top: none !important;
                font-size: 19px;
              }
            }
          }
        }
        @media (min-width: 993px) {
          #main-menu ul.menu li.menu-item a {
            color: @black;
          }
        }
        @media (max-width: 992px) {
          background-color: #fff;
        }
      }
    }
  }
}
