.bloc-references{
  color: @black-references;
  background-color: @grey-references;
  padding: 67px 0px;
  padding-bottom: 26px;
  position: relative;
  .owl-carousel .owl-stage-outer {
    text-align: -webkit-center;
  }
  .bloc-title{
    text-align: center;
    position: relative;
    width: 100%;
    .desc, .desc p{
      font-size: 20px;
    }
    .title{
      font-weight: 700;
    }
    .title-h2{
      &::before{
        content: '';
        background-color: @secondary-references;
        position: absolute;
        display: block;
        height: 4px;
        width: 30px;
        top: 0px;
        margin: 0 auto;
        margin-bottom: 5px;
        left: 50%;
        top: -20px;
        transform: translateX(-50%);
      }
    }
    &.in-left{
      text-align: left;
      width: 34%;
      padding-right: 5%;
      @media (max-width: 992px){
        width: 100%;
        text-align: center;
      }
      .title-h2{
        line-height: 46px;
        position: relative;
        font-weight: 800;
        display: inline-block;
        &::before{
          content: "";
          background-color: @white;
          position: absolute;
          width: 5px;
          height: 100%;
          top:0px;
          left: -28px;
        }
      }
      .desc , .desc p{
        font-size: 14px;
        margin-top: 43px;
      }
      &::before{
        display: none;
      }
    }
  }
  .bloc-desc{
    width: 100%;
    .title{
      font-weight: 700;
      display: inline-block;
      position: relative;
    }
    &.in-right{
      width: 66%;
      @media (max-width: 768px){
        width: 100%;
        text-align: center;
      }
    }
    >div{
      margin-bottom: 35px;
      &.row > div {
        margin-bottom: 35px;
        &.center-align {
          padding: 0 6%;
        }
      } 
      &.owl-carousel{
        margin-bottom: 12px;
      }
      @media (min-width: 768px){
        .right-align{
          padding-right: 50px;
        }
        .left-align{
          padding-left: 50px;
        }
      }
      @media (min-width: 992px){
        .right-align{
          padding-right: 75px;
        }
        .left-align{
          padding-left: 75px;
        }
      }
    }
  }
  .desc, .desc p{
    font-weight: 400;
    margin-top: 29px;
    margin-bottom: 26px;
    line-height: 28px;
    letter-spacing: 1px;
  }
  &.primary{
    background-color: @primary-references;
    color: @white;
  }
  &.secondary{
    background-color: @secondary-references;
    color: @white;
    .bloc-title, .title-h2{
      &::before{
        background-color: @white;
      }
    }
    .btn-decouvrir, .btn-ensavoirplus{
      background-color: @secondary-references;
      &:hover{
        color: @secondary-references;
        background-color: @white;
      }
    }
  }
  .item{
    padding-left: 55px;
    padding-right: 55px;
    height: 97px;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: space-around;
    img{
      width: auto;
      max-height: 60px;
    }
  }
  .owl-theme{
    .owl-dots{
      margin-top: 25px;
      .owl-dot{
        span{
          background: @grey-carousel-references;
          opacity: 1;
          margin: 5px 5px;
        }
        &.active, &:hover{
          span{
            background-color: @primary-references;
            opacity: 1;
          }
        }
      }
    }
  }
  .owl-theme .owl-nav.disabled + .owl-dots{
    margin-top: 25px;
  }
  .owl-carousel{
    .owl-item{
      .item{
        filter: grayscale(1);
        &:hover{
          filter: grayscale(0);
        }
      }
      a{
        img{
          width: auto;
          max-width: 100%;
          /* filter: grayscale(1); */
          display: inline-block;
        }
        &:hover{
          img{
            /* filter: grayscale(0); */
          }
        }
      }
      .img-link{
        width: 100%;
        text-align: center;
      }
    }
  }
  @media (max-width: 768px){
    .right-align, .left-align{
      text-align: center;
    }
  }
}