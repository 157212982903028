.bloc-conseils-guide {
  color: @black-conseils;
  padding: 35px 0px;
  padding-bottom: 30px;
  position: relative;
  > .container {
    padding-left: 95px;
    padding-right: 95px;
    @media (max-width: 992px) {
      padding-left: 0;
      padding-right: 0;
    }
  }
  .actus-conseils {
    float: left;
    text-align: left;
    width: 63%;
    padding-right: 50px;
    .img-link {
      height: 136px;
      display: flex;
      overflow: hidden;
      align-items: center;
      justify-content: space-around;
      img {
        width: 100%;
      }
    }
    .bloc-title {
      .desc {
        margin-bottom: 0;
        p {
          color: @black-conseils;
          font-size: 20px;
          // padding-left: 30px; enlevé car bug sur /logiciel-forum-en-ligne-pour-etude-qualitative.html
        }
      }
      .desc.paragraph {
        color: @black-conseils;
        font-size: 20px;
        line-height: 28px;
        padding-left: 30px;
        p {
          padding-left: 0;
        }
      }
    }
    .title-h2 {
      padding-left: 30px;
      &::before {
        background-color: @secondary-conseils;
      }
    }
    .title-h4 {
      font-size: 16px;
    }
    .paragraph,
    .paragraph p {
      color: @grey-conseils;
      line-height: 20px;
    }
    .bloc-desc {
      margin-top: 20px;
      .desc,
      .desc p {
        margin-top: 0px;
        margin-bottom: 25px;
      }
    }
    &.with-paragraph-desc {
      .bloc-title {
        .desc {
          &.paragraph {
            margin-bottom: 2px;
          }
        }
      }
      .bloc-desc {
        .desc {
          &.paragraph {
            line-height: 28px;
            margin-top: 0;
            p {
              line-height: 28px;
              margin-top: 0;
            }
            &.only {
              padding-left: 30px;
              padding-right: 10%;
            }
          }
          + .owl-carousel {
            margin-top: 39px;
          }
        }
        .item {
          .desc {
            &.paragraph {
              line-height: 20px;
              margin-top: 20px;
            }
          }
        }
      }
    }
    &.full-width {
      padding-right: 0;
      width: 100%;
      .bloc-desc {
        display: flex;
        margin-top: 50px;
        > .desc {
          flex-basis: 40%;
          width: 40%;
          max-width: 40%;
          padding-left: 30px;
          padding-right: 25px;
          @media (max-width: 992px) {
            width: 100%;
            flex-basis: 100%;
          }
          + .owl-carousel {
            margin-top: 0;
          }
          p + ul {
            margin-top: 5px;
          }
        }
        .owl-carousel {
          flex-basis: 60%;
          margin-top: 0;
          width: 60%;
          max-width: 60%;
          @media (max-width: 992px) {
            width: 100%;
            flex-basis: 100%;
          }
        }
        @media (max-width: 992px) {
          flex-wrap: wrap;
        }
      }
    }
    @media (max-width: 992px) {
      width: 100%;
      text-align: center;
      padding-right: 0px;
    }
  }
  .exp-areyounet {
    float: left;
    text-align: left;
    width: 37%;
    padding-left: 30px;
    &.full_width_conseil {
      width: 100%;
      padding-left: 25px;
      .bloc-desc {
        .title {
          &.left-logo {
            text-align: left;
            padding-left: 90px;
          }
          &.right-logo {
            text-align: right;
            padding-right: 24%;
          }
          a {
            font-size: 12px !important;
            text-decoration: underline;
            letter-spacing: 0;
          }
        }
        &.col-deux {
          .item {
            padding-right: 25%;
            @media (max-width: 992px) {
              padding-right: 0px;
            }
          }
          &.multiligne {
            display: flex;
            flex-wrap: wrap;
            .item {
              width: 50%;
              padding-right: 13%;
              float: left;
              margin-bottom: 75px;
              @media (max-width: 992px) {
                width: 100%;
                text-align: center;
                padding-right: 0px;
              }
            }
          }
        }
        &.col-three {
          .item {
            margin-right: 35px;
            padding-right: 0;
          }
        }
        &.col-four {
          .item {
            margin-right: 40px;
            padding-right: 0;
          }
        }
      }
    }
    .paragraph,
    .paragraph p {
      color: @grey-conseils;
      line-height: 20px;
    }
    .bloc-title {
      &.center_conseil {
        text-align: center;
        .title.title-h2::before {
          content: "";
          background-color: #f7a800;
          position: relative;
          display: block;
          height: 4px;
          width: 30px;
          margin: 15px auto;
          -webkit-transform: translateX(-50%);
          -ms-transform: translateX(-50%);
          transform: translateX(-50%);
        }
      }
      &.right_conseil {
        width: 45%;
        float: right;
      }
      .title-h2 {
        &:before {
          left: -28px;
        }
      }
      .desc,
      .desc p {
        color: @black-conseils;
        font-size: 20px;
        @media (max-width: 640px) {
          font-size: 14px;
          line-height: 18px;
        }
      }
    }
    .bloc-desc {
      .item {
        .title {
          height: 65px;
          display: flex;
          text-align: center;
          width: 100%;
          align-items: center;
          justify-content: center;
          .img-link {
            display: inline-block;
            box-shadow: none;

            -webkit-box-shadow: none;
            -moz-box-shadow: none;
            box-shadow: none;
            vertical-align: middle;
            margin-bottom: 0px;
          }
          img {
            margin-right: 15px;
            height: auto;
            max-height: 50px;
            width: auto;
            @media (max-width: 640px) {
              margin-bottom: 15px;
            }
          }
          .desc,
          .desc p {
            color: @black-conseils;
            display: inline-block;
            margin-top: 5px;
            text-align: left;
            margin-bottom: 5px;
            vertical-align: middle;
            span {
              display: block;
              line-height: 15px;
            }
            a {
              text-transform: none;
              color: @black-conseils;
              font-size: 13px;
            }
          }
        }
        .paragraph {
          margin-top: 0;
          margin-bottom: 0;
          p {
            border-top: 1px solid @border-grey-conseils;
            border-bottom: 1px solid @border-grey-conseils;
            margin: 0;
            padding-top: 18px;
            padding-bottom: 18px;
          }
        }
      }
    }
    @media (max-width: 992px) {
      width: 100%;
      text-align: center;
      margin-top: 30px;
      margin-left: 0 !important;
    }
  }
  .bloc-title {
    text-align: left;
    position: relative;
    float: left;
    width: 100%;
    &::before {
      content: "";
      background-color: @secondary-conseils;
      position: relative;
      display: block;
      height: 4px;
      width: 30px;
      top: 0px;
      margin: 0 auto;
      margin-bottom: 5px;
    }
    .desc,
    .desc p {
      font-size: 20px;
    }
    .title {
      font-weight: 800;
    }
    .title-h2 {
      line-height: 46px;
      position: relative;
      &::before {
        content: "";
        background-color: @secondary-conseils;
        position: absolute;
        width: 5px;
        height: 100%;
        top: 0px;
        left: 0px;
      }
    }
    .desc,
    .desc p {
      font-size: 14px;
    }
    &::before {
      display: none;
    }
  }
  .bloc-desc {
    float: left;
    width: 100%;
    .title {
      font-weight: 700;
      display: inline-block;
      position: relative;
    }
    &.in-right {
      width: 66%;
      @media (max-width: 992px) {
        width: 100%;
        text-align: center;
      }
    }
    > div {
      margin-bottom: 35px;
      &.owl-carousel {
        margin-bottom: 12px;
      }
    }
  }
  .desc,
  .desc p {
    font-weight: 400;
    margin-top: 25px;
    margin-bottom: 32px;
    line-height: 28px;
    letter-spacing: 1px;
  }
  .btn.btn-decouvrir {
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 600;
    border-radius: 5px;
    padding: 7px;
    min-width: 189px;
    color: @primary-conseils;
    background-color: @white;
    border: 1px solid @primary-conseils;
    &:hover {
      background-color: @primary-conseils;
      color: @white;
    }
  }
  .btn.btn-ensavoirplus {
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 600;
    border-radius: 5px;
    padding: 7px;
    min-width: 189px;
    color: @primary-conseils;
    background-color: @white;
    border: 1px solid @primary-conseils;
    &:hover {
      background-color: @primary-conseils;
      color: @white;
    }
  }
  .img-link {
    margin-bottom: 15px;
    -webkit-box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.35);
    -moz-box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.35);
    box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.35);
    position: relative;
    text-decoration: none;

    @media (max-width: 992px) {
      box-shadow: none;
      img {
        display: inline-block;
        width: auto;
        -webkit-box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.35);
        -moz-box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.35);
        box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.35);
      }
    }
    .client-case {
      background: @primary-conseils;
      color: @white;
      font-size: 12px;
      font-weight: 600;
      left: 0;
      line-height: 16px;
      letter-spacing: 0.4px;
      position: absolute;
      text-transform: uppercase;
      padding-left: 8px;
      padding-right: 5px;
      padding-top: 1px;
      padding-bottom: 1px;
      top: 0;
    }
  }
  .item {
    padding-left: 5px;
    padding-right: 20px;
  }
  ul {
    color: @grey-conseils;
    list-style: none;
    margin: 0;
    padding: 0;
    padding-right: 2%;
    position: relative;
    li {
      font-size: 14px;
      font-weight: 400;
      letter-spacing: 1px;
      line-height: 28px;
      padding-left: 30px;
      display: block;
      position: relative;
      strong {
        font-weight: 600;
      }
      &::before {
        content: "\ea10";
        color: @secondary-conseils;
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        line-height: 28px;
        font-family: "icomoon" !important;
        speak: none;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
      }
    }
  }
  .owl-theme {
    .owl-dots {
      margin-top: 25px;
      .owl-dot {
        span {
          background: @grey-carousel-conseils;
          opacity: 1;
          margin: 5px 5px;
        }
        &.active,
        &:hover {
          span {
            background-color: @primary-conseils;
            opacity: 1;
          }
        }
      }
    }
  }
  .owl-theme .owl-nav.disabled + .owl-dots {
    margin-top: 25px;
  }

  @media (max-width: 992px) {
    .right-align,
    .left-align {
      text-align: center;
    }
  }
}

.bloc-conseils-guide .bloc-title .sous-content {
  color: #888;
  line-height: 20px;
  font-size: 14px;
  margin-top: 15px;
  p + ul {
    margin-top: 25px;
  }
}
