body {
  background: @white;
  font-family: "Open Sans", "Arial";
  font-size: 14px;
}
@media (min-width: 1280px) {
  .container {
    width: 1260px;
    max-width: none;
  }
}
@media (min-width: 1374px) {
  .container {
    width: 1374px;
    max-width: none;
  }
}
@media (max-width: 1200px) {
  .container {
    max-width: none;
  }
}

.nopad {
  padding: 0;
}
.nopad-L {
  padding-left: 0;
}
.nopad-R {
  padding-righ: 0;
}

section {
  float: left;
  width: 100%;
  &.more-padding .container {
    padding-left: 95px;
    padding-right: 95px;
    @media (max-width: 992px) {
      padding-left: 0;
      padding-right: 0;
    }
  }
  &.map-areyounet {
    max-height: 400px;
  }
}

@media (max-width: 768px) {
  [class^="equalizer-"],
  [class*="equalizer-"] {
    height: auto !important;
  }
}
body.home .bloc-presentation.with-lists {
  background-image: url("../images/presentation/img-curve.png");
  background-attachement: fixed;
  background-position: 0% 25%;
  background-size: contain;
  background-repeat: no-repeat;
  @media (max-width: 900px) {
    background-image: none;
  }
}
p {
  margin-bottom: 0 !important;
  letter-spacing: 1px;
}
.spc-between {
  justify-content: space-between;
}
div.wpcf7-response-output {
  display: none !important;
}
.items-center {
  align-items: center;
}
.items-bottom {
  align-items: flex-end;
}
.title {
  text-transform: uppercase;
}
.title-h2 {
  font-size: 40px;
  line-height: 40px;
  @media (max-width: 640px) {
    font-size: 24px !important;
    line-height: 26px !important;
  }
}
.title-h3 {
  font-size: 24px;
  line-height: 24px;
}
.title-h4 {
  font-size: 18px;
  line-height: 22px;
}
.right-align {
  text-align: right;
}
.center-align {
  text-align: center;
}
.container {
  position: relative;
  &:before,
  &:after {
    display: none;
  }
}
.img-link {
  display: block;
}
.owl-theme {
  .owl-dots {
    margin-top: 22px;
    .owl-dot {
      outline: 0;
      span {
        background: @white;
        opacity: 0.4;
        margin: 5px 5px;
      }
      &.active,
      &:hover {
        span {
          background-color: @white;
          opacity: 1;
        }
      }
    }
  }
}
.owl-theme .owl-nav.disabled + .owl-dots {
  margin-top: 22px;
}
.parallax-bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
}
.parallax-mirror {
  overflow: visible !important;
  top: 35% !important;
}

.fn-connexes {
  background-color: @light-grey;
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  padding: 7px 15px;
  .fn-item {
    color: @dark-black;
    font-size: 14px;
    a {
      border-right: 1px solid @fn-connex-grey;
      color: @dark-black;
      display: block;
      margin-right: 15px;
      padding-right: 15px;
      text-decoration: none;
      cursor: pointer;
      &:hover {
        color: @secondary;
      }
      i {
        display: inline-block;
        margin-right: 15px;
        position: relative;
        top: 2px;
        &.icon-printer {
          font-size: 16px;
        }
      }
    }
    button {
      i {
        font-size: 16px;
        margin-right: 15px;
        position: relative;
        top: 2px;
      }
    }
    .dropdown-menu {
      background-color: @light-grey;
      border: 0;
      border-radius: 0;
      margin-left: -15px;
      min-width: 38px;
      z-index: 17;
      &:hover,
      &:focus {
        outline: 0;
      }
      .dropdown-item {
        background-color: transparent;
        margin: 0;
        padding: 0;
        outline: 0;
        &:hover,
        &:focus {
          background-color: transparent;
          outline: 0;
        }
      }
      li {
        a {
          border-bottom: 1px solid @fn-connex-grey-share;
          border-right: 0;
          color: @primary;
          display: block;
          font-size: 18px;
          margin: 0;
          margin-left: 15px;
          margin-right: 15px;
          padding: 0;
          padding-bottom: 1px;
          padding-top: 1px;
          &:hover {
            background-color: transparent;
            color: @secondary;
          }
          &:focus {
            background-color: transparent;
            color: @secondary;
          }
        }
        &:last-child {
          a {
            border-bottom: 0;
          }
        }
      }
    }
  }
  .btn-share {
    background-color: transparent;
    border: 0;
    cursor: pointer;
    outline: 0;
    padding: 0;
    &:hover {
      color: @secondary;
    }
    &:focus {
      outline: 0;
    }
  }
}
.top-navbar {
  position: relative;
  margin-bottom: 30px;
  + .bloc-presentation {
    padding-top: 0;
  }
  .container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}
.window-parallax-img {
  float: left;
  position: relative;
  width: 100%;
}
div#error {
  display: none !important;
}

.formulaire {
  .form-group {
    &.checkbox-inline {
      .wpcf7-list-item-label {
        text-transform: initial;
        font-size: 12px; line-height: 15px;
      }
      .wpcf7-list-item {
        margin: 0; display: flex;
      }
    }
    input[type="checkbox"], input[type="radio"] {
      width: 45px; height: 25px; margin-right: 15px;
    }
    .txt {
      font-size: 12px; line-height: 15px;
      margin-bottom: 10px !important;
    }
  }
  .wpcf7-form-control-wrap span.wpcf7-not-valid-tip { text-transform: initial; }
}

.page-template-ayn-landing-page {
  #masthead {
    display: none !important;
  }
  section.footer { display: none !important; }
  section.subfooter { display: none !important; }
  .banner-slide,
  .api_content { display: none !important; }
  .top-navbar { display: none !important; }
  .bloc-presentation {
    &.with-contact-form .bloc-image {
      flex-basis: 65%;
      @media (max-width: 1279px) {
        flex-basis: 50%;
      }
      @media (max-width: 991px) {
        flex-basis: 100%;
      }
    }
    &.with-contact-form .bloc-texte {
      flex-basis: 35%;
      @media (max-width: 1279px) {
        flex-basis: 50%;
      }
      @media (max-width: 991px) {
        flex-basis: 100%;
      }
    }
  }
  .wpcf7-form {
    .form-group {
      label { line-height: 16px !important; }
      @media (max-width: 1200px) {
        .no-pdl { padding-right: 0; }
        .no-pdr { padding-left: 0; }
      }
    }
  }
  .no-pdl { padding-left: 0; }
  .no-pdr { padding-right: 0; }
  .bloc-entete {
    padding-top: 0;
    .desc {
      > ul {
        color: #484848;
        list-style: none;
        margin: 0;
        padding: 0;
        position: relative;
        padding-right: 22%;
        font-size: 16px;padding: 0;
        > li {
          font-size: 16px;
          font-weight: 400;
          letter-spacing: 1px;
          line-height: 28px;
          padding-left: 30px;
          display: block;
          position: relative;
          &:first-child {
            &::before {
              content: "\ea10";
              color: #f7a800;
              display: block;
              position: absolute;
              left: 0;
              top: 0;
              line-height: 28px;
              font-family: icomoon!important;
              speak: none;
              font-style: normal;
              font-weight: 400;
              font-variant: normal;
              text-transform: none;
              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: grayscale;
            }  
          }
          > ul {
            padding: 0 0 0 25px;
            > li {
              padding: 0 0 0 15px;
              position: relative; line-height: 16px; margin-bottom: 9px;
              &::before {
                content:"";
                background-color: #f7a800;
                width: 7px; height: 7px; border-radius: 7px;
                position: absolute; top: 3px; left: 0;
              }  
            }
          }

        }
      }
    }
  }
}

