.banner {
  height: 553px;
  overflow: hidden;
  position: relative;
  &.litle-bannier {
    height: 422px;
  }
  &:before {
    content: "";
    position: absolute;
    width: 70%;
    height: 30%;
    background: white;
    bottom: 0%;
    right: -2%;
    transform: rotate(-11deg) translate(15%, 78%) scale(2, 2);
    z-index: 2;
    @media (max-width: 1060px) {
      transform: rotate(-11deg) translate(15%, 95%) scale(10, 2);
    }
    @media (max-width: 500px) {
      bottom: -10%;
    }
  }
  &:after {
    content: "";
    position: absolute;
    bottom: -15%;
    right: -10px;
    width: 100%;
    height: 100%;
    background: url("../images/courbe-banniere.png") no-repeat;
    background-position: 100% 100%;
    background-size: 100%;
    @media (max-width: 1600px) {
      bottom: -5%;
    }
    @media (max-width: 1200px) {
      bottom: -2%;
    }
  }
  img {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    min-width: 100%;
  }
  .banner-caption {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-65%, -10%);
    width: 790px;
    max-width: 85%;
    h1,
    h1 p {
      text-transform: uppercase;
      color: @white;
      font-size: 40px;
      font-weight: 800;
    }
    > p {
      font-size: 26px;
      font-weight: 200;
      color: @white;
    }
    @media (max-width: 1200px) {
      transform: translate(-50%, -10%);
      h1 {
        font-size: 28px;
      }
      p {
        font-size: 20px;
      }
    }
    @media (max-width: 640px) {
      transform: translate(-50%, -50%);
      h1 {
        font-size: 28px;
      }
      p {
        font-size: 20px;
      }
    }
  }
}
.banner-slide {
  /* overflow: hidden; */
  position: relative;
  z-index: 0;
  .tp-leftarrow,
  .tp-rightarrow {
    // background: rgba(255, 255, 255, 0.5);
    width: 60px;
    height: 60px;
    @media (max-width: 1280px) {
      top: 65% !important;
    }
  }
  .tp-leftarrow {
    opacity: 0.5;
    .transition(all, 0.3s, ease);
    &:hover {
      .transition(all, 0.3s, ease);
      opacity: 0.7;
    }
    &:before {
      display: none;
    }
    &:after {
      content: "";
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 60px;
      height: 60px;
      background: url("../images/arrow_slide_hp.png") no-repeat left top;
    }
  }
  .tp-rightarrow {
    right: 30px !important;
    opacity: 0.5;
    .transition(all, 0.3s, ease);
    &:hover {
      .transition(all, 0.3s, ease);
      opacity: 0.7;
    }
    &:before {
      display: none;
    }
    &:after {
      content: "";
      font-size: 45px;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 60px;
      height: 60px;
      background: url("../images/arrow_slide_hp.png") no-repeat right top;
    }
  }
  .tp-caption.rev-btn {
    @media (max-width: 1800px) {
      min-width: 90px !important;
      width: 100px;
      padding: 10px 1px !important;
    }
  }
  @media (max-width: 992px) {
    padding-top: 100px;
  }
  &.is-loading {
    background: #53607f;
    min-height: 70vh;
    .loader {
      content: "";
      position: absolute;
      left: 50%;
      top: 50%;
      .translate(-50%, -50%);
      width: 75px;
      height: 75px;
    }
  }
}
