.breadcrumb{
  background-color: @white;
  margin: 0;
  padding: 6px 0px;
  .breadcrumb-item{
      color: @secondary;
      font-size: 13px;
      span, a {
        display: inline-block;
        &:first-letter{
          text-transform: uppercase;
        }
        &.breadcrumb_last {
          margin-left: 3px;
        }
      }
     a{
       color: @dark-black;
       text-decoration: none !important;
       display: inline-block;
       &:hover{
         text-decoration: underline;
       }
       &:first-letter{
         text-transform: uppercase;
       }
       &.home-ico{
         display: block;
         position: relative;
         top: 1px;
         &:hover{
           text-decoration: none;
         }
       }
       .icon-home{
          color: @secondary;
          font-size: 14px;
       }
     }
  }
}
.breadcrumb-item + .breadcrumb-item{
  padding-left: 0.4rem;
  &::before{
    padding-right: 0.4rem;
    color: @dark-black;
  }
}