.api_content {
  position: fixed;
  right: 1.5%;
  /* bottom: 217px; */
  bottom: 0;
  z-index: 35;
  width: 490px;
  top: inherit;

  -o-transform-origin: 100% 100%;
  -moz-transform-origin: 100% 100%;
  -webkit-transform-origin: 100% 100%;
  -ms-transform-origin: 100% 100%;
  transform-origin: 100% 100%;
  -webkit-filter: blur(0);

  &.more-index {
    z-index: 40;
  }

  label,
  a,
  span,
  p {
    backface-visibility: hidden;
    -webkit-transition: all 0.2s linear;
    -moz-transition: all 0.2s linear;
    -ms-transition: all 0.2s linear;
    -o-transition: all 0.2s linear;
    transition: all 0.2s linear;
  }

  @media (max-width: 1450px) {
    -moz-transform: scale(0.7); /* Firefox */
    zoom: 70%;
  }
}
.api_wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  float: left;
  top: 0;
  left: 0;
}

.api_fixed {
  background: none repeat scroll 0 0 #fff;
  background: none repeat scroll 0 0 rgba(255, 255, 255, 0.9);
  box-shadow: 0 0 18px rgba(0, 0, 0, 0.5);
  position: fixed;
  transition: left 0.5s ease 0s;
  -moz-transition: left 0.5s ease 0s;
  -webkit-transition: left 0.5s ease 0s;
  -ms-transition: left 0.5s ease 0s;
  -o-transition: left 0.5s ease 0s;
  z-index: 10;
  width: 345px;
  right: 5%;
  @media (max-width: 520px) {
    width: 385px;
  }
  input {
    background: none repeat scroll 0 0 #f0f0f0;
    border-radius: 0;
    box-shadow: 0 0;
    color: #333;
    display: block;
    border: none;
    outline: none;
    top: 0;
    left: 0;
    display: block;
    background: transparent;
    z-index: 2;
    border-bottom: 2px solid @white;
    height: 45px;
    &.inputtext {
      color: @black;
      font-size: 14px;
      width: 100%;
      margin-bottom: 10px;
    }
    &.error {
      border-color: @red;
    }
  }
  .after-content {
    position: absolute;
    bottom: -64px;
    display: none;
    background: @grey-assistance;
    .box-shadow(0px 0px 15px 0px rgba(0, 0, 0, 0.35));
    padding: 0;
    z-index: 9;
    width: 447px;
    right: -12%;
    &::before {
      content: "";
      display: block;
      border-radius: 50px;
      .box-shadow(0px 0px 15px 0px rgba(0, 0, 0, 0.35));
      width: 91px;
      height: 91px;
      position: absolute;
      top: -44px;
      left: 3px;
      z-index: 0;
    }
    .headercall {
      background: @secondary;
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;
      padding-left: 109px;
      padding-right: 11px;
      width: 100%;
      height: 60px;
      &::before {
        content: "";
        display: block;
        position: absolute;
        left: 28px;
        bottom: -15px;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 15px 15px 0;
        border-color: transparent @secondary transparent transparent;
      }
      .fermer {
        display: block;
        cursor: pointer;
        border-radius: 50%;
        position: relative;
        height: 40px;
        width: 40px;
        .transition(all, 0.4s, ease);
        &:hover {
          text-decoration: none;
          .transition(all, 0.4s, ease);
          //.rotation(180deg);
        }
        &::before {
          content: "";
          position: absolute;
          width: 4px;
          height: 31px;
          background: @white;
          left: 25px;
          top: 4px;
          .rotation(45deg);
        }
        &::after {
          content: "";
          position: absolute;
          width: 4px;
          height: 31px;
          background: @white;
          right: 11px;
          top: 4px;
          .rotation(-45deg);
        }
      }
      .question {
        color: @white;
        display: flex;
        align-items: center;
        p {
          text-align: left;
          color: #5c6266;
          margin-top: 24px;
          margin-left: 0px;
          font-size: 15px;
        }
        span {
          font-size: 16px;
          &.call.icon {
            font-size: 28px;
            margin-right: 30px;
          }
        }
        strong {
          line-height: 40px;
        }
      }
      img.call-second {
        cursor: default;
        position: absolute;
        top: -45px;
        left: 3px;
      }
    }
    label {
      color: @black;
      font-weight: 300;
      display: inline-block;
      letter-spacing: -0.5px;
      line-height: 24px;
      margin-bottom: 0px;
      margin-top: 0px;
      position: relative;
      top: -4px;
      cursor: pointer;
      strong {
        font-weight: 600;
      }
    }
    .sh-rappel {
      label {
        font-size: 16px;
        letter-spacing: 0;
        line-height: 30px;
        span {
          strong {
            color: @primary;
          }
        }
      }
    }
  }
  .form-group.input-container {
    margin: 0;
    padding-left: 13px;
    padding-right: 13px;
  }
  .form-group.radio-container {
    width: 100%;
    margin-bottom: 5px;
    padding-left: 43px;
    padding-right: 13px;
    position: relative;
    &.call_rdv_1 {
      margin-top: 35px;
    }
  }
  .form-group.radio-container::after {
    content: "";
    position: absolute;
    width: 15px;
    height: 15px;
    background: url(../../assets/images/callback/radio.png) no-repeat 0% 0;
    top: 0px;
    left: 13px;
  }
  .form-group.radio-container.checked::after {
    background: url(../../assets/images/callback/radio.png) no-repeat 0% -30px;
  }
  .form-group.radio-container input {
    float: left;
  }
  .form-group.radio-container:nth-of-type(3) {
    margin-bottom: 25px;
  }
  input.hasDatepicker {
    /*background: url(../../assets/images/callback/calendar.png) 8px center no-repeat;*/
    height: 0;
    padding: 0px;
  }
  input[type="radio"] {
    display: inline-block;
    margin: 0 6px 0 0;
    opacity: 0;
    position: absolute;
    left: 12px;
    height: auto;
    width: 15px;
    top: 0px;
    cursor: pointer;
  }
  .btn {
    border: 1px solid @primary;
    background: @primary;
    color: @white;
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    transition: all 0.3s ease;
    font-weight: 700;
    text-align: center;
    vertical-align: middle;
    touch-action: manipulation;
    cursor: pointer;
    font-size: 14px;
    line-height: 1.42857;
    border-radius: 5px;
    max-height: 36px;
    margin: 0 auto;
    display: block;
    text-transform: uppercase;
    &:hover {
      background: @white;
      color: @primary;
    }
  }
  .before-content {
    position: relative;
    width: inherit;
  }
  .label_succes {
    color: green;
    display: block;
    position: relative;
    text-align: center;
    top: -2px;
    margin-top: 20px;
    clear: both;
  }
  form {
    margin: 0;
    padding-bottom: 40px;
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 12px;
    &.pr {
      padding-bottom: 20px;
    }
  }
  sup {
    top: -25px;
    right: -30px;
  }
}

.example-container {
  opacity: 1;
  height: auto;
  .transition(all, 0.2s, cubic-bezier(0.4, 0, 1, 1));
  &.no-opacity {
    opacity: 0;
    height: 0;
    overflow: hidden;
    pointer-events: none;
  }
}

img.icon-call {
  width: 120px;
  max-width: 120px;
  background: transparent;
}

.title_api {
  cursor: default;
  display: inline-block;
  margin: 0 -5px 0 0;
  padding: 0;
  width: 100%;
  height: auto;
  position: absolute;
  bottom: -225px;
  right: 0;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  transition: all 0.3s ease;
  @media (max-width: 1366px) {
    &.up {
      bottom: 80px !important;
    }
  }
  .imagecall {
    min-width: 267px;
    position: absolute;
    right: -40px;
    width: auto;
    text-align: left;
    top: -7px;
    @media (max-width: 520px) {
      min-width: 377px;
    }
    &::before {
      content: "";
      display: block;
      border-radius: 50px;
      .box-shadow(0px 0px 15px 0px rgba(0, 0, 0, 0.35));
      width: 91px;
      height: 91px;
      position: absolute;
      top: 1px;
      left: 0px;
      z-index: 1;
    }
  }
  .question {
    padding: 0;
    overflow: hidden;
    display: block;
    max-width: 266px;
    right: -114px;
    position: relative;
    top: 105px;
    @media (max-width: 520px) {
      max-width: 380px;
      right: -40px;
    }
    .slider-text {
      background: transparent;
      list-style: none;
      opacity: 0;
      padding: 0;
      text-align: left;
      position: relative;
      border-radius: 0px;
      /*transition: all 0.2s ease 0s;
      -moz-transition: all 0.4s ease 0s;
      -webkit-transition: all 0.4s ease 0s;
      -ms-transition: all 0.4s ease 0s;
      -o-transition: all 0.4s ease 0s;*/
      max-height: 97px;
      min-width: 266px;
      @media (max-width: 520px) {
        min-width: 376px;
      }
      li {
        cursor: pointer;
        .box-shadow(0px 0px 15px 0px rgba(0, 0, 0, 0.35));
        position: relative;
        a {
          display: flex;
          align-items: center;
          background-color: @white;
          color: @white;
          border: 1px solid @primary;
          margin: 0;
          margin-top: 8px;
          position: relative;
          span {
            position: relative;
            vertical-align: top;
            z-index: 1;
          }
          .icon {
            align-items: center;
            display: flex;
            color: @white;
            font-size: 29px;
            height: 44px;
            margin-right: 13px;
            padding: 0;
            text-align: center;
            width: 51px;
            @media (max-width: 520px) {
              font-size: 19px;
            }
            i {
              display: block;
              margin: 0 auto;
            }
            &.chat {
              font-size: 25px;
            }
            &.mess {
              font-size: 19px;
            }
          }
          .txtbtn {
            color: @black;
            font-size: 16px;
            font-weight: 400;
            text-transform: uppercase;
            @media (max-width: 520px) {
              font-size: 12px;
            }
            &.min {
              font-size: 12px;
              line-height: 19px;
              text-transform: none;
              top: -2px;
            }
            strong {
              color: @blue-assistance;
              font-size: 18px;
              font-weight: 700;
              letter-spacing: 1px;
              @media (max-width: 520px) {
                font-size: 12px;
              }
            }
          }
        }
        &::after {
          background-color: @primary;
          content: "";
          height: 45px;
          position: absolute;
          left: 0px;
          top: 0;
          width: 51px;
          .transition(all, 0.2s, cubic-bezier(0.4, 0, 1, 1));
          z-index: 0;
        }
        &:hover {
          text-decoration: none;
          &::after {
            /*width: 218px;
            left: 51px;*/
            .transition(all, 0.2s, cubic-bezier(0, 0.54, 0.4, 1.04));
          }
          a {
            background-color: @grey-assistance;
            .icon {
              /*color: @primary;*/
            }
            .txtbtn {
              /*color: @white;*/
              strong {
                /*color: @white;*/
              }
            }
            &:hover {
              text-decoration: none;
            }
          }
        }
      }
    }
  }
  &.opened {
    height: auto;
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    transition: all 0.3s ease;
    .question {
      /*position: absolute;
      top: 105px;
      left: 115px;*/
      .slider-text {
        max-height: none;
        opacity: 1;
        right: 0px;
        position: relative;
        /*transition: all 0.4s ease 0s;
        -moz-transition: all 0.4s ease 0s;
        -webkit-transition: all 0.4s ease 0s;
        -ms-transition: all 0.4s ease 0s;
        -o-transition: all 0.4s ease 0s;*/
      }
    }
  }
  p {
    margin: 0;
  }
}

img.call-second {
  cursor: pointer;
  border-radius: 50px;
  border: 2px solid @white;
  position: relative;
  top: 0;
  left: 0;
  max-width: 157px;
  z-index: 2;
}
.call-second-text {
  cursor: pointer;
  position: absolute;
  background-color: @secondary;
  top: 40px;
  left: -3px;
  color: white;
  z-index: 1;
  min-height: 60px;
  padding-left: 95px;
  font-size: 16px;
  padding-right: 7px;
  font-weight: 300;
  min-width: 266px;
  max-width: 267px;
  @media (max-width: 520px) {
    min-width: 376px;
    max-width: 377px;
    font-size: 13px;
    left: 0px;
  }
  .box-shadow(0px 0px 15px 0px rgba(0, 0, 0, 0.35));
  span {
    display: block;
    &.lighter {
      font-size: 14px;
    }
  }
  &::before {
    content: "";
    display: block;
    position: absolute;
    left: 28px;
    bottom: -15px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 15px 15px 0;
    border-color: transparent @secondary transparent transparent;
  }
  &::after {
    content: "<";
    font-size: 28px;
    font-weight: 100;
    position: absolute;
    right: 12px;
    top: -7px;
    .transition();
    -webkit-transform: rotate(90deg) scale(0.9, 1.9);
    -ms-transform: rotate(90deg) scale(0.9, 1.9);
    transform: rotate(90deg) scale(0.9, 1.9);
  }
  &.collapsed {
    &::after {
      content: ">";
    }
  }
}
.question strong {
  color: white;
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 100;
}
.question p {
  text-align: left;
  color: #ffffff;
  margin-top: 24px;
  margin-left: 8px;
  font-size: 15px;
}
span.btnblb.callbtn {
  display: inline-block;
  text-align: center;
  background: #f79520;
  text-transform: uppercase;
  padding: 8px 15px;
  color: #ffffff;
  margin: 5px;
  border: 1px solid #f79520 !important;
}
span.btnblb.callbtn:hover {
  background-color: #fff;
  border: 1px solid #f79520 !important;
  color: #f79520 !important;
}

.api_fixed .hasDatepicker:hover {
  cursor: pointer !important;
}

pre {
  display: none;
}

.api_fixed #basic_example_1 {
  display: inline-block;
  width: 68%;
  vertical-align: top;
  margin-top: 7px;
}

.valid-rappel-col {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 12px;
  .btn {
    margin: 0;
    &.btn-annuler {
      background-color: transparent;
      border: 1px solid @primary;
      color: @primary;
      font-weight: 400;
      max-width: 140px;
      min-width: 138px;
      &:hover {
        background-color: @primary;
        color: #ffffff;
      }
    }
    &.m-auto {
      margin: 0 auto;
    }
  }
}

/****** date picker *********/

#ui-datepicker-div {
  background: none;
  border-radius: 0;
  border: 0;
  font-family: "open sans", sans-serif !important;
  margin-top: 2px;
  left: initial !important;
  top: initial !important;
  width: 100%;
  padding: 0;
  position: relative !important;
  display: block !important;
  opacity: 1 !important;
  table {
    margin-bottom: 0;
    position: relative;
    width: 100%;
    &::after {
      content: "";
      display: block;
      position: absolute;
      bottom: -13px;
      height: 2px;
      left: 13px;
      right: 13px;
      background-color: @white;
    }
  }
}
img#load-callback {
  position: absolute;
  display: block;
  z-index: 88888;
  top: inherit;
  left: inherit;
  opacity: 1;
  right: 63px;
  width: 20px;
  height: 20px;
  bottom: 47px;
  &.with-pr {
    bottom: 29px;
    right: 16px;
  }
}
@media (max-width: 1024px) {
  .title_api .question .question strong {
    font-size: 17px;
  }
}
@media (max-width: 800px) {
  .api_fixed {
    right: 10%;
  }
  .api_content {
    -moz-transform: scale(0.9); /* Firefox */
    zoom: 90%;
  }
}
@media (max-width: 640px) {
  .api_content {
    -moz-transform: scale(0.7); /* Firefox */
    zoom: 70%;
  }
}
@media (max-width: 500px) {
  .api_content {
    -moz-transform: scale(0.6); /* Firefox */
    -moz-transform-origin: 0 0;
    zoom: 60%;
  }
}

.ui-datepicker {
  th {
    background: @primary !important;
    color: @white !important;
    padding: 3px;
    text-align: center;
  }
  td {
    text-align: center;
  }
  .ui-datepicker-buttonpane {
    width: 100%;
    float: left;
    margin-top: 0;
    padding-bottom: 5px;
    display: none;
  }
  .ui-datepicker-title {
    font-size: 18px;
    font-weight: 600;
    text-transform: uppercase;
  }
  .ui-datepicker-next {
    background-image: none;
    cursor: pointer;
    span {
      display: block;
      position: relative;
      margin: 0;
      left: initial;
      top: initial;
      font-size: 0;
      &::before {
        content: ">";
        color: @white;
        font-size: 23px;
        font-weight: 100;
        position: absolute;
        right: 0px;
        top: 0px;
        .transition();
        -webkit-transform: scale(0.9, 1.9);
        -ms-transform: scale(0.9, 1.9);
        transform: scale(0.9, 1.9);
      }
    }
    .ui-icon {
      overflow: visible;
      text-indent: 0;
    }
    &.ui-state-disabled {
      cursor: not-allowed;
      pointer-events: none;
    }
    &.ui-state-hover {
      padding: 0;
    }
  }
  .ui-datepicker-prev {
    background-image: none;
    cursor: pointer;
    span {
      display: block;
      position: relative;
      margin: 0;
      left: initial;
      top: initial;
      font-size: 0;
      &::before {
        color: @white;
        content: "<";
        font-size: 23px;
        font-weight: 100;
        position: absolute;
        right: -4px;
        top: 0px;
        .transition();
        -webkit-transform: scale(0.9, 1.9);
        -ms-transform: scale(0.9, 1.9);
        transform: scale(0.9, 1.9);
      }
    }
    .ui-icon {
      overflow: visible;
      text-indent: 0;
    }
    &.ui-state-disabled {
      cursor: not-allowed;
      pointer-events: none;
    }
    &.ui-state-hover {
      left: -1px;
      padding: 0;
    }
  }
}
.ui-datepicker-header {
  background: @secondary;
  color: @white;
  border: 1px solid @secondary;
  border-radius: 0;
}
.ui-state-default,
.ui-widget-content .ui-state-default,
.ui-state-hover,
.ui-widget-header .ui-state-default {
  border: none;
  padding: 6px;
  text-align: center;
}
.ui-state-default,
.ui-widget-content .ui-state-default {
  color: @black;
  background: none;
  border: 0;
  font-size: 16px;
  font-weight: 400;
}
.ui-datepicker-calendar {
  th {
    span {
      color: @white;
      font-size: 16px;
      font-weight: 300;
      text-transform: uppercase;
    }
  }
  td {
    a {
      color: @dark-black;
      font-size: 16px;
      font-weight: 400;
      &:hover {
        text-decoration: none;
        padding: 2px 4px;
        display: inline;
        background: @grey-hover-assistance;
      }
      &.ui-state-active {
        background: @primary;
        color: @white;
      }
    }
    &.ui-state-disabled {
      span {
        /*color: @grey-calendar-assistance;*/
        font-size: 16px;
      }
    }
    &.ui-datepicker-today {
      a {
        color: @white;
        background-color: @grey2-calendar-assistance;
        &.ui-state-active {
          background: @primary;
          color: @white;
        }
        &.ui-state-default {
          padding: 2px 4px;
          display: inline;
        }
      }
    }
  }
}

.ui-state-default.ui-state-active {
  background: @primary !important;
  color: @white !important;
  border-radius: 0;
  /* width: 40px; */
  /* height: 40px; */
  font-weight: 400;
  padding: 2px 4px;
  display: inline;
}

.ui-timepicker-div {
  margin-bottom: 20px;
  padding: 0px;
  padding-bottom: 4px;
  position: relative;
  width: 100%;
  &::after {
    content: "";
    display: block;
    position: absolute;
    bottom: -2px;
    height: 2px;
    left: 13px;
    right: 13px;
    background-color: @white;
  }
  > dl {
    padding: 0;
    margin-bottom: 0;
    width: 100%;
  }
  dt {
  }
  .custom-col-1 {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    margin-top: 25px;
    width: 100%;
  }
  .custom-col-2 {
    display: flex;
    align-items: center;
    flex-basis: 100%;
    flex-wrap: wrap;
    .ui_tpicker_hour_label,
    .ui_tpicker_minute_label {
      flex-basis: 20%;
      margin-bottom: 22px;
    }
    dd.ui_tpicker_hour,
    dd.ui_tpicker_minute {
      flex-basis: 80%;
      margin-bottom: 30px;
    }
  }
}
.ui_tpicker_time {
  color: @primary;
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 0;
  margin-left: 5px;
}
.ui_tpicker_time_label {
  color: @black;
  font-size: 14px;
  font-weight: 300;
}

.ui-slider-handle.ui-state-default.ui-corner-all {
  background: @primary;
  border-radius: 0;
  border: none;
  font-size: 22px;
  outline: none;
  top: -12px;
}
dd.ui_tpicker_hour,
dd.ui_tpicker_minute {
  color: @black;
  font-size: 14px;
  font-weight: 300;
}
.ui-datepicker-close.ui-state-default.ui-priority-primary.ui-corner-all {
  background: #f79520 !important;
  color: #fff;
  border-radius: 0;
  border: none;
  font-family: "open sans", sans-serif;
  text-transform: uppercase;
  font-weight: 400;
  font-size: 19px;
  padding: 10px 28px;
  margin-top: 15px;
  margin-right: 15px;
}

.ui-datepicker-current.ui-state-default.ui-priority-secondary.ui-corner-all {
  border-radius: 0;
  font-family: "open sans", sans-serif;
  background: #ffffff !important;
  color: #f79520;
  border-radius: 0;
  border: none;
  font-family: "open sans", sans-serif;
  padding: 10px 28px;
  text-transform: uppercase;
  font-weight: 400;
  font-size: 15px;
  border: 1px solid #f79520;
  margin-top: 15px;
  margin-left: 15px;
  display: none;
}
.ui-slider.ui-slider-horizontal {
  height: 3px;
  background: @grey2-calendar-assistance;
  margin-top: 10px;
}
.ui-icon,
.ui-widget-content .ui-icon,
.ui-widget-header .ui-icon {
  background-image: none;
}
.ui-widget-header .ui-state-hover {
  border: 1px solid rgba(153, 153, 153, 0);
  background: none !important;
  font-weight: 400;
  color: #212121;
}

#erreur_contact .wght-bold,
#confirmation_contact .wght-bold {
  margin-top: 50px;
}

#erreur_contact .btn,
#confirmation_contact .btn {
  background: #565b7e;
  color: #fff;
}

.ui_tpicker_hour_label,
.ui_tpicker_minute_label {
  color: @black;
  font-size: 14px;
  font-weight: 300;
}
span#call_error {
  color: @red;
  display: block;
  font-size: 12px;
  position: relative;
  text-align: left;
  top: -2px;
  margin-top: 15px;
  clear: both;
}

.icon-fone {
  padding: 0 13px 0 12px;
  display: inline;
  display: inline-block;
}

.overlay {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 10;
  left: 0;
  display: none;
}
#email_api {
  display: none;
}

.calendrier_RDV {
  height: 620px;
  overflow: auto;
  -moz-overflow: auto;
  -webkit-overflow: auto;
  transition: all 0.2s cubic-bezier(0.4, 0, 1, 1);
  @media (max-width: 720px) {
    height: 400px;
  }
}
@media (max-width: 720px) {
  .api_fixed .after-content {
    bottom: -100px;
    width: 450px;
  }
  .api_fixed form {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.calendrier_RDV::-webkit-scrollbar-track {
  border-radius: 10px;
  background: #eee;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

/*-----------------------*/

/*---animation---*/

.animated-api {
  -webkit-animation-duration: 4s;
  -webkit-animation-iteration-count: 10;
  -webkit-animation-timing-function: ease-out;
  -webkit-animation-fill-mode: forwards;

  animation-duration: 4s;
  animation-iteration-count: 10;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
}
.bounce {
  -webkit-animation-name: bounce;
  animation-name: bounce;
}
@-webkit-keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    -webkit-transform: translateY(0);
  }
  40% {
    -webkit-transform: translateY(-10px);
  }
  60% {
    -webkit-transform: translateY(-5px);
  }
}
@-moz-keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    -moz-transform: translateY(0);
  }
  40% {
    -moz-transform: translateY(-10px);
  }
  60% {
    -moz-transform: translateY(-5px);
  }
}
@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-5px);
  }
}
@media print {
  div.api_content {
    display: none;
  }
}
@media (max-width: 450px) {
  .title_api {
    .imagecall.columns {
      background: #f7a800;
      right: -320px;
      border-radius: 44px;
    }
    &.opened {
      .imagecall.columns {
        background: none;
        right: -40px;
        border-radius: 0;
      }
      .call-second-text {
        display: block;
        &.show {
          display: block;
        }
      }
    }
    .call-second-text {
      display: none;
      &.show {
        display: none;
      }
    }
  }
  input#btnCallBack {
    min-width: 160px;
  }
  .valid-rappel-col .btn {
    line-height: 17px;
  }
}
