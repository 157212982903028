.formulaire {
	padding-bottom: 30px;
	padding-top: 30px;
	.desc {
		color: @grey-label;
	}
	form {float: left;width: 100%;}
	.sidebar{padding-top: 30px;}
	.form-group	{
		margin-bottom: 30px;
		clear: both;
		float: left;
		.row > * {
		    margin-bottom: 20px;
		}
		label:not(.second-label), .wpcf7-list-item-label{
			display: block;
			font-family: 'Open sans';
			font-size: 14px;
			text-transform: uppercase;
		    font-weight: 400;
		    line-height: 14px;
		    margin-bottom: 10px;
		    color: @grey-label;
		    &.error {
			    background: @danger;
			    padding: 10px 15px;
			    color: #fff !important;
			    margin-top: 15px;
			    position: relative;
			    text-transform: initial;
			    font-size: 12px;
			    &::after {
			    	width: 0;
				    height: 0;
				    border-left: 6px solid transparent;
				    border-right: 6px solid transparent;
				    border-bottom: 9px solid @danger;
				    content: '';
				    position: absolute;
				    top: -9px;
				    left: 30px;
			    }
			}
		}
		input, button, select, textarea {
			border: 1px solid #eaeaea;
		    padding: 14px 10px;    
		    width: 100%;
		    outline: none; 
		    background: #fbfbfb;
		    width: 100%;
		    height: 50px;
		    &.wpcf7-not-valid{
		    	border: 1px solid #e26a6a;
		    }
		    &.error {
			    border: 1px solid @danger !important;
			}
		}
		::-webkit-input-placeholder { /* Chrome/Opera/Safari */
		  color: #b0b0b0;
		}
		::-moz-placeholder { /* Firefox 19+ */
		  color: #b0b0b0;
		}
		:-ms-input-placeholder { /* IE 10+ */
		  color: #b0b0b0;
		}
		:-moz-placeholder { /* Firefox 18- */
		  color: #b0b0b0;
		}
		textarea{height: auto;}

		input:focus{
			border-left: 3px solid @primary-form;
		}
		.selecter {
			background-color: #fbfbfb;
			.selecter-selected, .selecter-item{
				text-transform: initial;
			}
			&.with-placeholder ~ .selecter-options .selecter-item:first-child, &.placeholder + .selecter-selected{
			    color: #b0b0b0;
			}
		}
		/*Radio box customization */

		.vertical-list .wpcf7-list-item {
		    display: block;
		    width: 100%;
		}

		[type="radio"]:checked,
		[type="radio"]:not(:checked) {
		    position: absolute;
		    left: 0;
		    top: 0;
		    width: 20px;
    		height: 20px;
    		opacity: 0;
		}
		[type="radio"]:checked + label, [type="radio"]:checked + .wpcf7-list-item-label,
		[type="radio"]:not(:checked) + label, [type="radio"]:not(:checked) + .wpcf7-list-item-label
		{
		    position: relative;
		    padding-left: 28px;
		    cursor: pointer;
		    line-height: 20px;
		    display: inline-block;
		    color: #666;
		    pointer-events: none;
		}
		[type="radio"]:checked + label:before, [type="radio"]:checked + .wpcf7-list-item-label:before,
		[type="radio"]:not(:checked) + label:before, [type="radio"]:not(:checked) + .wpcf7-list-item-label:before {
		    content: '';
		    position: absolute;
		    left: 0;
		    top: 0;
		    width: 18px;
		    height: 18px;
		    border: 2px solid #8d92a2;
		    border-radius: 100%;
		    background: #fff;
		}
		[type="radio"]:checked + label:before, [type="radio"]:checked + .wpcf7-list-item-label:before{
		    background: #f0f0f0;
		    border-color: transparent;
		}
		[type="radio"]:checked + label:after,[type="radio"]:checked + .wpcf7-list-item-label:after,
		[type="radio"]:not(:checked) + label:after, [type="radio"]:not(:checked) + .wpcf7-list-item-label:after {
		    content: '';
		    width: 12px;
		    height: 12px;
		    background: @primary-form;
		    position: absolute;
		    top: 3px;
		    left: 3px;
		    border-radius: 100%;
		    -webkit-transition: all 0.2s ease;
		    transition: all 0.2s ease;
		}
		[type="radio"]:not(:checked) + label:after, [type="radio"]:not(:checked) + .wpcf7-list-item-label:after {
		    opacity: 0;
		    -webkit-transform: scale(0);
		    transform: scale(0);
		}
		[type="radio"]:checked + label:after, [type="radio"]:checked + .wpcf7-list-item-label:after {
		    opacity: 1;
		    -webkit-transform: scale(1);
		    transform: scale(1);
		}
		[type="radio"]:disabled + label:before, [type="radio"]:disabled + .wpcf7-list-item-label:before {
		    background: #b2b2b2;
		    border-color: #b2b2b2;
		}
		.radio-group, .wpcf7-radio > .wpcf7-list-item{
			float: left; margin-right: 30px;margin-left: 0;position: relative;
			&.is-block{
				float: none;
			}
		}


		/*Checkbox*/
		
		.checkbox-group {
			display: block;
			margin-bottom: 15px;
			float: left;
			margin-right: 30px;
			&.is-block{
				float: none;
			}
		  	input {
				padding: 0;
				height: initial;
				width: initial;
				margin-bottom: 0;
				display: none;
				cursor: pointer;
	     		padding: 3px 0 0 30px;
			  	&:checked + label:after, &:checked + .wpcf7-list-item-label:after {
					content: '';
					display: block;
					position: absolute;
				    top: 5px;
				    left: 8px;
				    width: 6px;
				    height: 10px;
					border: solid @primary-form;
					border-width: 0 2px 2px 0;
					transform: rotate(45deg);
				}
				&:disabled + label:before, &:disabled + .wpcf7-list-item-label:before{
					background: #b2b2b2;
					border-color: #b2b2b2;
				}
			}
			label, .wpcf7-list-item-label {
				position: relative;
				cursor: pointer;
				padding: 3px 0 0 30px;
				color: @grey-label;
				&:before {
					content:'';
					-webkit-appearance: none;
					background-color: transparent;
					border: 2px solid #8d92a2;
					box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
					display: inline-block;
					position: relative;
					vertical-align: middle;
					cursor: pointer;
					margin-right: 5px;
				    width: 21px;
				    height: 21px;
				    position: absolute;
				    top: 0;
				    left: 0;
				}

			}
		}
	}

	.star{color: @danger; font-size: 14px;}

	.bouton-group{
		text-align: center;
		padding:0 0 30px 0;
		float: left;
		input{
			display: inline-block;
			width: auto !important;
		}
		&.col-md-12 { padding: 0; }
	}

	div.wpcf7-spam-blocked, div.wpcf7-mail-sent-ng, div.wpcf7-aborted{border: none}

}

div.wpcf7-validation-errors, div.wpcf7-acceptance-missing {
    float: left;
    width: 100%;
    background: #e26a6a;
    color: white;
    border: #e26a6a;
}
.wpcf7-form-control-wrap {
    position: relative;
    display: block;
    span.wpcf7-not-valid-tip {
        background-color: #e26a6a;
	    font-size: 12px;
	    font-weight: normal;
	    display: block;
	    position: relative;
	    color: white;
	    height: auto;
	    min-width: 100%;
	    white-space: nowrap;
	    padding: 8px;
	    margin-top: 12px;
	    &:after{
    	    content: '';
		    position: absolute;
		    top: -7px;
		    left: 20px;
		    width: 0;
		    height: 0;
		    border-style: solid;
		    border-width: 0 9px 9px 9px;
			border-color: transparent transparent #e26a6a transparent;
	    }
	}
}
