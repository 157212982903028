.sidebar {
	h4 {
	    text-transform: uppercase;
		font-weight: 700;
		font-size: 18px;
	    border-bottom: 1px solid #b0b0b0;
	    padding-bottom: 15px;
	}
	.adress-bloc {
		// padding-top: 15px;
      	margin-bottom: 40px;
	}
	ul{
		li {
			list-style: none;
			padding-top: 10px;
			.s-label {
				color: @primary;
				font-size: 14px;
			    font-weight: 700;
			    text-transform: uppercase;
			    position: relative;
			    i {
			        position: absolute;
				    left: -33px;
				    font-size: 20px;
				    top: 1px;
				}
			}
			&.s-transport {
				img{margin-right: 10px;}
			}
			.desc {
				line-height: 22px;
				font-size: 14px;
				color: @grey-label;
				margin-bottom: 10px;
				ul{padding: 0;}
			}
		}
	}
	.soc-bloc{
		margin-bottom: 30px;
		.reseaux{
				list-style: none;
				margin: 0;
				margin-bottom: 3px;
				margin-top: 13px;
				padding: 0;
			li{
				display: inline-block;
				margin: 0;
				padding: 0;
			}
			a{
				background-color: @primary;
				border-radius: 50%;
				color: @white;
				display: inline-block;
				font-size: 22px;
				height: 40px;
				margin-right: 9px;
				padding: 5px 9px 4px 9px;
				position: relative;
				text-decoration: none;
				width: 40px;
				&:hover{
				  background-color: @secondary;
				  color: @white;
				}
			}
		}
	}
}