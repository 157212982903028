.modal {
  .modal-dialog {
    max-width: 100%;
    .modal-content {
      border-radius: 0;
      .modal-header {
        border-bottom: 0;
        justify-content: flex-end;
        padding: 0;
        .close-btn {
          background-color: @blue-second;
          color: @white;
          cursor: pointer;
          display: block;
          font-size: 10px;
          height: 40px;
          line-height: 40px;
          position: relative;
          text-align: center;
          width: 40px;
          i {
            opacity: 0.7;
          }
          &:hover {
            i {
              opacity: 1;
            }
          }
        }
      }
      .modal-body {
        padding: 30px 86px;
        &.with-icon {
          padding-top: 0;
        }
        .title {
          color: @blue-second;
          font-size: 36px;
          font-weight: 400;
          letter-spacing: 0;
          text-transform: none;
        }
        .form-search {
          margin-top: 39px;
          margin-bottom: 56px;
          position: relative;
          border-bottom: 2px solid @blue-second;
          padding-bottom: 4px;
          padding-right: 28px;
          input {
            border: 0;
            color: @black-third;
            font-size: 14px;
            font-weight: 400;
            outline: 0;
            padding: 5px 15px;
            width: 100%;
            &:focus {
              background-color: @grey-focus;
            }
          }
          .btn-submit {
            color: @blue-second;
            min-width: 0;
            padding: 0;
            position: absolute;
            right: 0;
            top: 4px;
            border: 0;
            font-size: 16px;
            font-weight: 400;
            &:hover,
            &.blured {
              color: @blue-third;
            }
          }
          .btn-cancel {
            border: 0;
            position: absolute;
            top: 5px;
            left: -30px;
            min-width: 0;
            padding: 0;
          }
        }
        .icon-cross-light {
          cursor: pointer;
          display: block;
          position: relative;
          height: 20px;
          width: 30px;
          &::before {
            content: "";
            position: absolute;
            width: 3px;
            height: 20px;
            background: @blue-second;
            left: 14px;
            top: 0px;
            .rotation(45deg);
          }
          &::after {
            content: "";
            position: absolute;
            width: 3px;
            height: 20px;
            background: @blue-second;
            right: 13px;
            top: 0px;
            .rotation(-45deg);
          }
        }
        p {
          &:nth-child(1) {
            display: block;
            position: relative;
            top: -4px;
            + .btn {
              margin-top: 60px;
            }
          }
        }
      }
      .modal-footer {
        border-top: 0;
        padding: 0;
      }
    }
  }
  &.modal-search {
    .modal-dialog {
      width: 945px;
    }
  }
  &.modal-notif {
    .modal-dialog {
      width: 444px;
      .modal-content {
        .modal-body {
          padding: 16px;
          padding-bottom: 10px;
          &.with-icon {
            padding-top: 0;
          }
          text-align: center;
          .title {
            display: block;
            font-size: 22px;
            line-height: 26px;
            margin-bottom: 18px;
            text-transform: uppercase;
          }
          p {
            color: @black-fourth;
            font-size: 16px;
            font-weight: 400;
            line-height: 22px;
            margin: 15px 0;
          }
          .btn {
            letter-spacing: 0.6px;
            line-height: 22px;
            margin-top: 25px;
            text-transform: uppercase;
            &.btn-first {
              background: @primary;
              color: @white;
              display: inline-block;
              font-weight: 700;
              min-width: 88px;
              &:hover,
              &:focus {
                background: @white;
                color: @primary;
              }
              &.danger {
                background: @red-danger;
                color: @white;
                &:hover,
                &:focus {
                  background: @red-hover;
                  border-color: @red-hover;
                  color: @white;
                }
                .glyphicon-ok {
                  display: none !important;
                }
              }
            }
            &.btn-second {
              background: @white;
              border-color: @grey-second;
              color: @grey-second;
              margin-right: 15px;
              min-width: 88px;
              font-weight: 400;
              padding-left: 0;
              padding-right: 0;
              &:hover,
              &:focus {
                font-weight: 700;
              }
            }
          }
          .glyphicon-ok {
            font-family: "Glyphicons Halflings";
            color: @green-notif;
            font-size: 21px;
            &:after {
              content: "\e013";
            }
          }
          .icone-notif {
            width: 61px;
            height: 61px;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            margin: 0px auto;
            margin-bottom: 5px;
            top: -16px;
            &.success {
              border: 3px solid @green-notif;
              padding: 13px;
            }
            &.warning {
              border: 3px solid @orange;
              padding: 13px;
              &::before {
                content: "!";
                font-size: 46px;
                color: @orange;
              }
            }
            &.danger {
              border: 3px solid @red-notif;
              &::before {
                content: "";
                position: absolute;
                width: 4px;
                height: 38px;
                background: @red-notif;
                left: 25px;
                top: 10px;
                .rotation(45deg);
              }
              &::after {
                content: "";
                position: absolute;
                width: 4px;
                height: 38px;
                background: @red-notif;
                right: 26px;
                top: 10px;
                .rotation(-45deg);
              }
            }
          }
        }
      }
    }
  }
}
.modal-backdrop {
  &.show {
    opacity: 0.7;
  }
}
[id^="#html_captcha_callback"] iframe,
[id*="#html_captcha_callback"] iframe {
  border-bottom: 1px solid #c1c1c1;
}
[id^="#html_captcha_callback"] iframe iframe + div,
[id*="#html_captcha_callback"] iframe iframe + div {
  position: fixed;
  opacity: 0;
  left: -5000px;
}
.modal.modal-search.pop-recherche {
  .modal-dialog {
    width: 680px;
  }
  .modal-content {
    .modal-body {
      padding: 5px 86px 20px 86px;
      .rouge {
        color: red;
        font-size: 16px;
      }
      .verte {
        color: green;
        font-size: 16px;
      }
      .form-search {
        border: none;
        margin-bottom: 15px;
        input {
          border-bottom: 2px solid #545e7f;
          margin-bottom: 15px;
        }
        .btn-envoyer {
          margin-top: 20px;
        }
        .button-valider {
          text-transform: uppercase;
          font-size: 14px;
          font-weight: 600;
          -webkit-border-radius: 5px;
          border-radius: 5px;
          padding: 7px;
          min-width: 189px;
          color: #53607f;
          background-color: transparent;
          border: 1px solid #53607f;
          position: relative;
          width: auto;
          z-index: 2;
          &:hover {
            background-color: #53607f;
            color: #ffffff;
          }
        }
      }
    }
  }
}
